<script lang="ts" setup>
import { computed, inject } from 'vue';
import { SortDirection, useDataTable } from '../';

const emit = defineEmits(['sort']);

const dataTableService = inject<ReturnType<useDataTable>>('dataTableService');

const currentSortLabel = computed(() => {
  const sortableColumns = dataTableService.getSortableColumns.value;
  const current = dataTableService.sort.value;

  if (current) {
    const column = sortableColumns.find((c) => c.name === current.column);
    return {
      name: column.name,
      direction: current.direction,
      label: column.label,
      translate: column.translateLabel,
      icon: current.direction === SortDirection.ASC ? 'mdi-sort-ascending' : 'mdi-sort-descending'
    };
  }

  return {
    name: null,
    direction: null,
    label: 'dataTable.sort.none',
    translate: true,
    icon: 'mdi-sort'
  };
});

const sortList = computed(() => {
  const sorts = [];
  const sortableColumns = dataTableService.getSortableColumns.value;
  const current = currentSortLabel.value;

  sorts.push({
    name: null,
    direction: null,
    label: 'dataTable.sort.none',
    translate: true,
    icon: 'mdi-sort',
    active: current.name === null && current.direction === null
  });

  sortableColumns.forEach((c) => {
    sorts.push(
      {
        name: c.name,
        direction: SortDirection.ASC,
        label: c.label,
        translate: c.translateLabel,
        icon: 'mdi-sort-ascending',
        active: current.name === c.name && current.direction === SortDirection.ASC
      },
      {
        name: c.name,
        direction: SortDirection.DESC,
        label: c.label,
        translate: c.translateLabel,
        icon: 'mdi-sort-descending',
        active: current.name === c.name && current.direction === SortDirection.DESC
      }
    );
  });

  return sorts;
});

const sortBy = (item) => {
  if (item.name === null) {
    dataTableService.clearSort();
    emit('sort', dataTableService.sort.value);
    return;
  }

  dataTableService.sortByDirection(item.name, item.direction);
  emit('sort', dataTableService.sort.value);
};
</script>

<template>
  <v-menu location="bottom">
    <template v-slot:activator="{ props }">
      <v-btn
        v-bind="props"
        :prepend-icon="currentSortLabel.icon"
        append-icon="mdi-menu-down">
        <v-tooltip
          activator="parent"
          location="top">
          {{ $t('dataTable.sort.sort') }}
        </v-tooltip>
        {{ currentSortLabel.translate ? $t(currentSortLabel.label) : currentSortLabel.label }}
      </v-btn>
    </template>
    <v-list>
      <v-list-item
        v-for="(sort, i) in sortList"
        :key="i"
        :active="sort.active"
        :prepend-icon="sort.icon"
        :value="sort"
        @click="sortBy(sort)">
        <v-list-item-title>
          {{ sort.translate ? $t(sort.label) : sort.label }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
