import { ApiModule, type PaginatedResponseData } from '@common/index';
import type { SubjectPrerequisiteEntreatyItem, SubjectPrerequisiteEntreaty } from '../models';

export class SubjectPrerequisiteEntreatyApi extends ApiModule {
  protected endpoint = '/subject-prerequisite-entreaty';

  public async pageSubjectPrerequisiteEntreatys(params: any) {
    return this.api.post<PaginatedResponseData<SubjectPrerequisiteEntreatyItem>, any>(
      `${this.endpoint}/page?page=${params.page}&size=${params.itemsPerPage}&sort=${params.sort ?? ''}`,
      {
        ...params
      }
    );
  }

  public loadSubjectPrerequisiteEntreatyDetails(id: number) {
    return this.api.get<SubjectPrerequisiteEntreaty>(`${this.endpoint}?id=${id}`);
  }

  public saveSubjectPrerequisiteEntreaty(params: any) {
    return this.api.post(`${this.endpoint}`, {
      ...params
    });
  }

  public deleteSubjectPrerequisiteEntreaty(id: number) {
    return this.api.delete(`${this.endpoint}?id=${id}`);
  }

  public getMetaData(subjectEntreatyId: number, subjectId: number, subjectPrerequisiteId: number | undefined) {
    return this.api.get<any>(
      `${this.endpoint}/master-data?entreatyId=${subjectEntreatyId}&subjectId=${subjectId}&subjectPrerequisiteId=${subjectPrerequisiteId ?? ''}`
    );
  }

  public reset(subjectId: number) {
    return this.api.post(`${this.endpoint}/reset`, {}, { params: { subjectId } });
  }
}
