import type { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
  {
    path: '/access-denied',
    name: 'accessDenied',
    component: () => import('../pages/AccessDeniedPage.vue')
  }
];

export default routes;
