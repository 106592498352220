import { useI18n } from 'vue-i18n';
import type { CellConfig, DataTableCellProps } from '../models';

export function isValueEmpty(itemData: string, columnName: string) {
  return itemData[columnName] === undefined || itemData[columnName] === null;
}

export function getValueToTranslate(translatePrefix: string, itemData: string, columnName: string) {
  return (translatePrefix || '') + itemData[columnName];
}

export function getFullValue(prefix: string, itemData: string, columnName: string, postfix: string) {
  return (prefix || '') + itemData[columnName] + (postfix || '');
}

export function computeCellValue(props: DataTableCellProps<CellConfig>) {
  const { t } = useI18n();
  if (isValueEmpty(props.item.data, props.column.name)) {
    return props.cellConfig.emptyValue;
  }
  if (props.cellConfig.translate) {
    return t(getValueToTranslate(props.cellConfig.translatePrefix, props.item.data, props.column.name));
  }
  return getFullValue(props.cellConfig.prefix, props.item.data, props.column.name, props.cellConfig.postfix);
}
