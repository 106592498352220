import { toRaw } from 'vue';
import { FormValidator, isEmpty, type FormValidatorConfig, type FormValidatorContext, type FormValidatorSettings } from '@common/index';

export interface SubjectOneOfRequiredValidatorSettings extends FormValidatorSettings {
  fields: string[];
}

export type SubjectOneOfRequiredValidatorConfig = FormValidatorConfig<SubjectOneOfRequiredValidatorSettings>;

export class SubjectOneOfRequiredValidator extends FormValidator<SubjectOneOfRequiredValidatorSettings> {
  constructor() {
    super();
    this._name = 'subjectOneOfRequired';
    this._settings = {
      invalidMessage: 'subject.validation.subjectOneOfRequired',
      needTranslate: true,
      fields: []
    };
  }

  public override async onValidate(value: any, context: FormValidatorContext) {
    const formValues = toRaw(context.element.form?.value);
    const fields = this.settings.fields;

    if (!formValues) {
      this.reset();
      return;
    }

    this.valid = !isEmpty(value) || fields.some((field) => !!formValues[field] && !isEmpty(formValues[field]));

    if (!this.valid) {
      this.addMessage(this.settings.invalidMessage);
    }
  }
}
