<script lang="ts" setup>
import { type DataTableButtonGlobalActionProps, useDataTable } from '../../';
import DataTableButtonAction from './DataTableButtonAction.vue';
import { computed, inject } from 'vue';

const props = defineProps<DataTableButtonGlobalActionProps>();
const emit = defineEmits(['action']);
const dataTableService = inject<ReturnType<typeof useDataTable>>('dataTableService')!;

const isDisabled = computed(
  () =>
    (props.selectionOnly && props.selectedItems.length === 0) ||
    props.actionConfig.disabled ||
    (props.disabledCondition && props.disabledCondition(props.selectedItems, dataTableService.getItems.value)) ||
    props.isLoading
);

const isTranslated = computed(() => props.actionConfig?.translateLabel || false);

const onAction = () => {
  emit('action', props.selectedItems);
};
</script>

<template>
  <data-table-button-action
    :class="actionConfig?.class"
    :label="actionConfig?.label"
    :color="actionConfig?.color"
    :icon="actionConfig?.icon"
    :prepend-icon="actionConfig?.prependIcon"
    :append-icon="actionConfig?.appendIcon"
    :stacked="actionConfig?.stacked"
    :size="actionConfig?.size"
    :icon-size="actionConfig?.iconSize"
    :disabled="isDisabled"
    :translate-label="isTranslated"
    @action="onAction" />
</template>
