import { ApiModule, type PaginatedResponseData } from '@common/index';
import type { SpecialityItem, Speciality } from '../models';

export class SpecialityApi extends ApiModule {
  protected endpoint = '/speciality';

  public async pageSpecialitys(params: any) {
    return this.api.post<PaginatedResponseData<SpecialityItem>, any>(
      `${this.endpoint}/page?page=${params.page}&size=${params.itemsPerPage}&sort=${params.sort ?? ''}`,
      {
        ...params
      }
    );
  }

  public loadSpecialityDetails(id: number) {
    return this.api.get<Speciality>(`${this.endpoint}?id=${id}`);
  }

  public saveSpeciality(params: any) {
    return this.api.post(`${this.endpoint}`, {
      ...params
    });
  }

  public deleteSpeciality(id: number) {
    return this.api.delete(`${this.endpoint}?id=${id}`);
  }

  public loadAvailableSpecialities() {
    return this.api.get<Speciality[]>(`${this.endpoint}/available`);
  }

  public loadAllSpecialities() {
    return this.api.get<Speciality[]>(`${this.endpoint}/all`);
  }
}
