import { ApiModule, type PaginatedResponseData } from '@common/index';
import type { AbsenceRatioItem, AbsenceRatio } from '../models';

export class AbsenceRatioApi extends ApiModule {
  protected endpoint = '/absence-ratio';

  public async pageAbsenceRatios(params: any) {
    return this.api.post<PaginatedResponseData<AbsenceRatioItem>, any>(
      `${this.endpoint}/page?page=${params.page}&size=${params.itemsPerPage}&sort=${params.sort ?? ''}`,
      {
        ...params
      }
    );
  }

  public loadAbsenceRatioDetails(id: number) {
    return this.api.get<AbsenceRatio>(`${this.endpoint}?id=${id}`);
  }

  public saveAbsenceRatio(params: any) {
    return this.api.post(`${this.endpoint}`, {
      ...params
    });
  }

  public deleteAbsenceRatio(id: number) {
    return this.api.delete(`${this.endpoint}?id=${id}`);
  }
}
