import { Permission } from '@common/index';
import type { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
  {
    path: '/sample-units',
    name: 'sampleUnitData',
    meta: {
      requiresAuth: true,
      permission: Permission.MENU_ADMINISTRATION
    },
    children: [
      {
        path: '',
        name: 'sampleUnitList',
        component: () => import('../pages/SampleUnitListPage.vue')
      },
      {
        path: 'create',
        component: () => import('../pages/SampleUnitDetailsPage.vue'),
        children: [
          {
            path: '',
            name: 'sampleUnitCreate',
            component: () => import('../containers/SampleUnitDetails.vue'),
            props: true
          }
        ]
      },
      {
        path: ':sampleUnitId',
        name: 'sampleUnitDetailsContainer',
        props: true,
        children: [
          {
            path: '',
            name: 'sampleUnitDetails',
            component: () => import('../pages/SampleUnitDetailsPage.vue'),
            props: true,
            children: [
              {
                path: '',
                name: 'sampleUnitEdit',
                component: () => import('../containers/SampleUnitDetails.vue'),
                props: true
              },
              {
                path: 'copy',
                name: 'sampleUnitCopy',
                component: () => import('../containers/SampleUnitDetails.vue'),
                props: (route) => ({ ...route.params, isCopy: true })
              },
              {
                path: 'entreaty',
                name: 'sampleUnitEntreaty',
                component: () => import('../containers/SampleUnitEntreatyDetails.vue'),
                props: true
              },
              {
                path: 'subject-entreaty',
                name: 'subjectEntreaty',
                component: () => import('../containers/SubjectEntreatyDetails.vue'),
                props: true
              },
              {
                path: 'lessons',
                name: 'sampleUnitHasLesson',
                component: () => import('../containers/SampleUnitHasLesson.vue'),
                props: true
              },
              {
                path: 'examiners',
                name: 'sampleUnitHasExaminer',
                component: () => import('../containers/SampleUnitHasExaminer.vue'),
                props: true
              },
              {
                path: 'courseTeachers',
                name: 'sampleUnitHasCourseTeacher',
                component: () => import('../containers/SampleUnitHasCourseTeacher.vue'),
                props: true
              },
              {
                path: 'subjects',
                name: 'sampleUnitSubjectList',
                component: () => import('../containers/SampleUnitSubject.vue'),
                props: true
              }
            ]
          },
          {
            path: 'subjects',
            name: 'sampleUnitSubject',
            children: [
              {
                path: 'create',
                component: () => import('../../subject/pages/SubjectDetailsPage.vue'),
                props: true,
                children: [
                  {
                    path: '',
                    name: 'sampleUnitSubjectCreate',
                    props: true,
                    component: () => import('../../subject/containers/SubjectDetails.vue')
                  }
                ]
              },
              {
                path: ':subjectId',
                name: 'sampleUnitSubjectDetails',
                component: () => import('../../subject/pages/SubjectDetailsPage.vue'),
                props: true,
                children: [
                  {
                    path: '',
                    name: 'sampleUnitSubjectEdit',
                    component: () => import('../../subject/containers/SubjectDetails.vue'),
                    props: true
                  },
                  {
                    path: 'copy',
                    name: 'sampleUnitSubjectCopy',
                    component: () => import('../../subject/containers/SubjectDetails.vue'),
                    props: (route) => ({ ...route.params, isCopy: true })
                  },
                  {
                    path: 'prerequisite',
                    name: 'sampleUnitSubjectPrerequisite',
                    component: () => import('../../subject/containers/SubjectPrerequisite.vue'),
                    props: true
                  },
                  {
                    path: 'prerequisiteFor',
                    name: 'sampleUnitSubjectPrerequisiteFor',
                    component: () => import('../../subject/containers/SubjectPrerequisiteFor.vue'),
                    props: true
                  },
                  {
                    path: 'sample-curriculum',
                    name: 'sampleUnitSubjectHasSampleCurriculum',
                    component: () => import('../../subject/containers/SubjectHasSampleCurriculum.vue'),
                    props: true
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: 'own',
    children: [
      {
        path: 'sample-units',
        name: 'ownSampleUnitData',
        children: [
          {
            path: '',
            name: 'ownSampleUnitList',
            component: () => import('../pages/teacher/TeacherSampleUnitListPage.vue')
          },
          {
            path: 'create',
            component: () => import('../pages/teacher/TeacherSampleUnitDetailsPage.vue'),
            children: [
              {
                path: '',
                name: 'ownSampleUnitCreate',
                component: () => import('../containers/SampleUnitDetails.vue'),
                props: true
              }
            ]
          },
          {
            path: ':sampleUnitId',
            name: 'ownSampleUnitDetailsContainer',
            props: true,
            children: [
              {
                path: '',
                name: 'ownSampleUnitDetails',
                component: () => import('../pages/teacher/TeacherSampleUnitDetailsPage.vue'),
                props: true,
                children: [
                  {
                    path: '',
                    name: 'ownSampleUnitEdit',
                    component: () => import('../containers/teacher/TeacherSampleUnitDetails.vue'),
                    props: true
                  },
                  {
                    path: 'copy',
                    name: 'ownSampleUnitCopy',
                    component: () => import('../containers/teacher/TeacherSampleUnitDetails.vue'),
                    props: (route) => ({ ...route.params, isCopy: true })
                  },
                  {
                    path: 'lessons',
                    name: 'ownSampleUnitHasLesson',
                    component: () => import('../containers/SampleUnitHasLesson.vue'),
                    props: true
                  },
                  {
                    path: 'examiners',
                    name: 'ownSampleUnitHasExaminer',
                    component: () => import('../containers/SampleUnitHasExaminer.vue'),
                    props: true
                  },
                  {
                    path: 'courseTeachers',
                    name: 'ownSampleUnitHasCourseTeacher',
                    component: () => import('../containers/SampleUnitHasCourseTeacher.vue'),
                    props: true
                  },
                  {
                    path: 'entreaty',
                    name: 'ownSampleUnitEntreaty',
                    component: () => import('../containers/teacher/TeacherSampleUnitEntreatyDetails.vue'),
                    props: true
                  },
                  {
                    path: 'subject-entreaty',
                    name: 'ownSubjectEntreaty',
                    component: () => import('../containers/teacher/TeacherSubjectEntreatyDetails.vue'),
                    props: true
                  },
                  {
                    path: 'subjects',
                    name: 'ownSampleUnitSubjectList',
                    component: () => import('../containers/teacher/TeacherSampleUnitSubject.vue'),
                    props: true
                  }
                ]
              },
              {
                path: 'subjects',
                name: 'ownSampleUnitSubject',
                children: [
                  {
                    path: 'create',
                    component: () => import('../../subject/pages/teacher/TeacherSubjectDetailsPage.vue'),
                    props: true,
                    children: [
                      {
                        path: '',
                        name: 'ownSampleUnitSubjectCreate',
                        props: true,
                        component: () => import('../../subject/containers/teacher/TeacherSubjectDetails.vue')
                      }
                    ]
                  },
                  {
                    path: ':subjectId',
                    name: 'ownSampleUnitSubjectDetails',
                    component: () => import('../../subject/pages/teacher/TeacherSubjectDetailsPage.vue'),
                    props: true,
                    children: [
                      {
                        path: '',
                        name: 'ownSampleUnitSubjectEdit',
                        component: () => import('../../subject/containers/teacher/TeacherSubjectDetails.vue'),
                        props: true
                      },
                      {
                        path: 'copy',
                        name: 'ownSampleUnitSubjectCopy',
                        component: () => import('../../subject/containers/teacher/TeacherSubjectDetails.vue'),
                        props: (route) => ({ ...route.params, isCopy: true })
                      },
                      {
                        path: 'prerequisite',
                        name: 'ownSampleUnitSubjectPrerequisite',
                        component: () => import('../../subject/containers/SubjectPrerequisite.vue'),
                        props: true
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];

export default routes;
