import { toRaw } from 'vue';
import { FormValidator, type FormValidatorContext, type FormValidatorConfig, type FormValidatorSettings } from '../models';
import { isEmpty } from '../utils';

export interface GreaterThanatorSettings extends FormValidatorSettings {
  otherFieldName: string;
  otherFieldFullName: string;
  allowEqual: boolean;
}

export type GreaterThanValidatorConfig = FormValidatorConfig<GreaterThanatorSettings>;

export class GreaterThanValidator extends FormValidator<GreaterThanatorSettings> {
  constructor() {
    super();
    this._name = 'greaterThan';
    this._settings = {
      invalidMessage: 'form.validator.greaterThan.invalidMessage',
      needTranslate: true,
      otherFieldName: '',
      otherFieldFullName: '',
      allowEqual: true
    };
  }

  public override async onValidate(value: any, context: FormValidatorContext) {
    if (isEmpty(value)) {
      return;
    }

    const values = toRaw(context.values);

    const otherValue = this.settings.otherFieldName
      ? values[this.settings.otherFieldName]
      : this.settings.otherFieldFullName
      ? this.getFullNameValues(this.settings.otherFieldFullName, context)
      : '';

      if (isEmpty(otherValue)) {
        return;
      }

    this._valid = this.settings.allowEqual ? Number(value) >= Number(otherValue) : Number(value) > Number(otherValue);

    if (!this._valid) {
      this.addMessage(this.settings.invalidMessage);
    }
  }

  private getFullNameValues(fullName: string, context: FormValidatorContext) {
    let values = toRaw(context.values);

    fullName.split('.').forEach((element) => {
      values = values[element];
    });

    return values;
  }
}
