import { ApiModule, type PaginatedResponseData } from '@common/index';
import type { ModuleItem, Module } from '../models';

export class ModuleApi extends ApiModule {
  protected endpoint = '/module';
  protected specialityHasModuleEndoint = '/speciality-has-module';

  public async pageModules(params: any) {
    return this.api.post<PaginatedResponseData<ModuleItem>, any>(
      `${this.endpoint}/page?page=${params.page}&size=${params.itemsPerPage}&sort=${params.sort ?? ''}`,
      {
        ...params
      }
    );
  }

  public loadModuleDetails(id: number) {
    return this.api.get<Module>(`${this.endpoint}?id=${id}`);
  }

  public saveModule(params: any) {
    return this.api.post(`${this.endpoint}`, {
      ...params
    });
  }

  public deleteModule(id: number) {
    return this.api.delete(`${this.endpoint}?id=${id}`);
  }

  public listModuleRelatedSpecialities(moduleId: number) {
    return this.api.get(`${this.specialityHasModuleEndoint}/list-by-module-id?moduleId=${moduleId}`);
  }

  public saveModuleRelatedSpeciality(params: any) {
    return this.api.post(`${this.specialityHasModuleEndoint}`, {
      ...params
    });
  }

  public deleteModuleRelatedSpeciality(id: number) {
    return this.api.delete(`${this.specialityHasModuleEndoint}?id=${id}`);
  }

  public listAllActiveModule() {
    return this.api.get<Module[]>(`${this.endpoint}/all`);
  }

  public listBySpecialityId(specialityId: number) {
    return this.api.get<ModuleItem[]>(`${this.endpoint}/by-speciality-id?specialityId=${specialityId}`);
  }
}
