<script lang="ts" setup>
import { computed } from 'vue';
import type { DataTableCellProps, CellConfig } from '../../';
import { computeCellValue } from '../../utils/data-table-cell.utils';

const props = defineProps<DataTableCellProps<CellConfig>>();

const getValue = computed(() => computeCellValue(props));
</script>

<template>
  <slot>
    <span>{{ getValue }}</span>
  </slot>
</template>
