<script lang="ts" setup>
import { computed, type PropType } from 'vue';
import { FormElement, useDisplayFormElement } from '../';

const props = defineProps({
  element: {
    type: Object as PropType<FormElement>,
    required: true
  }
});

const {
  labelComponent,
  label,
  hint,
  suffix,
  attributes,
  enabled,
  translatedValue,
  maxHeight,
  ellipsis,
  tooltip,
  tooltipLocation,
  tooltipMaxWidth,
  displayHtmlContent
} = useDisplayFormElement(props.element);

const style = computed(() => {
  const result: any = {};

  if (maxHeight.value !== undefined) {
    result.overflow = 'auto';
    result.maxHeight = maxHeight.value;
  } else if (ellipsis.value === true) {
    result.overflow = 'hidden';
    result.textOverflow = 'ellipsis';
    result.whiteSpace = 'nowrap';
  }

  return result;
});
</script>

<template>
  <div
    class="v-input"
    data-testid="display-container">
    <label
      class="v-display-label"
      data-testid="display-label">
      <template v-if="labelComponent">
        <component
          :is="labelComponent"
          :label="label"
          :props="{}"
          :element="element" />
      </template>
      <template v-else>
        {{ label }}
      </template>
    </label>
    <div
      class="v-display-value"
      :class="{ 'text-disabled': !enabled }"
      data-testid="display-value"
      :style="style">
      <span>
        <v-tooltip
          v-if="tooltip"
          activator="parent"
          :location="tooltipLocation"
          :max-width="tooltipMaxWidth"
          :text="translatedValue" />
        <div
          v-if="displayHtmlContent"
          v-html="translatedValue"></div>

        <div v-else>{{ translatedValue }}</div>
        {{ suffix }}
      </span>
    </div>
    <div
      v-if="!attributes.hideDetails"
      class="v-message">
      {{ hint }}
    </div>
  </div>
</template>
