import { ApiModule, type PaginatedResponseData } from '@common/index';
import type { SampleCurriculumItem, SampleCurriculum } from '../models';

export class SampleCurriculumApi extends ApiModule {
  protected endpoint = '/sample-curriculum';

  public async pageSampleCurriculums(params: any) {
    return this.api.post<PaginatedResponseData<SampleCurriculumItem>, any>(
      `${this.endpoint}/page?page=${params.page}&size=${params.itemsPerPage}&sort=${params.sort ?? ''}`,
      {
        ...params
      }
    );
  }

  public listSampleCurriculums() {
    return this.api.get<SampleCurriculum[]>(`${this.endpoint}/list`);
  }

  public loadSampleCurriculumDetails(id: number) {
    return this.api.get<SampleCurriculum>(`${this.endpoint}?id=${id}`);
  }

  public saveSampleCurriculum(params: any) {
    return this.api.post(`${this.endpoint}`, {
      ...params
    });
  }

  public downloadSampleCurriculum(id: number) {
    return this.api.get<Blob>(`${this.endpoint}/xls?id=${id}`, {
      headers: {
        Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      },
      responseType: 'blob'
    });
  }

  public deleteSampleCurriculum(id: number) {
    return this.api.delete(`${this.endpoint}?id=${id}`);
  }
}
