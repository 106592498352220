<script lang="ts" setup>
import { type PropType, ref } from 'vue';
import { useExpansionFormArrayElement } from '../composables';
import { ExpansionFormArrayElement } from '../models';
import { CtsFieldMessage } from './';
import { useModals } from '../../modal';
import { CtsExpansionField } from '@common/lib/modules/form';

const props = defineProps({
  element: {
    type: Object as PropType<ExpansionFormArrayElement>,
    required: true
  },
  componentSettings: {
    type: Object as PropType<any>,
    required: false
  }
});

const {
  attributes,
  settings,
  sheetAttributes,
  labelComponent,
  prependComponent,
  appendComponent,
  children,
  hasErrorMessages,
  hasWarningMessages,
  hasInfoMessages,
  label,
  addEnabled,
  addAttributes,
  addBtnEnabled,
  removeEnabled,
  removeAttributes,
  clearEnabled,
  clearAttributes,
  clearBtnEnabled,
  confirmClear,
  confirmClearTitle,
  confirmClearText,
  confirmClearNeedTranslate,
  confirmRemove,
  confirmRemoveTitle,
  confirmRemoveText,
  confirmRemoveNeedTranslate,
  add,
  clear,
  pageCount,
  page,
  firstOpenedByDefault,
  allOpenedByDefault,
  openedPanels
} = useExpansionFormArrayElement(props.element);

const modals = useModals();

const addChild = () => {
  add();
};

const clearChildren = () => {
  if (confirmClear) {
    modals.openConfirm({
      title: confirmClearTitle.value,
      text: confirmClearText.value,
      translate: confirmClearNeedTranslate.value,
      onConfirm: () => clear()
    });
  } else {
    clear();
  }
};

if (!openedPanels.value.length) {
  openedPanels.value = allOpenedByDefault.value
    ? children.value.map((child) => child.fullName)
    : firstOpenedByDefault.value && children.value.length
    ? [children.value[0].fullName]
    : [];
}
</script>

<template>
  <v-input
    :hideDetails="attributes.hideDetails"
    class="cts-form-array"
    :class="{
      'v-input--error': hasErrorMessages,
      'v-input--warning': hasWarningMessages,
      'v-input--info': hasInfoMessages
    }">
    <template
      v-if="prependComponent"
      #prepend>
      <component
        :is="prependComponent"
        :element="element" />
    </template>
    <template #default>
      <div class="cts-form-array__head">
        <div>
          <v-label v-if="label">
            <template v-if="labelComponent">
              <component
                :is="labelComponent"
                :label="label"
                :props="{}"
                :element="element" />
            </template>
            <template v-else>
              {{ label }}
            </template>
          </v-label>
          <div
            v-if="attributes.hideDetails !== true"
            class="v-input__details mb-5">
            <cts-field-message :element="element" />
          </div>
        </div>
        <div class="cts-form-array__actions">
          <v-btn
            v-if="addEnabled"
            v-bind="addAttributes"
            :disabled="!addBtnEnabled"
            @click.prevent="addChild" />
          <v-btn
            v-if="clearEnabled"
            v-bind="clearAttributes"
            :disabled="!clearBtnEnabled"
            @click.prevent="clearChildren" />
        </div>
      </div>
      <v-expansion-panels
        class="cts-form-array__body"
        v-bind="sheetAttributes"
        v-model="openedPanels"
        :multiple="true"
        width="100%">
        <cts-expansion-field
          v-for="(child, i) in children"
          :key="`${i} - ${child.name}`"
          :element="child"
          :remove-enabled="removeEnabled"
          :remove-attributes="removeAttributes"
          :remove-confirm-text="confirmRemoveText"
          :remove-confirm-title="confirmRemoveTitle"
          :remove-need-confirm="confirmRemove"
          :remove-need-translate="confirmRemoveNeedTranslate" />
      </v-expansion-panels>
      <template v-if="settings.pageable">
        <v-pagination
          :length="pageCount"
          v-model="page"></v-pagination>
      </template>
    </template>
    <template
      v-if="appendComponent"
      #append>
      <component
        :is="appendComponent"
        :element="element" />
    </template>
  </v-input>
</template>
