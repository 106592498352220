<script setup lang="ts">
import { type PropType, watch, provide, ref, onUnmounted, toRef, type Ref, computed } from 'vue';
import type { DataTableItem, DataTableSort, PartialDataTableConfig } from '../';
import { SortDirection, useDataTable } from '../';
import DataTableViewModeChange from './DataTableViewModeChange.vue';
import { vElementSize } from '@vueuse/components';
import DataTableSortSelect from '@common/lib/modules/datatable/components/DataTableSortSelect.vue';
import { toRaw } from 'vue';

const props = defineProps({
  config: {
    type: Object as PropType<PartialDataTableConfig>,
    required: false
  },
  data: {
    type: Array<any>,
    required: true
  },
  loading: {
    type: Boolean,
    default: false
  },
  sort: {
    type: Object as PropType<DataTableSort>
  }
});

const sort = computed(() => props.sort);

watch(sort, (value) => {
  dataTableService.sort.value = value ?? null;
});

const emit = defineEmits(['globalAction', 'rowAction', 'viewModeChange', 'sort', 'asd', 'selectionChange']);
const dataTableService = useDataTable();
const isLoading = ref(props.loading);
const initialLoading = ref(props.loading);

provide('dataTableService', dataTableService);

watch(
  () => props.config,
  () => dataTableService.init(props.config),
  { deep: true, immediate: true }
);

watch(
  () => props.data,
  () => dataTableService.initItems(props.data),
  { deep: true, immediate: true }
);

watch(
  () => props.loading,
  () => {
    isLoading.value = props.loading;
    if (!isLoading.value) initialLoading.value = false;
  }
);

watch(
  () => dataTableService.getSelectedItems,
  () => {
    const dataTableItems = toRaw(dataTableService.getSelectedItems.value);
    const selectedItems = dataTableItems.map((item) => toRaw(item.data));

    emit('selectionChange', selectedItems);
  },
  { deep: true }
);

onUnmounted(() => {
  dataTableService.autoPreviousViewMode = null;
  dataTableService.mobileViewModeActive = false;
});

const onGlobalAction = (action: string) => {
  emit(
    'globalAction',
    action,
    dataTableService.getSelectedItems.value.map((item) => item.data)
  );
};

const onRowAction = (action: string, item: DataTableItem) => {
  emit('rowAction', action, item.data);
};

const onViewModeChange = (viewMode: string) => {
  emit('viewModeChange', viewMode);
};

const onSort = (sort: DataTableSort | null) => {
  emit('sort', sort);
};

const dataTable = ref(null);

const defaultResize = (size: { width: number; height: number }) => {
  if (size.width === 0 || dataTableService.getConfig.value.showViewModes) return;

  const mobileBreakpoint = dataTableService.getConfig.value.mobileBreakpoint;
  const mobileViewMode = dataTableService.getConfig.value.mobileViewMode;

  if (size.width < mobileBreakpoint && !dataTableService.mobileViewModeActive) {
    dataTableService.autoPreviousViewMode = dataTableService.getCurrentViewMode.value.name;
    dataTableService.changeViewMode(mobileViewMode);
    dataTableService.mobileViewModeActive = true;
  }

  if (size.width >= mobileBreakpoint && dataTableService.mobileViewModeActive && dataTableService.autoPreviousViewMode) {
    dataTableService.changeViewMode(dataTableService.autoPreviousViewMode as string);
    dataTableService.autoPreviousViewMode = null;
    dataTableService.mobileViewModeActive = false;
  }
};

const onResize = (size: { width: number; height: number }) => {
  if (props.config?.onResize !== undefined) {
    props.config.onResize(size, dataTableService, defaultResize);
    return;
  }

  defaultResize(size);
};
</script>

<template>
  <div
    ref="dataTable"
    v-element-size="onResize"
    class="data-table">
    <div class="data-table__top">
      <slot
        name="top"
        :onGlobalAction="onGlobalAction"
        :dataTableService="dataTableService"
        :onViewModeChange="onViewModeChange">
        <slot name="additionalTop"></slot>
        <div class="data-table-actions data-table-actions--top">
          <template
            v-for="(action, i) in dataTableService.getTopGlobalActions.value"
            :key="i">
            <component
              v-if="!action.condition || action.condition(dataTableService.getSelectedItems.value)"
              :is="action.actionComponent"
              :data-index="i"
              :actionConfig="action.actionConfig"
              :selection-only="action.selectionOnly"
              :disabled-condition="action.disabledCondition"
              :is-loading="isLoading || initialLoading"
              :selectedItems="dataTableService.getSelectedItems.value"
              @action="onGlobalAction(action.action)" />
          </template>
        </div>
        <div class="data-table-tools data-table-tools--top">
          <data-table-sort-select
            v-if="dataTableService.hasSortableColumn && dataTableService.getCurrentViewMode.value.showSortSelect"
            @sort="onSort" />
          <data-table-view-mode-change
            v-if="dataTableService.getConfig.value.showViewModes && dataTableService.hasMoreViewModes.value"
            @change="onViewModeChange" />
        </div>
      </slot>
    </div>

    <div class="data-table__body">
      <slot>
        <transition
          name="slide-x-transition"
          mode="out-in">
          <component
            v-if="!initialLoading"
            :is="dataTableService.getCurrentViewMode.value.component"
            :config="dataTableService.getCurrentViewMode.value.componentConfig"
            @row-action="onRowAction"
            @sort="onSort">
          </component>
        </transition>
      </slot>
      <slot
        name="loading"
        :loading="isLoading">
        <v-overlay
          v-model="isLoading"
          :close-on-content-click="false"
          :contained="true"
          :disabled="true"
          :persistent="true"
          class="align-center justify-center">
          {{ $t('dataTable.loading') }}
        </v-overlay>
      </slot>
    </div>

    <div class="data-table__bottom">
      <slot
        name="bottom"
        :onGlobalAction="onGlobalAction"
        :dataTableService="dataTableService"
        :onViewModeChange="onViewModeChange">
        <div class="data-table-actions data-table-actions--bottom">
          <template
            v-for="(action, i) in dataTableService.getBottomGlobalActions.value"
            :key="i">
            <component
              v-if="!action.condition || action.condition(dataTableService.getSelectedItems.value)"
              :is="action.actionComponent"
              :data-index="i"
              :actionConfig="action.actionConfig"
              :selection-only="action.selectionOnly"
              :disabled-condition="action.disabledCondition"
              :selectedItems="dataTableService.getSelectedItems.value"
              :is-loading="isLoading || initialLoading"
              @action="onGlobalAction(action.action)" />
          </template>
        </div>
        <div class="data-table-tools data-table-tools--bottom"></div>
        <slot name="additionalBottom"></slot>
      </slot>
    </div>
  </div>
</template>
