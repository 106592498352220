import { AuthApi } from '../../auth';
import {
  AuthHeaderPlugin,
  DefaultResponsePlugin,
  RefreshTokenPlugin,
  AcceptLanguagePlugin,
  AccessDeniedResponsePlugin,
  AcademicYearHeaderPlugin,
  UneditableYearPlugin
} from '../plugins';
import { createApi } from './create-api.utils';
import { AutocompleteApi } from '../../form/services/autocomplete-api.service';
import { HtmlContentApi } from '../../html-content/services/html-content-api.service';
import { TranslationsApi } from '../../i18n/services/translations-api.service';
import { ValidatorApi } from '../../form/services/validator-api.service';
import { ImporterApiService } from '../../importer/services/importer.service';

const modules = {
  auth: new AuthApi(),
  autocomplete: new AutocompleteApi(),
  htmlContent: new HtmlContentApi(),
  translation: new TranslationsApi(),
  validator: new ValidatorApi(),
  importer: new ImporterApiService()
};

const commonApi = createApi({ modules });
commonApi.registerPlugins([
  AccessDeniedResponsePlugin,
  DefaultResponsePlugin,
  AuthHeaderPlugin,
  AcademicYearHeaderPlugin,
  RefreshTokenPlugin,
  AcceptLanguagePlugin,
  UneditableYearPlugin
]);

export const useCommonApi = () => commonApi.getModules();
