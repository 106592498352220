import { defineStore } from 'pinia';
import { ref, type Ref, watch } from 'vue';
import type { AcademicYear } from '../../models';
import { useRouter } from 'vue-router';

export const useAcademicYearStore = defineStore('academicYearStore', () => {
  const router = useRouter();

  const academicYears: Ref<AcademicYear[]> = ref([]);

  const selectedAcademicYear: Ref<AcademicYear | undefined> = ref(undefined);

  const baseUrls = ['/topics', '/sample-units', '/subjects', '/own/topics', '/own/sample-units'];

  watch(
    () => selectedAcademicYear.value,
    (value) => {
      if (value) {
        const routeFullPath = router.currentRoute.value.fullPath;

        baseUrls.forEach((url) => {
          if (routeFullPath.startsWith(url) && routeFullPath.slice(url.length, routeFullPath.length).length) {
            router.push(url);
          }
        });
      }
    }
  );

  return {
    academicYears,
    selectedAcademicYear
  };
});
