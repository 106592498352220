export interface Entreaty {
  id?: number;
}

export interface EntreatyItem {
  id: number;
  relatedId: number;
  relatedCode: string;
  relatedName: string;
  entreatyType: EntreatyType;
  requestMessage: string;
  responseMessage: string;
  ownerNeptunCode: string;
  status: EntreatyStatus;
}

export enum EntreatyType {
  TOPIC = 'TOPIC',
  SAMPLE_UNIT = 'SAMPLE_UNIT',
  SUBJECT = 'SUBJECT'
}

export enum EntreatyStatus {
  OPEN = 'OPEN',
  PENDING_EVALUATION = 'PENDING_EVALUATION',
  ACCEPTED = 'ACCEPTED',
  PARTIALY_ACCEPTED = 'PARTIALY_ACCEPTED',
  REJECTED = 'REJECTED'
}
