import type { Router } from 'vue-router';
import { useAuthStore } from '../stores/auth.store';
import { isPromise } from '../../../utils';
import { storeToRefs } from 'pinia';

export const createAuthGuard = (router: Router, loginRouteName: string, homeRouteName = '') => {
  router.beforeEach(async (to) => {
    const authStore = useAuthStore();
    const { isAdmin, loggedIn } = storeToRefs(authStore);

    if (authStore.initializingPromise && isPromise(authStore.initializingPromise) && to.name !== loginRouteName) {
      await authStore.initializingPromise;
    }

    if (to.path === '/') {
      if (loggedIn.value) {
        const homeRouteName = isAdmin.value ? 'sampleUnitList' : 'ownSampleUnitList';

        return { name: homeRouteName };
      } else {
        return { name: loginRouteName };
      }
    }

    // check if authentication is required and user is logged in
    if (!to.meta.requiresAuth) return;

    if (to.name != loginRouteName && !authStore.loggedIn) {
      return { name: loginRouteName, query: { redirect: to.fullPath } };
    }

    // check permissions
    const routePermissions = to.meta.permission;
    if (!routePermissions || !routePermissions.length || authStore.hasPermission(routePermissions)) return;

    return { name: homeRouteName };
  });
};
