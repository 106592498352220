import { ApiModule, CurriculumStatus, EditableStatus, type PaginatedResponseData } from '@common/index';
import type { SubjectItem, Subject, SubjectMasterData, SubjectSampleUnitRelatedMasterData } from '../models';

export class SubjectApi extends ApiModule {
  protected endpoint = '/subject';
  protected academicYearEndpoint = '/academic-year';

  public async pageSubjects(params: any) {
    return this.api.post<PaginatedResponseData<SubjectItem>, any>(
      `${this.endpoint}/page?page=${params.page}&size=${params.itemsPerPage}&sort=${params.sort ?? ''}`,
      {
        ...params
      }
    );
  }

  public loadSubjectDetails(id: number) {
    return this.api.get<Subject>(`${this.endpoint}?id=${id}`);
  }

  public saveSubject(values: any, isCopy = false, oldSubjectId?: number) {
    const saveEndpoint = isCopy ? `${this.endpoint}/copy?oldSubjectId=${oldSubjectId}` : `${this.endpoint}`;

    return this.api.post<any, Subject>(`${saveEndpoint}`, {
      ...values
    });
  }

  public finalizeSubject(params: any) {
    return this.api.post<any, Subject>(`${this.endpoint}/finalize`, {
      ...params
    });
  }

  public deleteSubject(id: number) {
    return this.api.delete(`${this.endpoint}?id=${id}`);
  }

  public getMasterData(sampleUnitId: number | undefined, subjectId: number | undefined) {
    return this.api.get<SubjectMasterData>(`${this.endpoint}/master-data`, { params: { sampleUnitId, subjectId } });
  }

  public loadSubjectsForSampleUnit(sampleUnitId: number) {
    return this.api.get<SubjectItem[]>(`${this.endpoint}/by-sample-unit-id?sampleUnitId=${sampleUnitId}`);
  }

  public setSubjectStatus(subjectId: number, status: EditableStatus) {
    return this.api.post<void, any>(`${this.endpoint}/set-status`, {}, { params: { subjectId, status } });
  }

  public loadSubjectSampleUnitRelatedMasterData(sampleUnitId: number) {
    return this.api.get<SubjectSampleUnitRelatedMasterData>(`${this.endpoint}/master-data/by-sample-unit-id`, { params: { sampleUnitId } });
  }

  public setMultipleStatus(subjectIds: number[], status: EditableStatus) {
    return this.api.post<any, any>(`${this.endpoint}/set-multiple-status`, { subjectIds }, { params: { status } });
  }

  public setMultipleCurriculumStatus(subjectIds: number[], status: CurriculumStatus) {
    return this.api.post<any, any>(`${this.endpoint}/set-multiple-curriculum-status`, { subjectIds }, { params: { status } });
  }

  public loadOwnSubjectsForSampleUnit(sampleUnitId: number) {
    return this.api.get<SubjectItem[]>(`${this.endpoint}/own/by-sample-unit-id?sampleUnitId=${sampleUnitId}`);
  }

  public setOwnStatus(subjectId: number, status: EditableStatus) {
    return this.api.post<any, any>(`${this.endpoint}/own/set-status`, {}, { params: { subjectId, status } });
  }

  public validateCodeVersion(params: any) {
    return this.api.get<boolean>(`${this.endpoint}/validate-code-version`, { params });
  }
}
