export default {
  app: {
    title: 'PTE AOK Subject Management'
  },

  pages: {
    index: 'Home'
  },
  menu: {
    logout: 'Logout',
    personalData: 'Personal data',
    dashboard: 'Dashboard',
    userManagement: 'Users',
    academicYear: 'Academic year',
    examType: 'Exam type'
  },

  academicYear: {
    label: {
      code: 'Code'
    },
    filter: {
      keyword: 'Search..'
    },
    details: {
      title: 'Edit Academic year'
    },
    dialog: {
      delete: {
        title: 'Delete Academic year',
        text: 'Confirm delete Academic year?'
      }
    }
  },

  examType: {
    label: {
      code: 'Code',
      name: 'Name'
    },
    filter: {
      keyword: 'Search..'
    },
    details: {
      title: 'Edit Exam type'
    },
    dialog: {
      delete: {
        title: 'Delete Exam type',
        text: 'Confirm delete Exam type?'
      }
    }
  },

  label: {
    yes: 'Igen',
    no: 'Nem'
  },

  user: {
    label: {
      name: 'Name',
      username: 'Username',
      email: 'E-mail',
      role: 'Role',
      enabled: 'Enabled',
      userType: 'Type'
    },
    filter: {
      keyword: 'Keyword',
      type: {
        label: 'Type',
        standard: 'Standard',
        guest: 'Guest'
      },
      enabled: {
        label: 'Enabled',
        true: 'True',
        false: 'False'
      },
      role: {
        admin: 'Admin'
      }
    },
    details: {
      title: 'User data',
      name: 'Name',
      username: 'Username',
      email: 'E-mail',
      enabled: 'Enabled',
      type: 'Type',
      role: 'Role'
    },
    notification: {
      deleteSuccess: 'User is successfully  deleted',
      deleteError: 'Error while delete user',
      userSuccess: 'User data is successfully saved',
      userError: 'Error while save user data',
      activationUserSuccess: 'User is successfully activated',
      activationUserError: 'Error while activate user',
      inactivationUserSuccess: 'User is successfully inactivated',
      inactivationUserError: 'Error while inactivate user'
    }
  },
  form: {
    validator: {
      required: {
        invalidMessage: 'Field is required'
      },
      email: {
        invalidMessage: 'Not valid email type'
      },
      length: {
        invalidMessage: 'Not valid length'
      }
    }
  },

  button: {
    add_new: 'Add new',
    activate: 'Activate',
    inactivate: 'Inactivate',
    activation_email: 'Send activation email',
    edit: 'Edit',
    open: 'Open',
    delete: 'Delete',
    save: 'Save',
    finalize: 'Finalize',
    back: 'Back',
    cancel: 'Cancel',
    submit: 'Submit',
    approve: 'Approve',
    deny: 'Deny',
    summary: 'Summary'
  },

  filter: {
    search: 'Search'
  },

  dashboard: {
    greeting: 'Welcome'
  },

  REGISTER: {
    1000: {
      TEXT: {
        SPIROMETRY: '<h2>Spirometry</h2>',
        SEGMENTS: '<h2>Segments</h2>',
        STAGING: '<h2>Staging</h2>',
        PRIMER_TUMOR_TNM: '<h3>Primer Tumor TNM</h3>',
        OESOPHAGUS: '<h3>Oesophagus TNM</h3>',
        CLINICAL: '<h4>Clinical TNM</h4>',
        PATHOLOGICAL: '<h4>Pathological TNM</h4>',
        DIAGNOSIS_DATA: '<h3>Diagnosis</h3>'
      }
    }
  }
};
