import type { Ref } from 'vue';
import { computed, ref } from 'vue';
import {
  createDataTableColumn,
  createDataTableConfig,
  createDataTableGlobalAction,
  createDataTableItem,
  createDataTableRowAction,
  createDataTableRowDetails,
  type DataTableColumn,
  type DataTableConfig,
  type DataTableGlobalAction,
  type DataTableItem,
  type DataTableRowAction,
  type DataTableSort,
  type DataTableViewMode,
  type PartialDataTableConfig,
  SortDirection
} from '../';
import SelectCellComponent from '../components/data-table-cells/DataTableSelectCell.vue';
import DataTableCellComponent from '../components/data-table-cells/DataTableCell.vue';
import { useDataTableStore } from '../';
import IndexCellComponent from '../components/data-table-cells/DataTableIndexCell.vue';

class DataTableService {
  private config: Ref<DataTableConfig> = ref(createDataTableConfig({}));
  private columns: Ref<DataTableColumn[]> = ref([]);
  private globalActions: Ref<DataTableGlobalAction[]> = ref([]);
  private rowActions: Ref<DataTableRowAction[]> = ref([]);
  private items: Ref<DataTableItem[]> = ref([]);
  private viewModes: Ref<DataTableViewMode[]> = ref([]);
  private currentViewMode: Ref<DataTableViewMode> = ref(null as any as DataTableViewMode);
  private autoPrevViewMode: string | null = null;
  private isMobileViewModeActive = false;
  private currentSort: Ref<DataTableSort | null> = ref(null);
  private hasSortable: Ref<boolean> = ref(false);
  private store = useDataTableStore();
  private rowDetailsData = ref<any>();

  public init(partialConfig?: PartialDataTableConfig, data: any[] = []) {
    this.buildConfig(partialConfig);
    this.buildRowActions();
    this.buildGlobalActions();
    this.buildColumns();
    this.buildRowDetailsData();
    this.initItems(data);
  }

  public initItems(data: any[]) {
    this.buildItems(data);
  }

  public changeViewMode(name: string) {
    const selected = this.viewModes.value.find((mode) => mode.name === name);
    if (!selected || this.currentViewMode.value === selected) return;

    this.currentViewMode.value = selected;

    if (this.config.value.showViewModes) {
      this.store.setViewMode(selected);
    }

    return selected;
  }

  public changeViewModeByIndex(index: number) {
    const selected = this.viewModes.value[index] || null;
    if (!selected) return;

    this.currentViewMode.value = selected;

    if (this.config.value.showViewModes) {
      this.store.setViewMode(selected);
    }

    return selected;
  }

  public getCurrentViewMode = computed(() => this.currentViewMode.value);

  public getCurrentViewModeIndex = computed(() => this.getViewModes.value.findIndex((m) => m.name === this.getCurrentViewMode.value.name));

  public getViewModes = computed(() => this.viewModes.value);

  public hasMoreViewModes = computed(() => this.viewModes.value.length);

  public sort = computed({ get: () => this.currentSort.value, set: (sort) => (this.currentSort.value = sort) });

  public get autoPreviousViewMode() {
    return this.autoPrevViewMode;
  }

  public set autoPreviousViewMode(value) {
    this.autoPrevViewMode = value;
  }

  public get mobileViewModeActive() {
    return this.isMobileViewModeActive;
  }

  public set mobileViewModeActive(value) {
    this.isMobileViewModeActive = value;
  }

  public sortBy(column: string) {
    if (this.currentSort.value && this.currentSort.value?.column === column) {
      if (this.currentSort.value?.direction === SortDirection.ASC) {
        this.currentSort.value.direction = SortDirection.DESC;
      } else {
        this.currentSort.value = null;
      }
      return;
    }

    this.currentSort.value = {
      column,
      direction: SortDirection.ASC
    };
  }

  public sortByDirection(column: string, direction: SortDirection) {
    this.currentSort.value = {
      column,
      direction: direction
    };
  }

  public clearSort() {
    this.currentSort.value = null;
  }

  public hasSortableColumn = computed(() => this.hasSortable.value);

  public selectAll() {
    const selectedItems = this.selectableItems.value.filter((item) => item.selected);

    this.selectableItems.value.forEach((item) => {
      item.selected = selectedItems.length !== this.selectableItems.value.length;
    });
  }

  public isAllSelected = computed(() => {
    return this.selectableItems.value.length > 0 && this.getSelectedItems.value.length === this.selectableItems.value.length;
  });

  public isSomeSelected = computed(() => {
    return this.getSelectedItems.value.length > 0 && !this.isAllSelected.value;
  });

  public selectableItems = computed(() => this.items.value.filter((item) => !item.disabled));

  public getConfig = computed(() => this.config.value);

  public getColumns = computed(() => this.columns.value);

  public getSortableColumns = computed(() => this.getColumns.value.filter((c) => c.sortable));

  public getGlobalActions = computed(() => this.globalActions.value);

  public getTopGlobalActions = computed(() => this.getGlobalActions.value.filter((action) => ['top', 'both'].includes(action.position)));

  public getBottomGlobalActions = computed(() => this.getGlobalActions.value.filter((action) => ['bottom', 'both'].includes(action.position)));

  public getRowActions = computed(() => this.rowActions.value);

  public getItems = computed(() => this.items.value);

  public getSelectedItems = computed(() => this.items.value.filter((item) => item.selected));

  public getRowDetailsData = computed(() => this.rowDetailsData.value);

  private buildConfig(partialConfig?: PartialDataTableConfig) {
    this.config.value = createDataTableConfig(partialConfig || {});
    this.viewModes.value = this.config.value.viewModes;
    this.currentViewMode.value = this.config.value.showViewModes && this.store.viewMode ? this.store.viewMode : this.viewModes.value[0];

    if (this.config.value.showViewModes) {
      this.store.setViewMode(this.currentViewMode.value);
    }
  }

  private buildColumns() {
    const hasColumns = this.config.value.columns.length > 0;

    this.columns.value.splice(0);
    this.addSelectionColumn();
    this.addIndexColumn();

    this.config.value.columns.forEach((columnDefinition) => {
      const column = createDataTableColumn(columnDefinition);

      if (column.sortable) {
        this.hasSortable.value = true;
      }

      this.columns.value.push(column);
    });

    this.addRowActionsColumn();

    if (!hasColumns) {
      this.buildColumnsFromItemKeys();
    }
  }

  private buildGlobalActions() {
    this.globalActions.value.splice(0);

    this.config.value.actions.global.forEach((actionDefinition) => {
      this.globalActions.value.push(createDataTableGlobalAction(actionDefinition));
    });
  }

  private buildRowActions() {
    this.rowActions.value.splice(0);

    this.config.value.actions.row.forEach((actionDefinition) => {
      this.rowActions.value.push(createDataTableRowAction(actionDefinition));
    });
  }

  private buildRowDetailsData() {
    this.rowDetailsData.value = createDataTableRowDetails(this.config.value);
  }

  private buildItems(data: any[]) {
    const hasColumns = this.config.value.columns.length > 0;

    this.items.value.splice(0);

    data.forEach((item) => {
      this.items.value.push(createDataTableItem(item, this.getConfig.value));
    });

    if (!hasColumns) {
      this.buildColumnsFromItemKeys();
    }
  }

  private buildColumnsFromItemKeys() {
    if (this.items.value.length) {
      this.columns.value.splice(0);
      this.addSelectionColumn();

      Object.keys(this.items.value[0].data).forEach((key) => {
        this.columns.value.push(createDataTableColumn({ name: key }));
      });

      this.addRowActionsColumn();
    }
  }

  private addSelectionColumn() {
    if (!this.config.value.selectable) return;

    this.columns.value.push(
      createDataTableColumn({
        name: '__data_table_selected__',
        cellComponent: SelectCellComponent
      })
    );
  }

  private addIndexColumn() {
    if (!this.config.value.showIndexColumn) return;

    this.columns.value.push(
      createDataTableColumn({
        name: '__data_table_index__',
        label: '',
        class: 'col-row-index',
        cellComponent: IndexCellComponent as any
      })
    );
  }

  private addRowActionsColumn() {
    if (!this.rowActions.value.length) return;

    this.columns.value.push(
      createDataTableColumn({
        name: '__data_table_row_actions__',
        label: '',
        cellComponent: DataTableCellComponent
      })
    );
  }
}

export type { DataTableService };

export const useDataTable = (): DataTableService => new DataTableService();
