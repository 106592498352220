import { ApiModule, type PaginatedResponseData } from '@common/index';
import type { EntreatyItem, Entreaty } from '../models';

export class EntreatyApi extends ApiModule {
  protected endpoint = '/entreaty';

  public async pageEntreatys(params: any, ownOnly = false) {
    const pageingEndpoint = ownOnly ? `${this.endpoint}/own/page` : `${this.endpoint}/page`;

    return this.api.post<PaginatedResponseData<EntreatyItem>, any>(
      `${pageingEndpoint}?page=${params.page}&size=${params.itemsPerPage}&sort=${params.sort ?? ''}`,
      {
        ...params
      }
    );
  }

  public loadEntreatyDetails(id: number) {
    return this.api.get<Entreaty>(`${this.endpoint}?id=${id}`);
  }

  public saveEntreaty(params: any) {
    return this.api.post<any, Entreaty>(`${this.endpoint}`, {
      ...params
    });
  }

  public deleteEntreaty(id: number) {
    return this.api.delete(`${this.endpoint}?id=${id}`);
  }

  public deleteMultiple(ids: number[]) {
    return this.api.post<void, any>(`${this.endpoint}/delete-multiple?`, { ids });
  }
}
