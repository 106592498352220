import type { AxiosInstance } from 'axios';
import type { ApiPlugin } from '../';
import { useI18nInstance } from '../../i18n/services/i18n.service';
import type { I18n } from 'vue-i18n';

export class AcceptLanguagePlugin implements ApiPlugin {
  private i18n: I18n<any, any, any, string, false> = useI18nInstance();

  initialize(instance: AxiosInstance): void {
    instance.interceptors.request.use((config) => {
      if (!this.i18n) {
        this.i18n = useI18nInstance();
      }

      const locale = this.i18n?.global.locale.value ? this.i18n?.global.locale.value : 'HU';

      config.headers['Accept-Language'] = locale.toUpperCase();

      return config;
    });
  }
}
