<script lang="ts" setup>
import { type DataTableCellProps } from '../../';
import { toRefs } from 'vue';

const props = defineProps<DataTableCellProps>();

const { item } = toRefs(props);
</script>

<template>
  <slot>
    <v-checkbox
      @click.stop
      :disabled="item.disabled"
      v-model="item.selected"
      hide-details
      color="primary" />
  </slot>
</template>
