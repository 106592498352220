import { FormValidator, type FormValidatorConfig } from '../models';
import { isEmpty } from '../utils';
import { type LengthValidatorSettings } from './length.validator';

export type ArrayLengthValidatorSettings = LengthValidatorSettings;
export type ArrayLengthValidatorConfig = FormValidatorConfig<ArrayLengthValidatorSettings>;

export class ArrayLengthValidator extends FormValidator<ArrayLengthValidatorSettings> {
  constructor() {
    super();
    this._name = 'arrayLength';
    this._settings = {
      invalidMessage: 'form.validator.length.invalidMessage',
      invalidMessageMin: 'form.validator.length.invalidMessageMin',
      invalidMessageMax: 'form.validator.length.invalidMessageMax',
      needTranslate: true
    };
  }

  public override async onValidate(values: any) {
    console.log(values);
    if (isEmpty(values)) {
      this.reset();
      return;
    }
    const min = Math.max(this._settings.min || 0, 0);
    const max = this._settings.max !== undefined ? Math.max(this._settings.max || 0, min) : undefined;
    const inclusive = this._settings.inclusive || false;

    this._valid = this.checkAllValid(values, inclusive, min, max);

    if (!this._valid) {
      this.setMessage(min, max);
    }
  }

  protected checkAllValid = (values: any, inclusive: boolean, min: number, max: number | undefined) => {
    let valid = true;

    values.forEach((value) => {
      const length = value.length;

      const isValid = inclusive
        ? length >= min && (max !== undefined ? length <= max : true)
        : length > min && (max !== undefined ? length < max : true);
      if (!isValid) {
        valid = false;
      }
    });

    return valid;
  };

  protected setMessage = (min: number, max: number | undefined) => {
    let message;
    let placeholders;

    if (min > 0 && max !== undefined) {
      message = this._settings.invalidMessage;
      placeholders = { min, max };
    } else if (min > 0) {
      message = this._settings.invalidMessageMin;
      placeholders = { min };
    } else {
      message = this._settings.invalidMessageMax;
      placeholders = { max };
    }

    this.addMessage(message, 'error', placeholders);
  };
}
