<script lang="ts" setup>
import type { DataTableButtonRowActionProps } from '../../';
import DataTableButtonAction from './DataTableButtonAction.vue';
import { computed } from 'vue';

const props = defineProps<DataTableButtonRowActionProps>();
const emit = defineEmits(['action']);
const isTranslated = computed(() => props.actionConfig?.translateLabel || false);
const isDisabled = computed(() => props.disabledCondition && props.disabledCondition(props.item));

const onAction = () => {
  emit('action', props.item);
};
</script>

<template>
  <data-table-button-action
    :label="actionConfig?.label"
    :color="actionConfig?.color"
    :icon="actionConfig?.icon"
    :prepend-icon="actionConfig?.prependIcon"
    :append-icon="actionConfig?.appendIcon"
    :stacked="actionConfig?.stacked"
    :size="props.actionConfig?.size"
    :icon-size="props.actionConfig?.iconSize"
    :translate-label="isTranslated"
    :disabled="isDisabled"
    @action="onAction" />
</template>
