import { ApiModule, type PaginatedResponseData } from '@common/index';
import type { TopicCategoryItem, TopicCategory } from '../models';

export class TopicCategoryApi extends ApiModule {
  protected endpoint = '/topic-category';

  public async pageTopicCategories(params: any) {
    return this.api.post<PaginatedResponseData<TopicCategoryItem>, any>(
      `${this.endpoint}/page?page=${params.page}&size=${params.itemsPerPage}&sort=${params.sort ?? ''}`,
      {
        ...params
      }
    );
  }

  public loadTopicCategoryDetails(id: number) {
    return this.api.get<TopicCategory>(`${this.endpoint}?id=${id}`);
  }

  public saveTopicCategory(params: any) {
    return this.api.post(`${this.endpoint}`, {
      ...params
    });
  }

  public deleteTopicCategory(id: number) {
    return this.api.delete(`${this.endpoint}?id=${id}`);
  }
}
