import { FormElement, formService } from '@common/lib/modules/form';
import { TopicSumHoursPlugin } from '../modules/topic/plugins/topic-sum-hours.plugin';
import { RequiredOnFinalizeValidator } from '../validators';
import { SampleUnitLangValidator, SampleUnitOneOfRequiredValidator } from '../modules/sample-unit/validators';
import { CampusFacultyCodeUniqueValidator } from '../modules/master-data/campus-faculty/validators/campus-faculty-code-unique.validator';
import { SubjectCodeVersionUniqueValidator, SubjectOneOfRequiredValidator } from '../modules/subject/validators';
import { TeacherNeptunCodeUniqueValidator } from '../modules/teacher/validators';
import { UserNeptunCodeUniqueValidator } from '../modules/admin/validators/user-neptun-code-unique.validator';
import { EditingPeriodEndValidator } from '../modules/master-data/academic-year/validators/editing-period-end.validator';
import { SubjectPrerequisiteEntreatyListElement } from '../modules/sample-unit/relations/subject-prerequisite-entreaty/components';
import { SampleCurriculumCodeGenPlugin } from '../modules/master-data/sample-curriculum/plugins/sample-curriculum-code-gen.plugin';
import { SpecialityGroupTypeMatchValidator } from '../modules/export/validators/speciality-group-type-match.validator';

export const registerValidators = () => {
  formService.registerValidator('requiredOnFinalize', RequiredOnFinalizeValidator);
  formService.registerValidator('sampleUnitLangValidator', SampleUnitLangValidator);
  formService.registerValidator('sampleUnitOneOfRequired', SampleUnitOneOfRequiredValidator);
  formService.registerValidator('campusFacultyCodeUniqueValidator', CampusFacultyCodeUniqueValidator);
  formService.registerValidator('subjectOneOfRequired', SubjectOneOfRequiredValidator);
  formService.registerValidator('teacherNeptunCodeUniqueValidator', TeacherNeptunCodeUniqueValidator);
  formService.registerValidator('userNeptunCodeUniqueValidator', UserNeptunCodeUniqueValidator);
  formService.registerValidator('specialityGroupTypeMatch', SpecialityGroupTypeMatchValidator);
  formService.registerValidator('editingPeriodEndValidator', EditingPeriodEndValidator);
  formService.registerValidator('subjectCodeVersionUnique', SubjectCodeVersionUniqueValidator);
};

export const registerFilters = () => {
  // formService.registerFilter('name', Component);
};

export const registerPlugins = () => {
  formService.registerPlugin('topicSumHours', TopicSumHoursPlugin);
  formService.registerPlugin('sampleCurriculumCodeGen', SampleCurriculumCodeGenPlugin);
};

export const registerFormElements = () => {
  formService.registerFormElement('subjectPrerequisiteEntreatyList', {
    class: FormElement,
    componentClass: SubjectPrerequisiteEntreatyListElement
  });
};

export const initForms = () => {
  registerFormElements();
  registerValidators();
  registerFilters();
  registerPlugins();
};
