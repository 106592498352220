<script lang="ts" setup>
import { inject } from 'vue';
import { type DataTableGridViewModeProps, type DataTableItem, useDataTable } from '../../';

defineProps<DataTableGridViewModeProps>();
const emit = defineEmits(['rowAction']);
const dataTableService = inject<ReturnType<typeof useDataTable>>('dataTableService')!;

const onRowAction = (action: string, item: DataTableItem) => {
  emit('rowAction', action, item);
};
</script>

<template>
  <div class="data-table-view data-table-view--grid">
    <v-container :fluid="config.grid.fluid">
      <v-row>
        <v-col
          v-for="(item, i) in dataTableService.getItems.value"
          :key="i"
          :cols="config.grid.cols"
          :sm="config.grid.sm"
          :md="config.grid.md"
          :lg="config.grid.lg"
          :xl="config.grid.xl"
          :xxl="config.grid.xxl">
          <component
            :is="config.cardComponent"
            :row-actions="dataTableService.getRowActions.value"
            :item="item"
            :index="i"
            :columns="dataTableService.getColumns.value"
            :clickable-items="dataTableService.getConfig.value.clickableItems"
            @row-action="onRowAction" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
