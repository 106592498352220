import { type FormConfig, type PartialDataManagerPaginatorConfig, type PartialDataTableConfig } from '@common/index';
import { DataManagerPaginatorPosition } from '@common/index';
import DataTableTableViewModeVue from '@common/lib/modules/datatable/components/data-table-view-modes/DataTableTableViewMode.vue';

export const SUBJECT_PREREQUISITE_ENTREATY_TABLE_CONFIG: PartialDataTableConfig = {
  needTableHeader: true,
  showViewModes: false,
  fixedHeader: true,
  selectable: false,
  rowClass: (item: any) => {
    return item.data.isEntreated ? 'entreated-row' : undefined;
  },
  viewModes: [
    {
      name: 'table',
      label: 'dataTable.viewMode.table',
      icon: 'mdi-view-list',
      translateLabel: true,
      showSortSelect: false,
      component: DataTableTableViewModeVue as any,
      componentConfig: {}
    }
  ],
  columns: [
    {
      name: 'prerequisiteName',
      label: 'subject.relations.prerequisite.prerequisiteName',
      translateLabel: true,
      needCardHeader: true
    },
    {
      name: 'prerequisiteCode',
      label: 'subject.relations.prerequisite.prerequisiteCode',
      translateLabel: true,
      needCardHeader: true
    },
    {
      name: 'prerequisiteSemester',
      label: 'subject.relations.prerequisite.prerequisiteSemester',
      translateLabel: true,
      needCardHeader: true
    },
    {
      name: 'type',
      label: 'subject.relations.prerequisite.type',
      cellConfig: {
        translate: true,
        translatePrefix: 'subject.relations.prerequisite.types.'
      },
      translateLabel: true,
      needCardHeader: true
    }
  ],
  actions: {
    global: [
      {
        action: 'add_new',
        position: 'bottom',
        actionConfig: {
          label: 'button.add_new',
          translateLabel: true,
          color: 'primary',
          class: 'my-3 mr-3'
        },
        disabledCondition: (_, items) => {
          return items.length >= 3;
        }
      }
    ],
    row: [
      {
        action: 'open',
        actionConfig: {
          label: 'button.edit',
          translateLabel: true,
          icon: 'mdi-pencil',
          size: 'small'
        }
      },
      {
        action: 'delete',
        actionConfig: {
          label: 'button.delete',
          translateLabel: true,
          icon: 'mdi-delete',
          size: 'small'
        }
      }
    ]
  }
};

export const SUBJECT_PREREQUISITE_ENTREATY_PAGINATOR_CONFIG: PartialDataManagerPaginatorConfig = {
  itemsPerPage: [5, 10, 50, 100],
  rounded: true,
  position: DataManagerPaginatorPosition.NONE
};

export const SUBJECT_PREREQUISITE_ENTREATY_LIST_FILTER_FORM_CONFIG: FormConfig = {
  name: 'subjectPrerequisiteEntreatyListFilter',
  attributes: {
    variant: 'outlined',
    density: 'compact',
    color: 'primary'
  },
  children: []
};
