import { computed, toRaw, watch, type Ref, ref } from 'vue';
import type {
  AsyncAutocompleteSettings,
  FormArrayAttributes,
  FormArraySettings,
  FormConfig,
  FormElementConfig,
  FormGroupAttributes,
  FormGroupSettings,
  FormSettings,
  FormStepAttributes,
  FormStepSettings,
  NumberAttributes,
  SelectableFormElementAttributes,
  SelectableFormElementSettings,
  StepperFormAttributes,
  StepperFormSettings
} from '../models';
import {
  FormElement,
  Form,
  SelectableFormElement,
  FormGroupElement,
  StepperForm,
  FormStepElement,
  FormArrayElement,
  ExpansionFormArrayElement
} from '../models';
import { useI18n } from 'vue-i18n';
import { debounce } from 'vue-debounce';
import { useIsLoading } from '../../../composables';
import { useCommonApi } from '../../api';
import type { DisplayAttributes, DisplaySettings } from '../models';
import { isEmpty } from '../';

export const useFormElement = (element: FormElement<any>) => {
  const translateLabel = () => {
    const { t } = useI18n();
    const label = element.attributes.label;
    const needTranslate = element.settings.needTranslate || false;

    if (!label || !needTranslate) return label;

    return t(label);
  };

  const translateHint = () => {
    const { t } = useI18n();
    const hint = element.attributes.hint;
    const needTranslate = element.settings.needHintTranslate || false;

    if (!hint || !needTranslate) return hint;

    return t(hint);
  };

  const translateSuffix = () => {
    const { t } = useI18n();
    const suffix = element.attributes.suffix;
    const needTranslate = element.settings.needSuffixTranslate || false;

    if (!suffix || !needTranslate) return suffix;

    return t(suffix);
  };

  const translateInfoBlock = () => {
    const { t } = useI18n();
    const infoBlock = element.settings.infoBlock;
    const needTranslate = element.settings.needTranslateInfoBlock || false;

    if (!infoBlock || !needTranslate) return infoBlock;

    return t(infoBlock);
  };

  return {
    // defaults
    element: element,
    component: computed(() => element.component),
    componentSettings: computed(() => element.componentSettings),
    name: computed(() => element.name),
    fullName: computed(() => element.fullName),
    settings: computed(() => element.settings),
    attributes: computed(() => element.attributes),
    children: computed(() => element.children),
    enabled: computed({ get: () => element.enabled, set: (enabled) => (element.enabled = enabled) }),
    visible: computed({ get: () => element.visible, set: (visible) => (element.visible = visible) }),
    readonly: computed({ get: () => element.readonly, set: (readonly) => (element.readonly = readonly) }),
    dirty: computed({ get: () => element.dirty, set: (dirty) => (element.dirty = dirty) }),
    initialized: computed(() => element.initialized),
    focused: computed(() => element.focused),
    loading: computed({ get: () => element.loading, set: (loading) => (element.loading = loading) }),
    value: computed({ get: () => element.value, set: (value) => (element.value = value) }),
    valid: computed({ get: () => element.valid, set: (valid) => (element.valid = valid) }),
    needsReRender: computed({ get: () => element.needsReRender, set: (needs) => (element.needsReRender = needs) }),
    messages: computed({ get: () => element.messages, set: (messages) => (element.messages = messages) }),
    message: computed(() => element.message),
    removable: computed(() => element.removable),
    validatorChain: element.validatorChain,
    currentValidation: element.currentValidation,
    beforeValueChange: element.beforeValueChange,
    // functions
    build: element.build.bind(element),
    initialize: element.initialize.bind(element),
    addChild: element.addChild.bind(element),
    getChild: element.getChild.bind(element),
    removeChildByIndex: element.removeChildByIndex.bind(element),
    removeChildByName: element.removeChildByName.bind(element),
    removeFromParent: element.removeFromParent.bind(element),
    addFilter: element.addFilter.bind(element),
    getFilter: element.getFilter.bind(element),
    hasFilter: element.hasFilter.bind(element),
    removeFilter: element.removeFilter.bind(element),
    clearFilters: element.clearFilters.bind(element),
    filter: element.filter.bind(element),
    filterValue: element.filterValue.bind(element),
    mask: element.mask.bind(element),
    maskValue: element.maskValue.bind(element),
    addValidator: element.addValidator.bind(element),
    getValidator: element.getValidator.bind(element),
    validate: element.validate.bind(element),
    resetValidator: element.resetValidator.bind(element),
    addPlugin: element.addPlugin.bind(element),
    getPlugin: element.getPlugin.bind(element),
    hasPlugin: element.hasPlugin.bind(element),
    removePlugin: element.removePlugin.bind(element),
    clearPlugins: element.clearPlugins.bind(element),
    onInput: element.onInput.bind(element),
    onFocus: element.onFocus.bind(element),
    onBlur: element.onBlur.bind(element),
    // extra
    dirtyWithChildren: computed({ get: () => element.dirtyWithChildren, set: (dirty) => (element.dirtyWithChildren = dirty) }), //
    messagesAsString: computed(() => element.message?.message),
    hasMessages: computed(() => element.messages.length > 0),
    errorMessages: computed(() => element.messages.filter((msg) => msg.type === 'error')),
    hasErrorMessages: computed(() => element.messages.filter((msg) => msg.type === 'error').length > 0),
    warningMessages: computed(() => element.messages.filter((msg) => msg.type === 'warning')),
    hasWarningMessages: computed(() => element.messages.filter((msg) => msg.type === 'warning').length > 0),
    infoMessages: computed(() => element.messages.filter((msg) => msg.type === 'info')),
    hasInfoMessages: computed(() => element.messages.filter((msg) => msg.type === 'info').length > 0),
    isValidating: element.currentValidation !== undefined,
    // settings
    gridSettings: computed(() => element.settings.grid),
    spacerSettings: computed(() => element.settings.spacerSettings),
    hideGridSlotWhenInvisible: computed(() => element.settings.hideGridSlotWhenInvisible),
    needTranslate: computed(() => element.settings.needTranslate),
    needHintTranslate: computed(() => element.settings.needHintTranslate),
    needValueTranslate: computed(() => element.settings.needValueTranslate),
    valueTranslatePrefix: computed(() => element.settings.valueTranslatePrefix),
    labelComponent: computed(() => toRaw(element.settings.labelComponent)),
    prependComponent: computed(() => toRaw(element.settings.prependComponent)),
    prependInnerComponent: computed(() => toRaw(element.settings.prependInnerComponent)),
    appendComponent: computed(() => toRaw(element.settings.appendComponent)),
    appendInnerComponent: computed(() => toRaw(element.settings.appendInnerComponent)),
    appendOuterComponent: computed(() => toRaw(element.settings.appendOuterComponent)),
    infoBlock: computed(() => translateInfoBlock()),

    // attributes
    label: computed(() => translateLabel()),
    hint: computed(() => translateHint()),
    suffix: computed(() => translateSuffix()),
    color: computed(() => element.getParentDependentAttribute('color')),
    bgColor: computed(() => element.getParentDependentAttribute('bgColor')),
    density: computed(() => element.getParentDependentAttribute('density')),
    variant: computed(() => element.getParentDependentAttribute('variant'))
  };
};

export const useSelectableFormElement = (element: SelectableFormElement<any>) => {
  const settings = element.settings as SelectableFormElementSettings;
  const attributes = element.attributes as SelectableFormElementAttributes;

  const translateItems = () => {
    const { t } = useI18n();
    const items = element.items;
    const itemTitle = attributes.itemTitle || 'title';
    const itemValue = attributes.itemValue || 'value';
    const needItemTranslate = settings.needItemTranslate || false;
    const translatePrefix = settings.itemTranslatePrefix || '';

    if (!needItemTranslate) return items;
    const mappedItems = items.map((item) => (typeof item === 'string' ? { [itemTitle]: item, [itemValue]: item } : item));

    return mappedItems.map((item) => ({ ...item, [itemTitle]: t(translatePrefix + item[itemTitle]) }));
  };

  const itemTitleKey = computed(() => attributes.itemTitle || 'title');
  const itemValueKey = computed(() => attributes.itemValue || 'value');

  return {
    // defaults
    ...useFormElement(element),
    settings: computed(() => settings),
    attributes: computed(() => attributes),
    items: computed({ get: () => translateItems(), set: (items) => (element.items = items) }),
    // settings
    needItemTranslate: computed(() => settings.needItemTranslate),
    itemTranslatePrefix: computed(() => settings.itemTranslatePrefix),
    direction: computed(() => settings.direction),

    isColumn: computed(() => settings.direction === 'column'),
    isInline: computed(() => settings.direction === 'inline'),
    itemLabel: (item: any): string => item[itemTitleKey.value],
    itemValue: (item: any) => item[itemValueKey.value],
    itemComponent: computed(() => toRaw(settings.itemComponent)),
    itemLabelComponent: computed(() => toRaw(settings.itemLabelComponent)),
    itemPrependComponent: computed(() => toRaw(settings.itemPrependComponent)),
    itemPrependInnerComponent: computed(() => toRaw(settings.itemPrependInnerComponent)),
    itemAppendComponent: computed(() => toRaw(settings.itemAppendComponent)),
    itemAppendInnerComponent: computed(() => toRaw(settings.itemAppendInnerComponent))
  };
};

export const useAutoCompleteFormElement = (element: SelectableFormElement<any>) => {
  const autocompleteApi = useCommonApi().autocomplete;
  const { isLoading, handlePromiseLoading } = useIsLoading();

  const selectableFormElement = useSelectableFormElement(element);
  const searchValue: Ref<any> = ref(undefined);

  const attributes = element.attributes as SelectableFormElementAttributes;
  const settings = computed(() => element.settings as AsyncAutocompleteSettings);

  const itemValueKey = computed(() => attributes.itemValue || 'value');

  const backendUrl = computed(() => {
    return settings.value.backendUrl;
  });

  const additionalParams = computed(() => {
    return settings.value.additionalParams;
  });

  const noDataText = computed(() => {
    return settings.value.noDataText;
  });

  const loading = computed(() => {
    if (backendUrl.value) {
      return isLoading.value;
    }

    return selectableFormElement.loading.value;
  });

  // TODO_FIX: search
  watch(
    searchValue,
    debounce(async () => {
      if (selectableFormElement.focused.value && searchValue.value && searchValue.value.length) {
        await querySelections(searchValue);
      }
    }, 300)
  );

  watch(selectableFormElement.value, (value) => {
    if (!attributes.multiple && (!value || (Array.isArray(value) && !value.length))) {
      searchValue.value = '';
    }
  });

  const findOptionByValue = (value: any) => {
    return element.items.find((item) => item[itemValueKey.value] === value);
  };

  const querySelections = async (searchValue: Ref<string>) => {
    const searchText = toRaw(searchValue.value);

    let persistentOptions = [] as any[];
    if (element.value) {
      const options = attributes.multiple ? element.value.map((val) => findOptionByValue(val)) : [findOptionByValue(element.value)];
      persistentOptions = options.filter((option) => !!option);
    }

    if (backendUrl.value) {
      const response = await handlePromiseLoading(autocompleteApi.search(backendUrl.value, { searchText, ...additionalParams.value }));
      const loadedOptions = response.data as any[];

      const filteredOptions = loadedOptions.filter(
        (item) => !persistentOptions.some((option) => option[itemValueKey.value] === item[itemValueKey.value])
      );

      element.items = [...persistentOptions, ...filteredOptions];
    } else {
      if (searchText) {
        element.items = element.items.filter((item) => selectableFormElement.itemLabel(item).toLowerCase().includes(searchText.toLowerCase()));
      }
    }
  };

  return {
    ...selectableFormElement,
    noDataText,
    loading,
    searchValue,
    querySelections
  };
};

export const useFormGroupElement = (element: FormGroupElement) => {
  const settings = element.settings as FormGroupSettings;
  const attributes = element.attributes as FormGroupAttributes;

  return {
    // defaults
    ...useFormElement(element),
    settings: computed(() => settings),
    attributes: computed(() => attributes),
    sheetAttributes: computed(() => element.sheetAttributes)
  };
};

export const useFormArrayElement = (element: FormArrayElement) => {
  const settings = element.settings as FormArraySettings;
  const attributes = element.attributes as FormArrayAttributes;

  const confirmTitle = (confirm: any, defaultValue: string) => {
    return typeof confirm === 'object' ? confirm.title || defaultValue : defaultValue;
  };

  const confirmText = (confirm: any, defaultValue: string) => {
    return typeof confirm === 'object' ? confirm.text || defaultValue : defaultValue;
  };

  const confirmNeedTranslate = (confirm: any) => {
    return typeof confirm === 'object' ? confirm.needTranslate !== false : true;
  };

  //TODO_FORM: is this good for form array paging?
  const pageSize = settings.pageSize ?? 10;
  const pageCount = Math.ceil(element.children.length / pageSize);
  const page = ref(1);
  const childrenPage = computed(() => {
    const pageStart = 0 + pageSize * (page.value - 1);
    const pageEnd = pageStart + pageSize;
    const sliceEnd = pageEnd >= element.children.length ? element.children.length : pageEnd;

    return element.children.slice(pageStart, sliceEnd);
  });

  return {
    // defaults
    ...useFormGroupElement(element),
    settings: computed(() => settings),
    attributes: computed(() => attributes),
    addEnabled: computed(() => settings.actions?.add?.enabled || false),
    maxChildrenCount: computed(() => settings.actions?.add?.maxChildrenCount || 0),
    addBtnEnabled: computed(() => {
      const max = settings.actions?.add?.maxChildrenCount || 0;
      return max <= 0 || element.children.length < max;
    }),
    addAttributes: computed(() => settings.actions?.add?.attributes || {}),
    removeEnabled: computed(() => settings.actions?.remove?.enabled || false),
    removeAttributes: computed(() => settings.actions?.remove?.attributes || {}),
    clearEnabled: computed(() => settings.actions?.clear?.enabled || false),
    clearBtnEnabled: computed(() => element.children.filter((child) => child.removable).length > 0),
    clearAttributes: computed(() => settings.actions?.clear?.attributes || {}),
    childTemplate: computed(() => settings.actions?.childTemplate),
    confirmClear: computed(() => settings.actions?.clear?.confirm !== false),
    confirmClearTitle: computed(() => confirmTitle(typeof settings.actions?.clear?.confirm, 'form.input.formArray.confirmClearTitle')),
    confirmClearText: computed(() => confirmText(typeof settings.actions?.clear?.confirm, 'form.input.formArray.confirmClearText')),
    confirmClearNeedTranslate: computed(() => confirmNeedTranslate(typeof settings.actions?.clear?.confirm)),
    confirmRemove: computed(() => settings.actions?.remove?.confirm !== false),
    confirmRemoveTitle: computed(() => confirmTitle(typeof settings.actions?.remove?.confirm, 'form.input.formArray.confirmRemoveTitle')),
    confirmRemoveText: computed(() => confirmText(typeof settings.actions?.remove?.confirm, 'form.input.formArray.confirmRemoveText')),
    confirmRemoveNeedTranslate: computed(() => confirmNeedTranslate(typeof settings.actions?.remove?.confirm)),
    children: computed(() => (settings.pageable ? childrenPage.value : element.children)),
    allOpenedByDefault: computed(() => settings.allOpenedByDefault || false),
    firstOpenedByDefault: computed(() => settings.firstOpenedByDefault || false),
    add: () => {
      if (!settings.actions?.childTemplate) return null;
      const addAsRemovable = settings.actions?.addAsRemovable !== false;
      return element.addChild(settings.actions.childTemplate as FormElementConfig, undefined, addAsRemovable);
    },
    clear: () => {
      const removableOnly = settings.actions?.clearRemovableOnly !== false;
      element.clearChildren(removableOnly);
    },
    page,
    pageCount
  };
};

export const useExpansionFormArrayElement = (element: ExpansionFormArrayElement) => {
  const openedPanels = computed({ get: () => element.openedPanels, set: (value) => (element.openedPanels = value) });

  return {
    ...useFormArrayElement(element),
    openedPanels
  };
};

export const useFormStepElement = (element: FormStepElement) => {
  const settings = element.settings as FormStepSettings;
  const attributes = element.attributes as FormStepAttributes;

  return {
    // defaults
    ...useFormElement(element),
    settings: computed(() => settings),
    attributes: computed(() => attributes),
    step: computed(() => element.step),
    stepRule: computed(() => element.stepRule),
    editable: computed(() => element.step === 1 || element.dirtyWithChildren),
    validWithChildren: computed(() => element.validWithChildren)
  };
};

export const useNumberFormElement = (element: FormElement) => {
  const attributes = element.attributes as NumberAttributes;
  const step = attributes.step ? attributes.step : 1.0;
  const min = attributes.min;
  const max = attributes.max;

  const increaseNumber = () => {
    let value = roundIncreaseNumber(element.value || 0);
    if (max !== undefined) {
      value = Math.min(max, value);
    }

    element.value = value;
    element.dirty = true;
  };

  const roundIncreaseNumber = (value: any) => {
    return Math.round((parseFloat(value) + step) * 100) / 100;
  };

  const decreaseNumber = () => {
    let value = roundDecreaseNumber(element.value || 0);
    if (min !== undefined) {
      value = Math.max(min, value);
    }

    element.value = value;
    element.dirty = true;
  };

  const roundDecreaseNumber = (value: any) => {
    return Math.round((parseFloat(value) - step) * 100) / 100;
  };

  return {
    // defaults
    ...useFormElement(element),
    attributes: computed(() => attributes),
    increaseNumber: increaseNumber,
    decreaseNumber: decreaseNumber
  };
};

export const useDisplayFormElement = (element: FormElement) => {
  const settings = element.settings as DisplaySettings;
  const attributes = element.attributes as DisplayAttributes;

  const translateValue = () => {
    const { t } = useI18n();
    let value = element.value;
    const needTranslate = element.settings.needValueTranslate || false;
    const prefix = element.settings.valueTranslatePrefix || '';
    const separator = settings.arrayJoinSeparator || ', ';

    if (typeof value === 'string' && settings.nl2Br === true) {
      value = value.replace('\n', '<br>');
    }

    if (isEmpty(value) || !needTranslate) {
      if (Array.isArray(value)) {
        return (value as string[]).join(separator);
      }

      return value;
    }

    if (Array.isArray(value)) {
      return (value as string[]).map((item) => t(`${prefix}${item}`)).join(separator);
    }

    return t(`${prefix}${value}`);
  };

  return {
    // defaults
    ...useFormElement(element),
    settings: computed(() => settings),
    attributes: computed(() => attributes),
    maxHeight: computed(() => settings.maxHeight),
    ellipsis: computed(() => settings.ellipsis),
    tooltip: computed(() => settings.tooltip),
    tooltipLocation: computed(() => settings.tooltipLocation || 'top'),
    tooltipMaxWidth: computed(() => settings.tooltipMaxWidth || 400),
    translatedValue: computed(() => translateValue()),
    nl2Br: computed(() => settings.nl2Br || false),
    displayHtmlContent: computed(() => settings.displayHtmlContent || false)
  };
};

export const createForm = (config: FormConfig, values?: any) => {
  const form = new Form(config);
  form.build();

  if (values !== undefined) {
    form.value = { ...form.value, ...values };
  }

  const noAutoInitialize = form.settings.autoInitialize === false;
  if (!noAutoInitialize) {
    form.initialize();
  }

  return returnForm(form);
};

export const useForm = (form: Form) => {
  return returnForm(form);
};

export type ComputedForm = ReturnType<typeof useForm>;

export const createStepperForm = (config: FormConfig, values?: any) => {
  const form = new StepperForm(config);
  form.build();

  if (values !== undefined) {
    form.value = { ...form.value, ...values };
  }

  const noAutoInitialize = form.settings.autoInitialize === false;
  if (!noAutoInitialize) {
    form.initialize();
  }

  return returnStepperForm(form);
};

export const useStepperForm = (form: StepperForm) => {
  return returnStepperForm(form);
};

export type ComputedStepperForm = ReturnType<typeof useStepperForm>;

const returnForm = (form: Form) => {
  const settings = form.settings as FormSettings;

  return {
    ...useFormElement(form),
    element: form,
    settings: computed(() => settings),
    fieldAnimation: computed(() =>
      typeof settings.fieldAnimation === 'string' ? settings.fieldAnimation : settings.fieldAnimation === undefined ? 'slide-y-transition' : undefined
    ),
    submit: form.submit.bind(form),
    generateSubmitData: form.generateSubmitData.bind(form),
    scrollToFirstInvalid: form.scrollToFirstInvalid
  };
};

const returnStepperForm = (form: StepperForm) => {
  const settings = form.settings as StepperFormSettings;
  const attributes = form.attributes as StepperFormAttributes;

  const translateActionLabel = (label?: string) => {
    const { t } = useI18n();
    const needTranslate = settings.needActionTranslate || false;

    if (!label || !needTranslate) return label;

    return t(label);
  };

  return {
    ...useFormElement(form),
    element: form,
    settings: computed(() => settings),
    attributes: computed(() => attributes),
    children: computed(() => form.children as FormStepElement[]),
    stepperAttributes: computed(() => form.stepperAttributes),
    step: computed({ get: () => form.step, set: (step) => (form.step = step) }),
    currentStepElement: computed(() => form.children.at(Math.max(form.step - 1, 0)) as FormStepElement),
    stepperColor: computed(() => form.stepperAttributes.color),
    showActions: computed(() => (settings.showActions !== undefined ? settings.showActions : true)),
    showSubmitBtn: computed(() => settings.showSubmitBtn || false),
    nextLabel: computed(() => translateActionLabel(settings.nextLabel)),
    prevLabel: computed(() => translateActionLabel(settings.prevLabel)),
    submitLabel: computed(() => translateActionLabel(settings.submitLabel)),
    fieldAnimation: computed(() =>
      typeof settings.fieldAnimation === 'string' ? settings.fieldAnimation : settings.fieldAnimation === undefined ? 'slide-y-transition' : undefined
    ),
    submit: form.submit.bind(form)
  };
};
