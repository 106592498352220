import { ApiModule, type PaginatedResponseData } from '@common/index';
import type { CampusFacultyItem, CampusFaculty } from '../models';

export class CampusFacultyApi extends ApiModule {
  protected endpoint = '/campus-faculty';

  public async pageCampusFacultys(params: any) {
    return this.api.post<PaginatedResponseData<CampusFacultyItem>, any>(
      `${this.endpoint}/page?page=${params.page}&size=${params.itemsPerPage}&sort=${params.sort ?? ''}`,
      {
        ...params
      }
    );
  }

  public getCampusFaculties() {
    return this.api.get<any[]>(`${this.endpoint}/list`);
  }

  public loadCampusFacultyDetails(id: number) {
    return this.api.get<CampusFaculty>(`${this.endpoint}?id=${id}`);
  }

  public saveCampusFaculty(params: any) {
    return this.api.post(`${this.endpoint}`, {
      ...params
    });
  }

  public deleteCampusFaculty(id: number) {
    return this.api.delete(`${this.endpoint}?id=${id}`);
  }

  public validateCode(params: any) {
    return this.api.get<boolean>(`${this.endpoint}/validate-code`, { params });
  }
}
