import axios, { type AxiosInstance, type AxiosRequestConfig, type CreateAxiosDefaults } from 'axios';
import type { ApiPlugin, ApiResponse } from '../';
import qs from 'qs';

const defaultConfig = {
  baseURL: import.meta.env.VITE_API_URL,
  withCredentials: false,
  headers: {
    common: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  },
  paramsSerializer: function (params) {
    return qs.stringify(params, { arrayFormat: 'repeat' });
  }
};

export abstract class ApiModule {
  protected api!: Api;

  public get Api() {
    return this.api;
  }

  public set Api(api: Api) {
    this.api = api;
  }
}

export class Api<TModules = any> {
  private readonly axiosInstance: AxiosInstance;
  private readonly plugins: ApiPlugin[];
  private readonly modules: TModules;

  constructor(config?: CreateAxiosDefaults, modules: TModules = {} as TModules) {
    this.axiosInstance = axios.create({
      ...defaultConfig,
      ...(config || {})
    });

    this.plugins = [];
    this.modules = modules;

    for (const name in this.modules) {
      (this.modules[name] as ApiModule).Api = this;
    }
  }

  public getModules() {
    return this.modules;
  }

  public registerPlugin(plugin: { new (): ApiPlugin }) {
    if (this.plugins.find((p) => typeof p === typeof plugin)) {
      return;
    }

    const pluginInstance = new plugin();
    pluginInstance.initialize(this.axiosInstance);
    this.plugins.push(pluginInstance);
  }

  public registerPlugins(plugins: { new (): ApiPlugin }[]) {
    plugins.forEach((plugin) => {
      this.registerPlugin(plugin);
    });
  }

  public request<TData>(config: AxiosRequestConfig): Promise<ApiResponse<TData>> {
    return this.axiosInstance.request(config);
  }

  public get<TData>(url: string, config?: AxiosRequestConfig): Promise<ApiResponse<TData>> {
    return this.axiosInstance.get(url, config);
  }

  public delete<TData>(url: string, config?: AxiosRequestConfig): Promise<ApiResponse<TData>> {
    return this.axiosInstance.delete(url, config);
  }

  public head<TData>(url: string, config?: AxiosRequestConfig): Promise<ApiResponse<TData>> {
    return this.axiosInstance.head(url, config);
  }

  public options<TData>(url: string, config?: AxiosRequestConfig): Promise<ApiResponse<TData>> {
    return this.axiosInstance.options(url, config);
  }

  public post<TData, TPostData = void>(url: string, data?: TPostData, config?: AxiosRequestConfig): Promise<ApiResponse<TData>> {
    return this.axiosInstance.post(url, data, config);
  }

  public put<TData, TPutData = void>(url: string, data?: TPutData, config?: AxiosRequestConfig): Promise<ApiResponse<TData>> {
    return this.axiosInstance.put(url, data, config);
  }

  public patch<TData, TPatchData = void>(url: string, data?: TPatchData, config?: AxiosRequestConfig): Promise<ApiResponse<TData>> {
    return this.axiosInstance.patch(url, data, config);
  }

  public postForm<TData, TPostData = void>(url: string, data?: TPostData, config?: AxiosRequestConfig): Promise<ApiResponse<TData>> {
    return this.axiosInstance.postForm(url, data, config);
  }

  public putForm<TData, TPutData = void>(url: string, data?: TPutData, config?: AxiosRequestConfig): Promise<ApiResponse<TData>> {
    return this.axiosInstance.putForm(url, data, config);
  }

  public patchForm<TData, TPatchData = void>(url: string, data?: TPatchData, config?: AxiosRequestConfig): Promise<ApiResponse<TData>> {
    return this.axiosInstance.patchForm(url, data, config);
  }
}
