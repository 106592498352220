export enum Permission {
  CONTEXT_RELATION_SAVE_DATA = 'context_relation.saveData',
  MENU_ADMINISTRATION = 'menu.administration',
  MENU_ADMINISTRATION_MASTER_DATA = 'menu.administration.master_data',
  MENU_CONTEXT_INSTITUTES = 'menu.context.institutes',
  MENU_CONTEXT_SUBJECTS = 'menu.context.subjects',
  MENU_PERSONAL_DATA = 'menu.personal_data',
  MENU_NOTIFICATIONS = 'menu.notifications',

  MENU_TOPIC_TEACHER = 'menu.teacher.topic'
}
