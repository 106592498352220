<script setup lang="ts">
import ModalsContainer from '@common/lib/modules/modal/components/ModalsContainer.vue';
import NotificationsContainer from '@common/lib/modules/notification/components/NotificationsContainer.vue';
import { useAuthStore } from '@common/lib/modules/auth';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';

const authStore = useAuthStore();
const { isAdmin } = storeToRefs(authStore);
const theme = computed(() => (isAdmin.value ? 'adminLight' : 'light'));
</script>

<template>
  <v-app :theme="theme">
    <router-view />
    <modals-container />
    <notifications-container />
  </v-app>
</template>
