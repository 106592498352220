<script lang="ts" setup>
import { inject } from 'vue';
import { type DataTableItem, type DataTableSort, useDataTable } from '../../';
import DataTableTableColumn from './table-view/DataTableTableColumn.vue';
import DataTableTableCell from './table-view/DataTableTableCell.vue';
import DataTableTableRow from '@common/lib/modules/datatable/components/data-table-view-modes/table-view/DataTableTableRow.vue';

const dataTableService = inject<ReturnType<typeof useDataTable>>('dataTableService')!;
const emit = defineEmits(['rowAction', 'sort']);

const onRowAction = (action: string, item: DataTableItem) => {
  emit('rowAction', action, item);
};

const onSort = (sort: DataTableSort | null) => {
  emit('sort', sort);
};
</script>

<template>
  <v-table
    class="data-table-view data-table-view--table"
    :class="{ 'data-table-view--table-selectable': dataTableService.getConfig.value.selectable }"
    :fixed-header="dataTableService.getConfig.value.fixedHeader"
    :fixed-footer="dataTableService.getConfig.value.fixedFooter"
    :height="dataTableService.getConfig.value.height"
    :hover="dataTableService.getConfig.value.hover">
    <thead v-if="dataTableService.getConfig.value.needTableHeader">
      <tr>
        <data-table-table-column
          v-for="(column, i) in dataTableService.getColumns.value"
          :key="column.name + i"
          :column="column"
          :sorted="dataTableService.sort"
          @sort="onSort" />
      </tr>
    </thead>
    <tbody>
      <data-table-table-row
        v-for="(item, i) in dataTableService.getItems.value"
        :key="i"
        :index="i"
        :item="item"
        @row-action="onRowAction" />
    </tbody>
  </v-table>
</template>
