<script setup lang="ts">
import { computed, inject, type PropType, ref, watch } from 'vue';
import DataTableTableCell from './DataTableTableCell.vue';
import { type DataTableItem, useDataTable } from '@common/lib/modules/datatable';

const dataTableService = inject<ReturnType<typeof useDataTable>>('dataTableService')!;
const props = defineProps({
  item: {
    type: Object as PropType<DataTableItem>,
    required: true
  },
  index: {
    type: Number,
    required: true
  }
});
const emit = defineEmits(['rowAction']);

const rowDetailsOpen = ref(false);

const onRowAction = (action: string, item: DataTableItem) => {
  emit('rowAction', action, item);
};

const onRowClick = (item: DataTableItem) => {
  if (rowDetailsComponent.value) {
    rowDetailsOpen.value = !rowDetailsOpen.value;
  }

  if (!dataTableService.getConfig.value.clickableItems) return;
  onRowAction('click', item);
};

const rowDetailsComponent = computed(() => dataTableService.getRowDetailsData.value.component);
const rowDetailsConfig = computed(() => dataTableService.getRowDetailsData.value.config);
const rowClass = computed(() => {
  const rClass = dataTableService.getConfig.value.rowClass;
  if (typeof rClass === 'function') {
    return rClass.call(this, props.item);
  } else if (typeof rClass === 'string') {
    return rClass;
  }
  return undefined;
});

watch(
  () => props.item.data,
  () => (rowDetailsOpen.value = false)
);
</script>

<template>
  <tr
    class="data-table-row"
    :class="[
      {
        disabled: item.disabled,
        'data-table-row--odd': !(index % 2),
        'data-table-row--even': !!(index % 2)
      },
      rowClass
    ]"
    @click="onRowClick(item)">
    <data-table-table-cell
      v-for="(column, j) in dataTableService.getColumns.value"
      :key="column.name + index + j"
      :item="item"
      :index="index"
      :column="column"
      @row-action="onRowAction" />
  </tr>
  <transition
    v-if="rowDetailsComponent"
    name="slide-y-transition"
    mode="out-in">
    <tr
      v-if="rowDetailsOpen"
      class="data-table-row-details">
      <td
        :colspan="dataTableService.getColumns.value.length"
        class="pa-0 ma-0 h-0 position-relative">
        <component
          :is="rowDetailsComponent"
          :item="item"
          :config="rowDetailsConfig" />
      </td>
    </tr>
  </transition>
</template>
