import { ApiModule, type PaginatedResponseData } from '@common/index';
import type { PositionItem, Position } from '../models';

export class PositionApi extends ApiModule {
  protected endpoint = '/position';

  public async pagePositions(params: any) {
    return this.api.post<PaginatedResponseData<PositionItem>, any>(
      `${this.endpoint}/page?page=${params.page}&size=${params.itemsPerPage}&sort=${params.sort ?? ''}`,
      {
        ...params
      }
    );
  }

  public loadPositionDetails(id: number) {
    return this.api.get<Position>(`${this.endpoint}?id=${id}`);
  }

  public savePosition(params: any) {
    return this.api.post(`${this.endpoint}`, {
      ...params
    });
  }

  public deletePosition(id: number) {
    return this.api.delete(`${this.endpoint}?id=${id}`);
  }
}
