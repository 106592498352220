import { ApiModule, type PaginatedResponseData } from '@common/index';
import type { EducationalRequirementItem, EducationalRequirement } from '../models';

export class EducationalRequirementApi extends ApiModule {
  protected endpoint = '/educational-requirement';

  public async pageEducationalRequirements(params: any) {
    return this.api.post<PaginatedResponseData<EducationalRequirementItem>, any>(
      `${this.endpoint}/page?page=${params.page}&size=${params.itemsPerPage}&sort=${params.sort ?? ''}`,
      {
        ...params
      }
    );
  }

  public loadEducationalRequirementDetails(id: number) {
    return this.api.get<EducationalRequirement>(`${this.endpoint}?id=${id}`);
  }

  public saveEducationalRequirement(params: any) {
    return this.api.post(`${this.endpoint}`, {
      ...params
    });
  }

  public deleteEducationalRequirement(id: number) {
    return this.api.delete(`${this.endpoint}?id=${id}`);
  }
}
