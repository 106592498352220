import { Permission } from '@common/index';
import type { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
  {
    path: '/topics',
    name: 'topicData',
    meta: {
      requiresAuth: true,
      permission: Permission.MENU_ADMINISTRATION
    },
    children: [
      {
        path: '',
        name: 'topicList',
        component: () => import('../pages/TopicListPage.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'create',
        name: 'topicCreateContainer',
        component: () => import('../pages/TopicDetailsPage.vue'),
        props: true,
        children: [
          {
            path: '',
            name: 'topicCreate',
            component: () => import('../containers/TopicDetails.vue'),
            props: true
          }
        ]
      },
      {
        path: ':topicId',
        name: 'topicEditor',
        children: [
          {
            path: 'summary',
            name: 'topicSummary',
            component: () => import('../pages/TopicSummaryPage.vue'),
            props: true
          },
          {
            path: '',
            name: 'topicDetails',
            component: () => import('../pages/TopicDetailsPage.vue'),
            props: true,
            children: [
              {
                path: '',
                name: 'topicEdit',
                component: () => import('../containers/TopicDetails.vue'),
                props: true
              },
              {
                path: 'copy',
                name: 'topicCopy',
                component: () => import('../containers/TopicDetails.vue'),
                props: (route) => ({ ...route.params, isCopy: true })
              },
              {
                path: 'entreaty',
                name: 'topicEntreaty',
                component: () => import('../containers/TopicEntreatyDetails.vue'),
                props: true
              },
              {
                path: 'specialities',
                name: 'topicSpeciality',
                component: () => import('../containers/TopicSpeciality.vue'),
                props: true
              },
              {
                path: 'clinical-skill',
                name: 'topicSpecClinicalSkill',
                component: () => import('../containers/TopicSpecClinicalSkill.vue'),
                props: true
              },
              {
                path: 'educational-requirement',
                name: 'topicSpecEducationalRequirement',
                component: () => import('../containers/TopicSpecEducationalRequirement.vue'),
                props: true
              },
              {
                path: 'outcome',
                name: 'topicHasOutcome',
                component: () => import('../containers/TopicHasOutcome.vue'),
                props: true
              },
              {
                path: 'sample-units',
                name: 'topicSampleUnitList',
                component: () => import('../containers/TopicSampleUnit.vue'),
                props: true
              }
            ]
          },
          {
            path: 'sample-units',
            name: 'topicSampleUnit',
            children: [
              {
                path: 'create',
                name: 'topicSampleUnitDetailsCreate',
                component: () => import('../../sample-unit/pages/SampleUnitDetailsPage.vue'),
                props: true,
                children: [
                  {
                    path: '',
                    name: 'topicSampleUnitCreate',
                    component: () => import('../../sample-unit/containers/SampleUnitDetails.vue'),
                    props: true
                  }
                ]
              },
              {
                path: ':sampleUnitId',
                name: 'topicSampleUnitDetailsContainer',
                props: true,
                children: [
                  {
                    path: '',
                    name: 'topicSampleUnitDetails',
                    component: () => import('../../sample-unit/pages/SampleUnitDetailsPage.vue'),
                    props: true,
                    children: [
                      {
                        path: '',
                        name: 'topicSampleUnitEdit',
                        component: () => import('../../sample-unit/containers/SampleUnitDetails.vue'),
                        props: true
                      },
                      {
                        path: 'copy',
                        name: 'topicSampleUnitCopy',
                        component: () => import('../../sample-unit/containers/SampleUnitDetails.vue'),
                        props: (route) => ({ ...route.params, isCopy: true })
                      },
                      {
                        path: 'entreaty',
                        name: 'topicSampleUnitEntreaty',
                        component: () => import('../../sample-unit/containers/SampleUnitEntreatyDetails.vue'),
                        props: true
                      },
                      {
                        path: 'subject-entreaty',
                        name: 'topicSubjectEntreaty',
                        component: () => import('../../sample-unit/containers/SubjectEntreatyDetails.vue'),
                        props: true
                      },
                      {
                        path: 'lectures',
                        name: 'topicSampleUnitHasLesson',
                        component: () => import('../../sample-unit/containers/SampleUnitHasLesson.vue'),
                        props: true
                      },
                      {
                        path: 'examiners',
                        name: 'topicSampleUnitHasExaminer',
                        component: () => import('../../sample-unit/containers/SampleUnitHasExaminer.vue'),
                        props: true
                      },
                      {
                        path: 'courseTeachers',
                        name: 'topicSampleUnitHasCourseTeacher',
                        component: () => import('../../sample-unit/containers/SampleUnitHasCourseTeacher.vue'),
                        props: true
                      },
                      {
                        path: 'subjects',
                        name: 'topicSampleUnitSubjectList',
                        component: () => import('../../sample-unit/containers/SampleUnitSubject.vue'),
                        props: true
                      }
                    ]
                  },
                  {
                    path: 'subjects',
                    name: 'topicSampleUnitSubject',
                    props: true,
                    children: [
                      {
                        path: 'create',
                        component: () => import('../../subject/pages/SubjectDetailsPage.vue'),
                        props: true,
                        children: [
                          {
                            path: '',
                            name: 'topicSampleUnitSubjectCreate',
                            props: true,
                            component: () => import('../../subject/containers/SubjectDetails.vue')
                          }
                        ]
                      },
                      {
                        path: ':subjectId',
                        name: 'topicSampleUnitSubjectDetails',
                        component: () => import('../../subject/pages/SubjectDetailsPage.vue'),
                        props: true,
                        children: [
                          {
                            path: '',
                            name: 'topicSampleUnitSubjectEdit',
                            component: () => import('../../subject/containers/SubjectDetails.vue'),
                            props: true
                          },
                          {
                            path: 'copy',
                            name: 'topicSampleUnitSubjectCopy',
                            component: () => import('../../subject/containers/SubjectDetails.vue'),
                            props: (route) => ({ ...route.params, isCopy: true })
                          },
                          {
                            path: 'prerequisite',
                            name: 'topicSampleUnitSubjectPrerequisite',
                            component: () => import('../../subject/containers/SubjectPrerequisite.vue'),
                            props: true
                          },
                          {
                            path: 'prerequisiteFor',
                            name: 'topicSampleUnitSubjectPrerequisiteFor',
                            component: () => import('../../subject/containers/SubjectPrerequisiteFor.vue'),
                            props: true
                          },
                          {
                            path: 'sample-curriculum',
                            name: 'topicSampleUnitSubjectHasSampleCurriculum',
                            component: () => import('../../subject/containers/SubjectHasSampleCurriculum.vue'),
                            props: true
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: 'own',
    children: [
      {
        path: 'topics',
        name: 'ownTopicData',
        children: [
          {
            path: '',
            name: 'ownTopicList',
            component: () => import('../pages/TeacherTopicListPage.vue')
          },
          {
            path: ':topicId',
            name: 'ownTopicEditor',
            children: [
              {
                path: 'summary',
                name: 'ownTopicSummary',
                component: () => import('../pages/TeacherTopicSummaryPage.vue'),
                props: true
              },
              {
                path: '',
                name: 'ownTopicDetails',
                component: () => import('../pages/TeacherTopicDetailsPage.vue'),
                props: true,
                children: [
                  {
                    path: '',
                    name: 'ownTopicEdit',
                    component: () => import('../containers/teacher/TeacherTopicDetails.vue'),
                    props: true
                  },
                  {
                    path: 'clinical-skill',
                    name: 'ownTopicSpecClinicalSkill',
                    component: () => import('../containers/TopicSpecClinicalSkill.vue'),
                    props: true
                  },
                  {
                    path: 'educational-requirement',
                    name: 'ownTopicSpecEducationalRequirement',
                    component: () => import('../containers/TopicSpecEducationalRequirement.vue'),
                    props: true
                  },
                  {
                    path: 'outcome',
                    name: 'ownTopicHasOutcome',
                    component: () => import('../containers/TopicHasOutcome.vue'),
                    props: true
                  },
                  {
                    path: 'entreaty',
                    name: 'ownTopicEntreaty',
                    component: () => import('../containers/teacher/TeacherTopicEntreatyDetails.vue'),
                    props: true
                  },
                  {
                    path: 'sample-units',
                    name: 'ownTopicSampleUnitList',
                    component: () => import('../containers/teacher/TeacherTopicSampleUnit.vue'),
                    props: true
                  }
                ]
              },
              {
                path: 'sample-units',
                name: 'ownTopicSampleUnit',
                children: [
                  {
                    path: 'create',
                    name: 'ownTopicSampleUnitDetailsCreate',
                    component: () => import('../../sample-unit/pages/teacher/TeacherSampleUnitDetailsPage.vue'),
                    props: true,
                    children: [
                      {
                        path: '',
                        name: 'ownTopicSampleUnitCreate',
                        component: () => import('../../sample-unit/containers/teacher/TeacherSampleUnitDetails.vue'),
                        props: true
                      }
                    ]
                  },
                  {
                    path: ':sampleUnitId',
                    name: 'ownTopicSampleUnitDetailsContainer',
                    props: true,
                    children: [
                      {
                        path: '',
                        name: 'ownTopicSampleUnitDetails',
                        component: () => import('../../sample-unit/pages/teacher/TeacherSampleUnitDetailsPage.vue'),
                        props: true,
                        children: [
                          {
                            path: '',
                            name: 'ownTopicSampleUnitEdit',
                            component: () => import('../../sample-unit/containers/teacher/TeacherSampleUnitDetails.vue'),
                            props: true
                          },
                          {
                            path: 'copy',
                            name: 'ownTopicSampleUnitCopy',
                            component: () => import('../../sample-unit/containers/teacher/TeacherSampleUnitDetails.vue'),
                            props: (route) => ({ ...route.params, isCopy: true })
                          },
                          {
                            path: 'lectures',
                            name: 'ownTopicSampleUnitHasLesson',
                            component: () => import('../../sample-unit/containers/SampleUnitHasLesson.vue'),
                            props: true
                          },
                          {
                            path: 'examiners',
                            name: 'ownTopicSampleUnitHasExaminer',
                            component: () => import('../../sample-unit/containers/SampleUnitHasExaminer.vue'),
                            props: true
                          },
                          {
                            path: 'courseTeachers',
                            name: 'ownTopicSampleUnitHasCourseTeacher',
                            component: () => import('../../sample-unit/containers/SampleUnitHasCourseTeacher.vue'),
                            props: true
                          },
                          {
                            path: 'entreaty',
                            name: 'ownTopicSampleUnitEntreaty',
                            component: () => import('../../sample-unit/containers/teacher/TeacherSampleUnitEntreatyDetails.vue'),
                            props: true
                          },
                          {
                            path: 'subject-entreaty',
                            name: 'ownTopicSubjectEntreaty',
                            component: () => import('../../sample-unit/containers/teacher/TeacherSubjectEntreatyDetails.vue'),
                            props: true
                          },
                          {
                            path: 'subjects',
                            name: 'ownTopicSampleUnitSubjectList',
                            component: () => import('../../sample-unit/containers/teacher/TeacherSampleUnitSubject.vue'),
                            props: true
                          }
                        ]
                      },
                      {
                        path: 'subjects',
                        name: 'ownTopicSampleUnitSubject',
                        props: true,
                        children: [
                          {
                            path: 'create',
                            component: () => import('../../subject/pages/teacher/TeacherSubjectDetailsPage.vue'),
                            props: true,
                            children: [
                              {
                                path: '',
                                name: 'ownTopicSampleUnitSubjectCreate',
                                props: true,
                                component: () => import('../../subject/containers/teacher/TeacherSubjectDetails.vue')
                              }
                            ]
                          },
                          {
                            path: ':subjectId',
                            name: 'ownTopicSampleUnitSubjectDetails',
                            component: () => import('../../subject/pages/teacher/TeacherSubjectDetailsPage.vue'),
                            props: true,
                            children: [
                              {
                                path: '',
                                name: 'ownTopicSampleUnitSubjectEdit',
                                component: () => import('../../subject/containers/teacher/TeacherSubjectDetails.vue'),
                                props: true
                              },
                              {
                                path: 'copy',
                                name: 'ownTopicSampleUnitSubjectCopy',
                                component: () => import('../../subject/containers/teacher/TeacherSubjectDetails.vue'),
                                props: (route) => ({ ...route.params, isCopy: true })
                              },
                              {
                                path: 'prerequisite',
                                name: 'ownTopicSampleUnitSubjectPrerequisite',
                                component: () => import('../../subject/containers/SubjectPrerequisite.vue'),
                                props: true
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];

export default routes;
