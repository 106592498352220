import type { AxiosInstance } from 'axios';
import type { ApiPlugin } from '..';
import { useModals } from '../../modal';

export class UneditableYearPlugin implements ApiPlugin {
  initialize(instance: AxiosInstance): void {
    instance.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.status === 400 && error.data.data.message === 'uneditable_year') {
          return this.handleUneditableYear(error);
        }
        return Promise.reject(error);
      }
    );
  }

  private handleUneditableYear = async (error) => {
    const modals = useModals();

    modals.openDialog({
      title: 'uneditableYearDialog.title',
      color: 'red-lighten-1',
      text: 'uneditableYearDialog.text',
      persistent: true,
      translate: true
    });

    return Promise.reject(error);
  };
}
