import { type FormElement, FormElementPlugin } from '@common/lib/modules/form';

export class TopicSumHoursPlugin extends FormElementPlugin {
  public constructor() {
    super();
    this._name = 'topicSumHours';
  }

  public override onValueChange(element: FormElement<any>): void {
    this._updateSumValue(element);
  }

  protected _updateSumValue(element: FormElement<any>): void {
    const sumElement = element.parent?.getChild('sumHours');
    if (!sumElement) return;

    const lectureElement = element.parent?.getChild('lectureHours');
    const practiceElement = element.parent?.getChild('practiceHours');
    const seminarElement = element.parent?.getChild('seminarHours');

    const lectureValue = Number(lectureElement?.value || 0);
    const practiceValue = Number(practiceElement?.value || 0);
    const seminarValue = Number(seminarElement?.value || 0);

    sumElement.value = lectureValue + practiceValue + seminarValue;
  }
}
