import type { AsyncAutocompleteConfig, FormConfig, SelectConfig } from '@common/index';

export const SUBJECT_PREREQUISITE_ENTREATY_DETAILS_FORM_CONFIG: FormConfig = {
  name: 'subjectPrerequisiteEntreatyDetailsForm',
  attributes: {
    color: 'primary',
    variant: 'outlined',
    density: 'compact'
  },
  children: [
    {
      component: 'select',
      name: 'subjectId',
      attributes: {
        label: 'subject.relations.prerequisite.subjectName',
        multiple: false,
        itemTitle: 'label',
        itemValue: 'value'
      },
      settings: {
        needTranslate: true,
        disabled: true,
        items: []
      }
    } as SelectConfig,
    {
      component: 'asyncAutocomplete',
      name: 'prerequisiteId',
      attributes: {
        label: 'subject.relations.prerequisite.prerequisiteName',
        itemTitle: 'subjectLabelWithSemester',
        itemValue: 'id'
      },
      settings: {
        backendUrl: '/subject-prerequisite-entreaty/search',
        needItemTranslate: false,
        needTranslate: true,
        grid: { cols: 12 }
      },
      validators: [{ validator: 'required' }]
    } as AsyncAutocompleteConfig,
    {
      component: 'select',
      name: 'type',
      attributes: {
        label: 'subject.relations.prerequisite.type',
        multiple: false,
        itemTitle: 'label',
        itemValue: 'value'
      },
      settings: {
        needTranslate: true,
        needItemTranslate: true,
        items: [
          { label: 'subject.relations.prerequisite.types.FINISHED', value: 'FINISHED' },
          { label: 'subject.relations.prerequisite.types.PARALLEL', value: 'PARALLEL' }
        ]
      },
      validators: [
        {
          validator: 'required'
        }
      ]
    } as SelectConfig
  ]
};
