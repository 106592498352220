<script lang="ts" setup>
import type { PropType } from 'vue';
import type { Form } from '../models';
import { useForm } from '../composables';
import { CtsField } from '../components';

const props = defineProps({
  form: {
    type: Object as PropType<Form>,
    required: true
  }
});

const emit = defineEmits(['submit']);

const { enabled, attributes, children, submit, fieldAnimation } = useForm(props.form);

const onSubmit = async () => {
  const data = await submit();
  emit('submit', data);
};
</script>

<template>
  <v-form
    v-bind="attributes"
    :disabled="!enabled"
    @submit.prevent="onSubmit">
    <v-row :dense="attributes.density === 'compact'">
      <cts-field
        v-for="(child, i) in children"
        :key="i"
        :element="child"
        :field-animation="fieldAnimation" />
    </v-row>
  </v-form>
</template>
