export default {
  dataTable: {
    viewMode: {
      table: 'Table view',
      grid: 'Grid view'
    },
    sort: {
      sort: 'Sort',
      none: 'Default'
    },
    selectAll: 'Select all'
  },
  dataManager: {
    paginator: {
      itemsPerPage: 'Items per page'
    }
  },
  modal: {
    save: 'Save',
    close: 'Close',
    cancel: 'Cancel',
    confirm: 'Confirm'
  },
  validation: {
    required: 'The field is required!',
    companyNumber: 'Not valid company number format! (BS-CF-NNNNNN)',
    minChars: 'Minimum {min} character!',
    maxChars: 'Maximum {max} character!',
    email: 'Not valid email format!'
  },
  form: {
    input: {
      datePicker: {
        selectDate: 'Choose a date'
      },
      formArray: {
        confirmClearTitle: 'Clear all items',
        confirmClearText: 'Are you sure you want to clear all the elements?',
        confirmRemoveTitle: 'Remove item',
        confirmRemoveText: 'Are you sure you want to remove the element?'
      }
    }
  },
  login: {
    signIn: 'Sign in',
    email: 'E-mail',
    password: 'Password',
    rememberMe: 'Remember me',
    forgotPassword: 'Forgot password',
    backToLogin: 'Back to login',
    sendEmail: 'Send email',
    username: 'Username'
  },
  notification: {
    default: {
      success: 'The operation was successful',
      error: 'There was an error while processing your request'
    }
  },
  auth: {
    newPassword: 'New password',
    passwordConfirm: 'Confirm password',
    setPassword: 'Setup password',
    name: 'Name',
    email: 'E-mail',
    phoneNumber: 'Phone number',
    personalData: 'Personal data',
    changePassword: 'Change password',
    currentPassword: 'Current password',
    validation: {
      passwordMustMatch: 'Passwords must match!',
      passwordMinLength: 'The password must be at least {min} characters long!',
      passwordFormat: 'The password must contain a lowercase letter, an uppercase letter, a number and a special character!'
    },
    hint: {
      passwordFormat:
        'The password must be at least {min} characters long, contain lowercase letters, uppercase letters, numbers and special characters!'
    },
    btn: {
      save: 'Save'
    },
    notification: {
      savePersonalData: {
        success: 'Personal data save is successfull!',
        error: 'An error occurred during modification!'
      },
      saveCustomerDetails: {
        success: 'Save user is successfull!',
        error: 'An error occurred during modification!'
      },
      createCustomer: {
        success: 'User create is succesfull!',
        error: 'An error occurred during create!'
      },
      changePassword: {
        success: 'Password change is successfull!',
        error: 'An error occurred during password change!'
      },
      api: {
        noActiveAccount: 'Unsuccessfull login! Incorrect email or password!'
      }
    }
  }
};
