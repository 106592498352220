import { ApiModule } from '@common/index';
import type { Configuration } from '../model/information.model';

export class InformationApi extends ApiModule {
  protected endpoint = '/application';

  public loadInformations() {
    return this.api.get<Configuration>(`${this.endpoint}/configuration`);
  }
}
