import { createApp } from 'vue';
import App from './App.vue';
import { createPinia } from 'pinia';
import { createVuetify } from '@common/lib/modules/vuetify';
import { createRouter } from '@common/lib/modules/router';
import { createI18n } from '@common/lib/modules/i18n';
import { createAuthGuard } from '@common/lib/modules/auth/services/auth-router.service';
import routes from './app/routes';
import themes from './app/themes';
import messages from './i18n';
import VueBlocksTree from 'vue3-blocks-tree';
import 'vue3-blocks-tree/dist/vue3-blocks-tree.css';
import '@ssthouse/vue3-tree-chart/dist/vue3-tree-chart.css';
import './assets/scss/styles.scss';
import { initForms } from './app/utils/form.utils';

declare global {
  const VITE_APP_VERSION: string;
}

const i18n = createI18n('hu', messages);
const app = createApp(App);
const pinia = createPinia();
const router = createRouter(routes);
const vuetify = createVuetify(i18n, themes);

initForms();

createAuthGuard(router, 'login');

app.use(pinia);
app.use(router);
app.use(i18n);
app.use(vuetify);
app.use(VueBlocksTree);
app.mount('#app');
