<script setup lang="ts">
import { Editor } from '@tiptap/vue-3';
import { computed, type PropType } from 'vue';
import type { HtmlEditorToolbarAction } from '@common/lib/modules/form';
import { useI18n } from 'vue-i18n';

const props = defineProps({
  editor: {
    type: Object as PropType<Editor>,
    required: true
  },
  action: {
    type: Object as PropType<HtmlEditorToolbarAction>,
    required: true
  }
});

const emit = defineEmits(['action']);

const { t } = useI18n();

const label = computed(() => {
  if (typeof props.action.label === 'function') {
    const label = props.action.label(props.editor);
    return props.action.needTranslate === true ? t(label) : label;
  }

  return props.action.needTranslate === true ? t(props.action.label || '') : props.action.label;
});

const icon = computed(() => {
  if (typeof props.action.icon === 'function') {
    return props.action.icon(props.editor);
  }

  return (props.action.icon as string) || 'mdi-none';
});

const classes = computed(() => {
  if (typeof props.action.class === 'function') {
    return props.action.class(props.editor);
  }

  return props.action.class as string;
});

const attributes = computed(() => {
  if (typeof props.action.attributes === 'function') {
    return props.action.attributes(props.editor);
  }

  return props.action.attributes || {};
});

const disabled = computed(() => {
  if (typeof props.action.disabled === 'function') {
    return props.action.disabled(props.editor);
  }

  return props.action.disabled || false;
});

const active = computed(() => {
  if (typeof props.action.active === 'function') {
    return props.action.active(props.editor);
  }

  return props.action.active || false;
});

const onClick = () => {
  const { from, to } = props.editor.state.selection;
  props.action.action(props.editor);
  props.editor.commands.setTextSelection({ from, to });
  props.editor.commands.focus();
  emit('action');
};
</script>

<template>
  <v-btn
    class="px-4"
    size="small"
    icon="true"
    v-bind="attributes"
    :class="[
      classes,
      {
        'v-btn--active': active
      }
    ]"
    :disabled="disabled"
    @click.prevent.stop="onClick">
    <v-tooltip
      v-if="label"
      activator="parent"
      location="top"
      :text="label" />
    <v-icon :icon="icon" />
  </v-btn>
</template>
