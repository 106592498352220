import { Permission } from '@common/index';
import type { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
  {
    path: 'exports',
    name: 'exportData',
    meta: {
      requiresAuth: true,
      permission: Permission.MENU_ADMINISTRATION
    },
    children: [
      {
        path: '',
        name: 'exports',
        component: () => import('../pages/ExportsPage.vue')
      }
    ]
  }
];

export default routes;
