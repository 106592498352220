import { ApiModule, type PaginatedResponseData } from '@common/index';
import type { TopicHasSpecialityItem, TopicHasSpeciality } from '../models';

export class TopicHasSpecialityApi extends ApiModule {
  protected endpoint = '/topic-has-speciality';
  protected topicEndpoint = '/topic';

  public async pageTopicHasSpecialitys(params: any) {
    return this.api.post<PaginatedResponseData<TopicHasSpecialityItem>, any>(
      `${this.endpoint}/page?page=${params.page}&size=${params.itemsPerPage}&sort=${params.sort ?? ''}`,
      {
        ...params
      }
    );
  }

  public async listTopicHasSpecialitys(params: any) {
    return this.api.get<TopicHasSpecialityItem[]>(`${this.endpoint}/list`, {
      params: {
        ...params
      }
    });
  }

  public loadTopicHasSpecialityDetails(id: number) {
    return this.api.get<TopicHasSpeciality>(`${this.endpoint}?id=${id}`);
  }

  public saveTopicHasSpeciality(params: any) {
    return this.api.post(`${this.endpoint}`, {
      ...params
    });
  }

  public deleteTopicHasSpeciality(id: number) {
    return this.api.delete(`${this.endpoint}?id=${id}`);
  }

  public getTopicData(id: number) {
    return this.api.get<any>(`${this.topicEndpoint}?id=${id}`);
  }

  public getAvaiableSpecialities(topicId: number, topicHasSpecialityId: number | undefined) {
    return this.api.get<any>(`${this.endpoint}/avaiable-specialities?topicId=${topicId}&topicHasSpecialityId=${topicHasSpecialityId ?? ''}`);
  }
}
