import type { ThemeDefinition } from 'vuetify';

const light: ThemeDefinition = {
  dark: false,
  colors: {
    background: '#f0f4f5',
    'on-surface': '#363636',
    primary: '#121D46',
    'primary-light': '#9DA8C4',
    'primary-lighter': '#F2F8FA',
    'primary-lightest': '#E1EDF0',
    secondary: '#0A112A',
    'secondary-light': '#FFF1F0',
    yellow: '#F3BC14',
    green: '#22D2A8',
    success: '#4caf50',
    info: '#3498ff',
    'info-lighten-1': '#f0f9ff',
    warning: '#ffb300',
    error: '#df2026'
  }
};

const adminLight: ThemeDefinition = {
  dark: false,
  colors: {
    background: '#f0f4f5',
    'on-surface': '#363636',
    primary: '#00B7BD',
    'primary-light': '#9DA8C4',
    'primary-lighter': '#F2F8FA',
    'primary-lightest': '#E1EDF0',
    secondary: '#027376',
    'secondary-light': '#FFF1F0',
    yellow: '#F3BC14',
    green: '#22D2A8',
    success: '#4caf50',
    info: '#3498ff',
    'info-lighten-1': '#f0f9ff',
    warning: '#ffb300',
    error: '#df2026'
  }
};

const dark: ThemeDefinition = {
  dark: true,
  colors: {}
};

export default { light, adminLight, dark };
