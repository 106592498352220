<script setup lang="ts">
import { computed, type PropType } from 'vue';
import { type FormElement } from '../../models';
import { useFormElement } from '../../composables';
import { isEmpty } from '@common/lib/modules/form';

const props = defineProps({
  element: {
    type: Object as PropType<FormElement>,
    required: true
  },
  label: {
    type: String
  },
  props: {
    type: Object
  }
});

const { getValidator, attributes, settings } = useFormElement(props.element);

const requiredValidatorNames = computed(() => {
  const requiredIndicatorValidatorNames = ['required'];

  if (
    settings.value.custom?.requiredIndicatorValidatorNames &&
    !isEmpty(settings.value.custom.requiredIndicatorValidatorNames) &&
    Array.isArray(settings.value.custom.requiredIndicatorValidatorNames)
  ) {
    requiredIndicatorValidatorNames.push(...settings.value.custom.requiredIndicatorValidatorNames);
  } else if (
    props.element.form &&
    props.element.form.settings.custom?.requiredIndicatorValidatorNames &&
    !isEmpty(props.element.form.settings.custom.requiredIndicatorValidatorNames) &&
    Array.isArray(props.element.form.settings.custom.requiredIndicatorValidatorNames)
  ) {
    requiredIndicatorValidatorNames.push(...props.element.form.settings.custom.requiredIndicatorValidatorNames);
  }

  return requiredIndicatorValidatorNames;
});

const hasRequiredValidator = computed(
  () => requiredValidatorNames.value.some((name) => !!getValidator(name)) || ('required' in attributes.value && attributes.value.required === true)
);
</script>

<template>
  <div v-if="label">
    <span>
      <slot name="label">
        {{ label }}
      </slot>
    </span>
    <span
      v-if="hasRequiredValidator"
      class="required-indicator">
      *
    </span>
  </div>
</template>
