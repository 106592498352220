import { markRaw } from 'vue';
import type {
  CellConfig,
  DataTableColumn,
  DataTableConfig,
  DataTableGlobalAction,
  DataTableItem,
  DataTableRowAction,
  DataTableViewMode,
  PartialDataTableColumn,
  PartialDataTableConfig,
  PartialDataTableGlobalAction,
  PartialDataTableRowAction
} from '../';
import { DataTableButtonRowAction, DataTableButtonGlobalAction, DefaultDataTableGridCard } from '../';
import { DataTableCell } from '../components/data-table-cells';
import DataTableTableViewMode from '../components/data-table-view-modes/DataTableTableViewMode.vue';
import DataTableGridViewMode from '../components/data-table-view-modes/DataTableGridViewMode.vue';

export function getDefaultDataTableViewModes(): DataTableViewMode[] {
  return [
    {
      name: 'table',
      label: 'dataTable.viewMode.table',
      icon: 'mdi-view-list',
      translateLabel: true,
      showSortSelect: false,
      component: DataTableTableViewMode,
      componentConfig: {}
    },
    {
      name: 'grid',
      label: 'dataTable.viewMode.grid',
      icon: 'mdi-view-grid',
      translateLabel: true,
      showSortSelect: true,
      component: DataTableGridViewMode,
      componentConfig: {
        cardComponent: markRaw(DefaultDataTableGridCard),
        grid: {
          fluid: true,
          cols: 12,
          sm: 4,
          md: 4,
          lg: 4,
          xl: 4,
          xxl: 4
        }
      }
    }
  ];
}

export function getDefaultViewMoodesWithoutSort(): DataTableViewMode[] {
  return [
    {
      name: 'table',
      label: 'dataTable.viewMode.table',
      icon: 'mdi-view-list',
      translateLabel: true,
      showSortSelect: false,
      component: DataTableTableViewMode,
      componentConfig: {}
    },
    {
      name: 'grid',
      label: 'dataTable.viewMode.grid',
      icon: 'mdi-view-grid',
      translateLabel: true,
      showSortSelect: false,
      component: DataTableGridViewMode,
      componentConfig: {
        cardComponent: markRaw(DefaultDataTableGridCard),
        grid: {
          fluid: true,
          cols: 12,
          sm: 4,
          md: 4,
          lg: 4,
          xl: 4,
          xxl: 4
        }
      }
    }
  ];
}

export function createDataTableConfig(config: PartialDataTableConfig): DataTableConfig {
  const result = {
    columns: [],
    actions: { global: [], row: [] },
    viewModes: [],
    showViewModes: false,
    needTableHeader: true,
    fixedHeader: false,
    fixedFooter: false,
    height: 'auto',
    hover: false,
    selectable: false,
    showIndexColumn: false,
    clickableItems: false,
    mobileViewMode: 'grid',
    mobileBreakpoint: 1000,
    ...config
  };

  if (!result.viewModes.length) {
    result.viewModes = getDefaultDataTableViewModes();
  }

  result.viewModes.forEach((mode) => (mode.component = markRaw(mode.component)));
  return result;
}

export function createDataTableColumn(config: PartialDataTableColumn): DataTableColumn {
  return {
    sortable: false,
    visible: true,
    translateLabel: false,
    cellConfig: createDataTableCellConfig(config.cellConfig || {}),
    ...config,
    label: config.label !== undefined && config.label !== null ? config.label : config.name,
    cellComponent: config.cellComponent ? markRaw(config.cellComponent) : markRaw(DataTableCell)
  };
}

export function createDataTableCellConfig(config: any): CellConfig {
  return {
    emptyValue: '-',
    prefix: '',
    dateFormat: 'yyyy.MM.dd',
    ...config
  };
}

export function createDataTableItem(data: any, config: DataTableConfig): DataTableItem {
  return {
    data,
    selected: false,
    disabled: !!config.disabledRowCondition && config.disabledRowCondition(data)
  };
}

export function createDataTableRowAction(config: PartialDataTableRowAction): DataTableRowAction {
  return {
    actionConfig: null,
    ...config,
    actionComponent: config.actionComponent ? markRaw(config.actionComponent) : markRaw(DataTableButtonRowAction)
  };
}

export function createDataTableGlobalAction(config: PartialDataTableGlobalAction): DataTableGlobalAction {
  return {
    position: 'top',
    actionConfig: null,
    selectionOnly: false,
    ...config,
    actionComponent: config.actionComponent ? markRaw(config.actionComponent) : markRaw(DataTableButtonGlobalAction)
  };
}

export function createDataTableRowDetails(config: PartialDataTableConfig) {
  return {
    component: config.rowDetailsComponent ? markRaw(config.rowDetailsComponent) : undefined,
    config: config.rowDetailsComponentConfig
  };
}
