import { ApiModule, type PaginatedResponseData } from '@common/index';
import type { TeacherItem, Teacher } from '../models';

export class TeacherApi extends ApiModule {
  protected endpoint = '/teacher';
  protected instituteEndpoint = '/institute';
  protected positionEndpoint = '/position';

  public async pageTeachers(params: any) {
    return this.api.post<PaginatedResponseData<TeacherItem>, any>(
      `${this.endpoint}/page?page=${params.page}&size=${params.itemsPerPage}&sort=${params.sort ?? ''}`,
      {
        ...params
      }
    );
  }

  public loadTeacherDetails(id: number) {
    return this.api.get<Teacher>(`${this.endpoint}?id=${id}`);
  }

  public saveTeacher(params: any) {
    return this.api.post(`${this.endpoint}`, {
      ...params
    });
  }

  public deleteTeacher(id: number) {
    return this.api.delete(`${this.endpoint}?id=${id}`);
  }

  public getInstitutes() {
    return this.api.get<any[]>(`${this.instituteEndpoint}/list`);
  }

  public getPositions() {
    return this.api.get<any[]>(`${this.positionEndpoint}/list`);
  }

  public validateNeptunCode(params: any) {
    return this.api.get<boolean>(`${this.endpoint}/validate-neptun-code`, { params });
  }

  public exportTeacherData() {
    return this.api.get<Blob>(`${this.endpoint}/export/xls`, {
      headers: {
        Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      },
      responseType: 'blob'
    });
  }
}
