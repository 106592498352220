<script lang="ts" setup>
import type { PropType } from 'vue';
import type { FormElement } from '../models';
import { useFormElement } from '../composables';
import { CtsInfoBlock } from './';
import { useModals } from '../../modal';

const props = defineProps({
  element: {
    type: Object as PropType<FormElement>,
    required: true
  },
  fieldAnimation: {
    type: [String, undefined] as PropType<string | undefined>
  },
  removeEnabled: {
    type: Boolean,
    default: false
  },
  removeAttributes: {
    type: Object,
    default: () => {}
  },
  removeNeedTranslate: {
    type: Boolean,
    default: false
  },
  removeNeedConfirm: {
    type: Boolean,
    default: false
  },
  removeConfirmTitle: {
    type: String,
    default: ''
  },
  removeConfirmText: {
    type: String,
    default: ''
  }
});

const { component, componentSettings, visible, infoBlock, density, removeFromParent, removable, label, fullName, labelComponent, attributes } =
  useFormElement(props.element);

const modals = useModals();

const remove = () => {
  if (props.removeNeedConfirm) {
    modals.openConfirm({
      title: props.removeConfirmTitle,
      text: props.removeConfirmText,
      translate: props.removeNeedTranslate,
      onConfirm: () => removeFromParent()
    });
  } else {
    removeFromParent();
  }
};
</script>

<template>
  <v-expansion-panel
    v-if="visible"
    v-bind="attributes"
    :value="fullName">
    <template #title>
      <template v-if="labelComponent">
        <component
          :is="labelComponent"
          :label="label"
          :props="{}"
          :element="element" />
      </template>
      <template v-else>
        {{ label || '-' }}
      </template>
    </template>
    <template #text>
      <div
        v-if="removeEnabled && removable"
        class="cts-field__actions">
        <v-btn
          v-bind="removeAttributes"
          @click.prevent="remove" />
      </div>
      <component
        :is="component"
        :componentSettings="componentSettings"
        :element="element" />
      <cts-info-block
        v-if="infoBlock"
        :content="infoBlock"
        :density="density" />
    </template>
  </v-expansion-panel>
</template>
