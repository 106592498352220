<script setup lang="ts">
import { type PropType } from 'vue';
import { FormElement, useFormElement } from '@common/lib/modules/form';
import { useSubjectPrerequisiteEntreatyDataTable } from '../composables';
import { DataTable } from '@common/lib/modules/datatable';

const props = defineProps({
  element: {
    type: Object as PropType<FormElement>,
    required: true
  }
});

const { label, labelComponent, appendComponent, prependComponent, attributes } = useFormElement(props.element);
const { tableConfig, data, onRowAction, onGlobalAction } = useSubjectPrerequisiteEntreatyDataTable(props.element);
</script>

<template>
  <v-input v-bind="attributes">
    <template
      v-if="$slots.prepend || prependComponent"
      #prepend>
      <slot name="prepend">
        <component
          :is="prependComponent"
          :element="element" />
      </slot>
    </template>
    <template
      v-if="$slots.append || appendComponent"
      #append>
      <slot name="append">
        <component
          :is="appendComponent"
          :element="element" />
      </slot>
    </template>
    <template #default>
      <div class="w-100">
        <label class="v-label">
          <template v-if="labelComponent">
            <component
              :is="labelComponent"
              :label="label"
              :props="{}"
              :element="element" />
          </template>
          <template v-else>
            {{ label }}
          </template>
        </label>
        <div class="scrollable-table scrollable-table--entreated">
          <data-table
            :config="tableConfig"
            :data="data"
            @global-action="onGlobalAction"
            @row-action="onRowAction" />
        </div>
      </div>
    </template>
  </v-input>
</template>
