import { ApiModule, type PaginatedResponseData } from '@common/index';
import type { TopicHasClinicalSkillItem } from '../models';
import type { SpecialityGroupType } from '../../../../master-data/speciality/models';

export class TopicHasClinicalSkillApi extends ApiModule {
  protected endpoint = '/topic-has-clinical-skill';

  public async pageTopicHasClinicalSkills(params: any) {
    return this.api.post<PaginatedResponseData<TopicHasClinicalSkillItem>, any>(
      `${this.endpoint}/page?page=${params.page}&size=${params.itemsPerPage}&sort=${params.sort ?? ''}`,
      {
        ...params
      }
    );
  }

  public saveTopicHasClinicalSkill(params: any, topicId: number) {
    return this.api.post(`${this.endpoint}/create-relations`, {
      ...params,
      topicId: topicId
    });
  }

  public deleteTopicHasClinicalSkill(id: number) {
    return this.api.delete(`${this.endpoint}?id=${id}`);
  }

  public getMasterData(topicId: number, specialityGroupType: SpecialityGroupType) {
    return this.api.get<any>(`${this.endpoint}/master-data`, { params: { topicId, specialityGroupType } });
  }

  public deleteSelected(selectedIds: number[], topicId: number) {
    return this.api.post(`${this.endpoint}/delete-relations?topicId=${topicId}`, { ids: selectedIds });
  }
}
