<script lang="ts" setup>
import type { PropType } from 'vue';
import { useAutoCompleteFormElement } from '../composables';
import { SelectableFormElement } from '../models';
import { CtsFieldMessage } from './';

const props = defineProps({
  element: {
    type: Object as PropType<SelectableFormElement>,
    required: true
  },
  componentSettings: {
    type: Object as PropType<any>,
    required: false
  }
});

const {
  attributes,
  enabled,
  loading,
  readonly,
  value,
  fullName,
  messagesAsString,
  hasErrorMessages,
  hasWarningMessages,
  hasInfoMessages,
  onInput,
  onFocus,
  onBlur,
  labelComponent,
  prependComponent,
  prependInnerComponent,
  appendComponent,
  appendInnerComponent,
  appendOuterComponent,
  items,
  itemComponent,
  itemAppendComponent,
  itemPrependComponent,
  label,
  suffix,
  hint,
  noDataText,
  color,
  bgColor,
  density,
  variant,
  searchValue
} = useAutoCompleteFormElement(props.element);
</script>

<template>
  <div class="d-flex gap-1">
    <v-autocomplete
      v-bind="attributes"
      v-model:modelValue="value"
      v-model:search="searchValue"
      :items="items"
      :name="fullName"
      :label="label"
      :suffix="suffix"
      :hint="hint"
      :disabled="!enabled"
      :no-data-text="noDataText"
      :readonly="readonly"
      :loading="loading"
      :color="color"
      :bg-color="bgColor"
      :variant="variant"
      :density="density"
      :clearable="attributes.clearable === true && enabled && !readonly"
      :closable-chips="attributes.closableChips === true && enabled && !readonly"
      :class="{
        'v-input--error': hasErrorMessages,
        'v-input--warning': hasWarningMessages,
        'v-input--info': hasInfoMessages
      }"
      :messages="messagesAsString"
      @focus="onFocus"
      @blur="onBlur"
      @input="onInput"
      @change="onInput"
      @update:model-value="onInput">
      <template #message>
        <cts-field-message :element="element" />
      </template>
      <template
        v-if="prependComponent"
        #prepend>
        <component
          :is="prependComponent"
          :element="element" />
      </template>
      <template
        v-if="prependInnerComponent"
        #prepend-inner>
        <component
          :is="prependInnerComponent"
          :element="element" />
      </template>
      <template
        v-if="appendComponent"
        #append>
        <component
          :is="appendComponent"
          :element="element" />
      </template>
      <template
        v-if="appendInnerComponent"
        #append-inner>
        <component
          :is="appendInnerComponent"
          :element="element" />
      </template>
      <template
        v-if="itemComponent"
        #item="{ item, index, props }">
        <component
          :is="itemComponent"
          :element="element"
          :item="item"
          :item-index="index"
          :props="props" />
      </template>
      <template
        v-if="itemPrependComponent"
        #prepend-item>
        <component
          :is="itemPrependComponent"
          :element="element"
          :item="null"
          :item-index="0" />
      </template>
      <template
        v-if="itemAppendComponent"
        #append-item>
        <component
          :is="itemAppendComponent"
          :element="element"
          :item="null"
          :item-index="0" />
      </template>
      <template
        v-if="labelComponent"
        #label="{ label, props }">
        <component
          :is="labelComponent"
          :label="label"
          :props="props"
          :element="element" />
      </template>
    </v-autocomplete>
    <template v-if="appendOuterComponent">
      <component
        :is="appendOuterComponent"
        :element="element" />
    </template>
  </div>
</template>
