import { ApiModule, EditableStatus, type PaginatedResponseData } from '@common/index';
import type { TopicItem, Topic, TopicHierarchyDto } from '../models';
import type { TopicHasOutcome } from '../relations/topic-has-outcome/models';
import type { TopicSummaryItem } from '../models/topic-summary.model';

export class TopicApi extends ApiModule {
  protected endpoint = '/topic';
  protected academicYearEndpoint = '/academic-year';
  protected topicHasOutComeEndpoint = '/topic-has-outcome';

  public async pageTopics(params: any) {
    return this.api.post<PaginatedResponseData<TopicItem>, any>(
      `${this.endpoint}/page?page=${params.page}&size=${params.itemsPerPage}&sort=${params.sort ?? ''}`,
      {
        ...params
      }
    );
  }

  public loadTopicDetails(id: number) {
    return this.api.get<Topic>(`${this.endpoint}?id=${id}`);
  }

  public loadTopicSummary(id: number) {
    return this.api.get<TopicSummaryItem>(`${this.endpoint}/get-summary?topicId=${id}`);
  }

  public saveTopic(params: any, isCopy = false, oldTopicId?: number) {
    const saveEndpoint = isCopy ? `${this.endpoint}/copy?oldTopicId=${oldTopicId}` : `${this.endpoint}`;
    return this.api.post<any, Topic>(`${saveEndpoint}`, {
      ...params
    });
  }

  public finalizeTopic(params: any) {
    return this.api.post<any, Topic>(`${this.endpoint}/finalize`, {
      ...params
    });
  }

  public setTopicStatus(topicId: number, status: EditableStatus) {
    return this.api.post<void, any>(`${this.endpoint}/set-status`, {}, { params: { topicId, status } });
  }

  public deleteTopic(id: number) {
    return this.api.delete(`${this.endpoint}?id=${id}`);
  }

  public getTopicCategories() {
    return this.api.get<any[]>(`${this.endpoint}-category/list`);
  }

  public loadTopicHasOutcome(topicId: number) {
    return this.api.get<TopicHasOutcome>(`${this.topicHasOutComeEndpoint}/by-topic-id?topicId=${topicId}`);
  }

  public saveTopicHasOutcome(params: any) {
    return this.api.post<any, TopicHasOutcome>(`${this.topicHasOutComeEndpoint}`, {
      ...params
    });
  }

  public setMultipleStatus(ids: number[], selectedStatus: EditableStatus): Promise<any> {
    return this.api.post<any, any>(`${this.endpoint}/set-multiple-status?status=${selectedStatus}`, {
      ids
    });
  }

  public pageOwnTopics(params: any) {
    return this.api.post<PaginatedResponseData<TopicItem>, any>(
      `${this.endpoint}/own/page?page=${params.page}&size=${params.itemsPerPage}&sort=${params.sort ?? ''}`,
      {
        ...params
      }
    );
  }

  public loadOwnTopicDetails(id: number) {
    return this.api.get<Topic>(`${this.endpoint}/own?id=${id}`);
  }

  public saveOwnTopic(params: any) {
    return this.api.post<any, Topic>(`${this.endpoint}/own`, {
      ...params
    });
  }

  public finalizeOwnTopic(params: any) {
    return this.api.post<any, Topic>(`${this.endpoint}/own/finalize`, {
      ...params
    });
  }

  public finalizeOwnTopicById(topicId: number) {
    return this.api.post<any, Topic>(`${this.endpoint}/own/finalize-by-id?topicId=${topicId}`);
  }

  public loadTopicHierarchy(id: number) {
    return this.api.get<TopicHierarchyDto[]>(`${this.endpoint}/hierarchy?topicId=${id}`);
  }

  public loadOwnTopicSummary(id: number) {
    return this.api.get<TopicSummaryItem>(`${this.endpoint}/own/get-summary?topicId=${id}`);
  }
}
