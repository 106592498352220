import { toRaw } from 'vue';
import { useApi } from '../../../services';
import { FormValidator, isEmpty, type FormValidatorConfig, type FormValidatorContext, type FormValidatorSettings } from '@common/index';

export type SampleUnitLangValidatorSettings = FormValidatorSettings;
export type SampleUnitLangValidatorConfig = FormValidatorConfig<SampleUnitLangValidatorSettings>;

export class SampleUnitLangValidator extends FormValidator<SampleUnitLangValidatorSettings> {
  sampleUnitApi = useApi().sampleUnit;

  constructor() {
    super();
    this._name = 'sampleUnitLangValidator';
    this._settings = {
      invalidMessage: 'sampleUnit.validation.sampleUnitWithLangAlreadyExists',
      needTranslate: true
    };
  }

  public override async onValidate(value: any, context: FormValidatorContext) {
    const formValues = toRaw(context.element.parent?.value);

    if (isEmpty(value) || !formValues.topicId || !context.element.enabled) {
      return;
    }

    const sampleUnitId = formValues.id;

    const response = await this.sampleUnitApi.validateSampleUnitLanguage({ sampleUnitId, language: value, topicId: formValues.topicId });
    const alreadyExsits = response.data;
    if (alreadyExsits) {
      this.addMessage(this.settings.invalidMessage);
      this._valid = false;
    } else {
      this._valid = true;
    }
  }
}
