export default {
  app: {
    title: 'PTE AOK Tárgybejelentő',
    privacyPolicy: 'Adatvédelmi tájékoztató'
  },
  pages: {
    home: 'Főoldal',
    userManagement: 'Felhasználók',
    teacherList: 'Oktatók',
    topicList: 'Témák',
    topicData: 'Témák',
    subjectList: 'Tantárgyak',
    subjectData: 'Tantárgyak',
    subjectPrerequisite: 'Előfeltételek',
    subjectPrerequisiteFor: 'Mihez előfeltétel',
    subjectHasSampleCurriculum: 'Mintatantervek',
    subjectCreate: 'Tantárgy létrehozás',
    subjectCopy: 'Tantárgy másolás',
    sampleUnitList: 'Mintaegységek',
    sampleUnitData: 'Mintaegységek',
    sampleUnitCreate: 'Mintaegység létrehozás',
    sampleUnitCopy: 'Mintaegység másolás',
    sampleUnitSubjectList: 'Tantárgyak',
    sampleUnitHasLesson: 'Foglalkozások',
    sampleUnitHasCourseTeacher: 'Szemináriumok, gyakorlatok oktatói',
    sampleUnitHasExaminer: 'Vizsgáztatók',
    sampleUnitHasFaculty: 'Campus karok',
    sampleUnitSubject: 'Tantárgyak',
    sampleUnitSubjectPrerequisite: 'Előfeltételek',
    sampleUnitSubjectPrerequisiteFor: 'Mihez előfeltétel',
    sampleUnitSubjectHasSampleCurriculum: 'Mintatantervek',
    sampleUnitSubjectCreate: 'Tantárgy létrehozás',
    sampleUnitSubjectCopy: 'Tantárgy másolás',
    facultyList: 'Karok',
    instituteList: 'Intézetek',
    specialityList: 'Szakok',
    moduleList: 'Modulok',
    academicYearList: 'Akadémiai évek',
    examTypeList: 'Jegykialakítás',
    absenceRatioList: 'Hiányzási arányok',
    positionList: 'Beosztások',
    clinicalSkillList: 'Klinikai készségek',
    educationalRequirementList: 'Képzési és kimeneti követelmények',
    topicCreate: 'Téma létrehozás',
    topicCopy: 'Téma másolás',
    topicEdit: 'Alapadatok',
    topicSampleUnit: 'Mintaegységek',
    topicSampleUnitList: 'Mintaegységek',
    topicSampleUnitCreate: 'Mintaegység létrehozás',
    topicSampleUnitCopy: 'Mintaegység másolás',
    topicSpeciality: 'Szakok',
    topicSpecClinicalSkill: 'Klinikai készségek',
    topicSpecEducationalRequirement: 'Képzési és kimeneti követelmények',
    topicHasOutcome: 'TEA',
    topicSampleUnitSubjectList: 'Tantárgyak',
    topicSampleUnitHasLesson: 'Foglalkozások',
    topicSampleUnitHasExaminer: 'Vizsgáztatók',
    topicSampleUnitHasCourseTeacher: 'Szemináriumok, gyakorlatok oktatói',
    topicSampleUnitSubject: 'Tantárgyak',
    topicSampleUnitSubjectCreate: 'Tantárgy létrehozás',
    topicSampleUnitSubjectCopy: 'Tantárgy másolás',
    topicSampleUnitEntreaty: 'Mintaegység kérvény',
    topicSubjectEntreaty: 'Tantárgy kérvény',
    topicCategoryList: 'PotePath',
    topicEntreaty: 'Kérvény',
    topicSummary: 'Téma összegző',
    sampleUnitEntreaty: 'Mintaegység kérvény',
    subjectEntreaty: 'Tantárgy kérvény',
    topicSampleUnitSubjectPrerequisite: 'Előfeltételek',
    topicSampleUnitSubjectHasSampleCurriculum: 'Mintatantervek',
    methodologicalElementList: 'Módszertani elemek',
    campusFacultyList: 'Campus karok',
    ownTopicData: 'Témáim',
    ownTopicList: 'Témáim',
    ownTopicHasOutcome: 'TEA',
    ownTopicSampleUnit: 'Mintaegységek',
    ownTopicSampleUnitCreate: 'Mintaegység létrehozás',
    ownTopicSampleUnitList: 'Mintaegységek',
    ownTopicSpecClinicalSkill: 'Klinikai készségek',
    ownTopicSpecEducationalRequirement: 'Képzési és kimeneti követelmények',
    ownTopicSampleUnitSubjectList: 'Tantárgyak',
    ownTopicSampleUnitHasLesson: 'Foglalkozások',
    ownTopicSampleUnitHasExaminer: 'Vizsgáztatók',
    ownTopicSampleUnitSubject: 'Tantárgyak',
    ownTopicSampleUnitSubjectPrerequisite: 'Előfeltételek',
    ownTopicSampleUnitSubjectCopy: 'Tantágy másolás',
    ownTopicSampleUnitSubjectCreate: 'Tantágy létrehozás',
    ownTopicEntreaty: 'Kérvény',
    ownTopicSampleUnitEntreaty: 'Mintaegység kérvény',
    ownTopicSubjectEntreaty: 'Tantárgy kérvény',
    exports: 'Adat exportok',
    ownSampleUnitList: 'Mintaegységeim',
    ownSampleUnitData: 'Mintaegységeim',
    ownSampleUnitCreate: 'Mintaegység létrehozás',
    ownSampleUnitSubjectList: 'Tantárgyak',
    ownSampleUnitHasLesson: 'Foglalkozások',
    ownSampleUnitHasExaminer: 'Vizsgáztatók',
    ownSampleUnitSubject: 'Tantárgyak',
    ownSampleUnitSubjectCreate: 'Tantárgy létrehozás',
    ownSampleUnitSubjectPrerequisite: 'Előfeltételek',
    ownSampleUnitSubjectCopy: 'Tantárgy másolás',
    ownTopicSampleUnitCopy: 'Mintaegység másolás',
    ownTopicSummary: 'Téma összegző',
    ownSampleUnitCopy: 'Mintaegység másolás',
    ownSampleUnitEntreaty: 'Mintaegység kérvény',
    ownSubjectEntreaty: 'Tantárgy kérvény',
    ownTopicSampleUnitHasCourseTeacher: 'Szemináriumok, gyakorlatok oktatói',
    ownSampleUnitHasCourseTeacher: 'Szemináriumok, gyakorlatok oktatói',
    entreatyList: 'Kérvények',
    ownEntreatyList: 'Kérvényeim',
    instituteRevisionList: 'Intézet történet',
    topicRevisionList: 'Téma történet',
    sampleUnitRevisionList: 'Mintaegység történet',
    subjectRevisionList: 'Tantárgy történet',
    accessDenied: 'Hozzáférés megtagadva',
    privacyPolicy: 'Adatvédelmi tájékoztató',
    information: 'Információ',
    sampleCurriculumList: 'Mintatantervek',
    archiveTopicList: 'Archivált adatok',
    archiveList: 'Archivált adatok'
  },
  menu: {
    logout: 'Kijelentkezés',
    personalData: 'Személyes adatok',
    dashboard: 'Főoldal',
    userManagement: 'Felhasználók',
    subject: 'Tárgyak',
    sampleUnit: 'Mintaegység',
    ownSampleUnit: 'Mintaegységeim',
    academicYear: 'Akadémiai év',
    examType: 'Jegykialakítás',
    absenceRatio: 'Hiányzási arányok',
    clinicalSkill: 'Klinikai készségek',
    educationalRequirement: 'Képzési és kimeneti követelmény',
    faculty: 'Kar',
    institute: 'Intézet',
    position: 'Beosztás',
    speciality: 'Szak',
    teacher: 'Oktató',
    topic: 'Téma',
    ownTopic: 'Témáim',
    module: 'Modul',
    masterData: 'Törzsadatok',
    revision: 'Történet',
    topicRevision: 'Téma történet',
    sampleUnitRevision: 'Mintaegység történet',
    subjectRevision: 'Tantárgy történet',
    instituteRevision: 'Intézet történet',
    topicCategory: 'PotePath',
    methodologicalElement: 'Módszertani elemek',
    campusFaculty: 'Campus karok',
    entreaties: 'Kérvények',
    exports: 'Adat exportok',
    ownEntreaties: 'Kérvényeim',
    information: 'Információ',
    sampleCurriculum: 'Mintatanterv',
    academicYearChooser: 'Akadémiai évválasztó',
    year: 'Év',
    unEditable: 'Nem módosítható!',
    archive: 'Archivált adatok'
  },
  dashboard: {
    greeting: 'Üdvözöljük'
  },

  listModal: {
    title: 'Oktatók'
  },
  user: {
    validation: {
      neptunCodeAlreadyExists: 'A neptun kód már használatban van'
    },
    filter: {
      enabled: {
        label: 'Engedélyezett',
        true: 'Igen',
        false: 'Nem'
      },
      type: {
        label: 'Típus',
        standard: 'Standard',
        guest: 'Vendég'
      },
      role: {
        admin: 'Admin'
      }
    },
    role: {
      admin: 'Admin',
      teacher: 'Oktató'
    },
    label: {
      name: 'Név',
      username: 'Felhasználónév',
      email: 'Email',
      role: 'Jogosultsági szint',
      enabled: 'Engedélyezett',
      userType: 'Típus',
      neptunCode: 'Neptun kód'
    },
    details: {
      name: 'Név',
      username: 'Felhasználónév',
      email: 'Email',
      role: 'Jogosultsági szint',
      enabled: 'Engedélyezett',
      type: 'Típus',
      title: 'Felhasználó szerkesztése',
      newTitle: 'Felhasználó létrehozása'
    },
    type: {
      GUEST: 'Vendég',
      STANDARD: 'PTE felhasználó'
    },
    enabled: {
      true: 'Igen',
      false: 'Nem'
    },
    dialog: {
      delete: {
        title: 'Felhasználó törlése',
        text: 'Biztosan törli a felhasználót?'
      }
    },
    notification: {
      userSuccess: 'Felhasználó mentése sikeres!',
      userError: 'Hiba a felhasználó mentése közben!',
      deleteSuccess: 'Felhasználó törlése sikeres!',
      deleteError: 'Hiba a felhasználó törlése közben!',
      activationUserSuccess: 'Felhasználó aktiválása sikeres!',
      activationUserError: 'Hiba a felhasználó aktiválása közben!',
      inactivationUserSuccess: 'Felhasználó inaktiválása sikeres!',
      inactivationUserError: 'Hiba a felhasználó inaktiválása közben!',
      email: {
        activationUserSuccess: 'Aktiváló email sikeresen elküldve!',
        activationUserError: 'Hiba az aktiváló email elküldése közben!'
      }
    },
    importer: {
      title: 'Felhasználók importálása'
    }
  },
  academicYear: {
    label: {
      code: 'Kód',
      status: 'Státusz',
      editingPeriodEnd: 'Szerkesztési időszak vége'
    },
    details: {
      title: 'Akadémiai év szerkesztése'
    },
    dialog: {
      statusChange: {
        title: 'Akadémiai év státusz módosítása',
        text: 'Biztosan módosítja az akadémiai év státuszát?'
      },
      delete: {
        title: 'Akadémiai év törlése',
        text: 'Biztosan törli az akadémiai évet?'
      },
      copy: {
        title: 'Akadémiai év másolása',
        text: 'Biztosan másolja az akadémiai évet?'
      }
    },
    button: {
      editPeriod: 'Időszak szerkesztése',
      startEditingPeriod: 'Szerkesztési időszakra vált',
      startAdministrativePeriod: 'Adminisztrációs időszakra vált',
      activate: 'Időszak aktiválása',
      createUpcoming: 'Következő év generálása',
      archive: 'Archiválás',
      changeToUpcoming: 'Visszaállítás következő státuszra'
    },
    notification: {
      academicYearSuccess: 'Akadémiai év adatok mentése sikeres!',
      academicYearError: 'Hiba az akadémiai év mentése közben!',
      deleteSuccess: 'Akadémiai év adatok törlése sikeres!',
      deleteError: 'Hiba az akadémiai év adatok törlése közben!',
      statusChangeSuccess: 'Akadémiai év státusz módosítása sikeres!',
      statusChangeError: 'Hiba az akadémiai év státusz módosítása közben!',
      copySuccess: 'Akadémiai év másolása sikeres!',
      copyError: 'Hiba az akadémiai év másolása közben!'
    },
    status: {
      UPCOMING: 'Következő',
      EDITING_PERIOD: 'Szerkesztési időszak',
      ADMINISTRATIVE_PERIOD: 'Adminisztrációs időszak',
      ACTIVE: 'Aktív',
      ARCHIVED: 'Archivált',
      PROCESSING: 'Feldolgozás alatt'
    },
    validation: {
      editingPeriodIsWithinWeek: 'A kiválasztott dátum egy héten belül van!'
    }
  },
  topicCategory: {
    label: {
      name: 'Név',
      active: 'Aktív'
    },
    details: {
      title: 'PotePath módosítása'
    },
    new: {
      title: 'Új PotePath'
    },
    filter: {
      keyword: 'Keresés'
    },
    notification: {
      topicCategorySuccess: 'PotePath adatok mentése sikeres!',
      topicCategoryError: 'Hiba a PotePath adatok mentése közben!',
      deleteSuccess: 'PotePath törlése sikeres!',
      deleteError: 'Hiba a PotePath törlése közben!'
    },
    dialog: {
      delete: {
        title: 'PotePath adatok törlése',
        text: 'Biztosan törli a PotePath mezőt?'
      }
    }
  },

  campusFaculty: {
    label: {
      code: 'Kód',
      active: 'Aktív'
    },
    details: {
      title: 'Campus kar módosítása'
    },
    new: {
      title: 'Új campus kar'
    },
    filter: {
      keyword: 'Keresés'
    },
    notification: {
      campusFacultySuccess: 'Campus kar adatok mentése sikeres!',
      campusFacultyError: 'Hiba a campus kar adatok mentése közben!',
      deleteSuccess: 'Campus kar törlése sikeres!',
      deleteError: 'Hiba a campus kar törlése közben!'
    },
    dialog: {
      delete: {
        title: 'Campus kar adatok törlése',
        text: 'Biztosan törli a campus kar mezőt?'
      }
    },
    validation: {
      codeAlreadyExists: 'A campus kar kód már létezik!'
    }
  },

  methodologicalElement: {
    label: {
      name: 'Név',
      active: 'Aktív'
    },
    details: {
      title: 'Módszertani elem módosítása'
    },
    new: {
      title: 'Új módszertani elem'
    },
    filter: {
      keyword: 'Keresés'
    },
    notification: {
      methodologicalElementSuccess: 'Módszertani elem adatok mentése sikeres!',
      methodologicalElementError: 'Hiba a módszertani elem adatok mentése közben!',
      deleteSuccess: 'Módszertani elem törlése sikeres!',
      deleteError: 'Hiba a módszertani elem törlése közben!'
    },
    dialog: {
      delete: {
        title: 'Módszertani elem törlése',
        text: 'Biztosan törli a módszertani elemet?'
      }
    }
  },
  absenceRatio: {
    details: {
      title: 'Megengedett hiányzási arányok szerkesztése'
    },
    label: {
      percent: 'Megengedett hiányzás (százalék)',
      name: 'Név'
    },
    dialog: {
      delete: {
        title: 'Megengedett hiányzás adatok törlése',
        text: 'Biztosan törli a megengedett hiányzás mezőt?'
      }
    },
    notification: {
      absenceRatioSuccess: 'Hiányzás adatok mentése sikeres!',
      absenceRatioError: 'Hiba a hiányzás adatok mentése közben!',
      deleteSuccess: 'Hiányzás adatok törlése sikeres!',
      deleteError: 'Hiba a hiányzás adatok törlése közben!'
    }
  },
  examType: {
    label: {
      code: 'Kód',
      name: 'Név',
      active: 'Aktív'
    },
    details: {
      title: 'Jegykialakítás szerkesztése'
    },
    dialog: {
      delete: {
        title: 'Jegykialakítás törlése',
        text: 'Biztosan törli a jegykialakítást?'
      }
    },
    notification: {
      examTypeSuccess: 'Jegykialakítás mentése sikeres!',
      examTypeError: 'Hiba a jegykialakítás mentése közben!',
      deleteSuccess: 'Jegykialakítás törlése sikeres!',
      deleteError: 'Hiba a jegykialakítás törlése közben!'
    }
  },
  clinicalSkill: {
    label: {
      orderNumber: 'Sorszám',
      name: 'Név',
      level: 'Szint',
      code: 'Kód',
      active: 'Aktív'
    },
    details: {
      title: 'Klinikai készség szerkesztése'
    },
    dialog: {
      delete: {
        title: 'Klinikai készség törlése',
        text: 'Biztosan törli a Klinikai készséget?'
      }
    },
    notification: {
      clinicalSkillSuccess: 'Klinikai készség mentése sikeres!',
      clinicalSkillError: 'Hiba a Klinikai készség mentése közben!',
      examTypeSuccess: 'Klinikai készség mentése sikeres!',
      examTypeError: 'Hiba a Klinikai készség mentése közben!',
      deleteSuccess: 'Klinikai készség törlése sikeres!',
      deleteError: 'Hiba a Klinikai készség törlése közben!'
    }
  },
  educationalRequirement: {
    label: {
      groupData: 'Csoport',
      name: 'Név',
      orderNumber: 'Sorszám',
      active: 'Aktív',
      relatedSpecialities: 'Kapcsolódó szakok',
      specialities: 'Szakok',
      code: 'Kód'
    },
    active: {
      true: 'Aktív',
      false: 'Inaktív'
    },
    details: {
      title: 'Képzési és kimeneti követelmény szerkesztése'
    },
    dialog: {
      delete: {
        title: 'Képzési és kimeneti követelmény törlése',
        text: 'Biztosan törli a Képzési és kimeneti követelményt?'
      }
    },
    notification: {
      educationalRequirementSuccess: 'Képzési és kimeneti követelmény mentése sikeres!',
      educationalRequirementError: 'Hiba a Képzési és kimeneti követelmény mentése közben!',
      deleteSuccess: 'Képzési és kimeneti követelmény törlése sikeres!',
      deleteError: 'Hiba a Képzési és kimeneti követelmény törlése közben!'
    }
  },
  faculty: {
    label: {
      name: 'Név',
      code: 'Kód',
      acronym: 'Rövidítés'
    },
    details: {
      title: 'Kar szerkesztése'
    },
    dialog: {
      delete: {
        title: 'Kar törlése',
        text: 'Biztosan törli a Kart?'
      }
    },
    notification: {
      facultySuccess: 'Kar mentése sikeres!',
      facultyError: 'Hiba a Kar mentése közben!',
      deleteSuccess: 'Kar törlése sikeres!',
      deleteError: 'Hiba a Kar törlése közben!'
    }
  },
  institute: {
    label: {
      name: 'Név',
      code: 'Kód',
      neptunCode: 'Neptun kód',
      active: 'Aktív',
      facultySelector: 'Kar',
      webCode: 'Webkód',
      creationTime: 'Módosítás dátuma',
      createdBy: 'Módosító személye',
      auto: 'Módosulás típusa'
    },
    details: {
      title: 'Intézet szerkesztése'
    },
    dialog: {
      delete: {
        title: 'Intézet törlése',
        text: 'Biztosan törli az Intézetet?'
      }
    },
    notification: {
      instituteSuccess: 'Intézet mentése sikeres!',
      instituteError: 'Hiba az Intézet mentése közben!',
      deleteSuccess: 'Intézet törlése sikeres!',
      deleteError: 'Hiba az Intézet törlése közben!'
    }
  },
  position: {
    label: {
      name: 'Név',
      gender: 'Nem'
    },
    details: {
      title: 'Beosztás szerkesztése'
    },
    dialog: {
      delete: {
        title: 'Beosztás törlése',
        text: 'Biztosan törli a beosztást?'
      }
    },
    notification: {
      positionSuccess: 'Beosztás mentése sikeres!',
      positionError: 'Hiba a beosztás mentése közben!',
      deleteSuccess: 'Beosztás törlése sikeres!',
      deleteError: 'Hiba a beosztás törlése közben!'
    }
  },
  sampleCurriculum: {
    label: {
      code: 'Kód',
      specialityName: 'Szak',
      trainingFormat: 'Képzési forma',
      language: 'Nyelv',
      startYear: 'Kezdés éve',
      endYear: 'Utolsó aktív év'
    },
    details: {
      title: 'Mintatanterv szerkesztése',
      newTitle: 'Új mintatanterv létrehozása'
    },
    filter: {
      keyword: 'Keresés'
    },
    dialog: {
      delete: {
        title: 'Mintatanterv törlése',
        text: 'Biztosan törli a mintatantervet?'
      }
    },
    notification: {
      deleteSuccess: 'Mintatanterv törlése sikeres!',
      deleteError: 'Hiba a mintatanterv törlése közben!',
      sampleCurriculumError: 'Hiba a mintatanterv mentése közben!',
      sampleCurriculumSuccess: 'Mintatanterv mentése sikeres!'
    },
    validation: {
      startYear: {
        smallerThan: 'A kezdés éve nem lehet későbbi, mint az utolsó aktív év!'
      },
      endYear: {
        greaterThan: 'Az utolsó aktív év nem lehet korábbi, mint a kezdés éve!'
      }
    }
  },
  speciality: {
    label: {
      name: 'Név',
      code: 'Kód',
      active: 'Aktív',
      enabled: 'Elérhető',
      enabledLanguage: 'Kizárólagos nyelv',
      orderNumber: 'Sorszám',
      language: 'Nyelv',
      facultyAcronym: 'Kar',
      faculty: 'Kar',
      acronym: 'Rövidítés',
      needClinicalSkills: 'Klinikai készségek rögzíthetők',
      notNeedClinicalSkills: 'Klinikai készségek nem rögzíthetők',
      specialityGroupType: 'Szakcsoport típus',
      curriculumCode: 'Tantervi kód',
      trainingFormat: 'Képzési forma'
    },
    details: {
      title: 'Szak szerkesztése'
    },
    dialog: {
      delete: {
        title: 'Szak törlése',
        text: 'Biztosan törli a Szakot?'
      }
    },
    notification: {
      specialitySuccess: 'Szak mentése sikeres!',
      specialityError: 'Hiba a Szak mentése közben!',
      deleteSuccess: 'Szak törlése sikeres!',
      deleteError: 'Hiba a Szak törlése közben!'
    },
    trainingFormat: {
      UNDIVIDED: 'Osztatlan',
      BSC: 'Bsc',
      MSC: 'Msc'
    }
  },
  module: {
    label: {
      name: 'Név',
      code: 'Kód',
      active: 'Aktív',
      type: 'Típus',
      specialityCode: 'Szak kód',
      specialityName: 'Szak neve',
      curriculumStatus: 'Tantervi állapot',
      prevModuleCode: 'Előző modul kódja',
      prevModuleName: 'Előző modul neve',
      relatedSpecialities: 'Kapcsolódó szakok',
      speciality: 'Szak',
      prevModule: 'Előző modul'
    },
    details: {
      title: 'Modul szerkesztése'
    },
    dialog: {
      delete: {
        title: 'Modul törlése',
        text: 'Biztosan törli a Modult?'
      }
    },
    notification: {
      moduleSuccess: 'Modul mentése sikeres!',
      moduleError: 'Hiba a Modul mentése közben!',
      deleteSuccess: 'Modul törlése sikeres!',
      deleteError: 'Hiba a Modul törlése közben!',
      moduleRelatedSpecialitySuccess: 'Modul-Szak kapcsolat mentése sikeres!',
      moduleRelatedSpecialityError: 'Hiba a Modul-Szak kapcsolat szerkesztése közben!'
    },
    relation: {
      speciality: {
        moduleCode: 'Modul kód',
        specialityName: 'Szak',

        title: 'Modul-Szak kapcsolat szerkesztése',
        dialog: {
          delete: {
            title: 'Modul-Szak kapcsolat törlése',
            text: 'Biztosan törli a Modul-Szak kapcsolatot?'
          }
        },
        notification: {
          moduleHasSpecialitySuccess: 'Modul-Szak kapcsolat mentése sikeres!',
          moduleHasSpecialityError: 'Hiba a Modul-Szak kapcsolat mentése közben!',
          deleteSuccess: 'Modul-Szak kapcsolat törlése sikeres!',
          deleteError: 'Hiba a Modul-Szak kapcsolat törlése közben!'
        }
      }
    }
  },
  teacher: {
    label: {
      neptunCode: 'Neptun kód',
      degree: 'Fokozat',
      displayName: 'Név',
      instituteName: 'Intézet',
      active: 'Aktív',
      positionName: 'Beosztás',
      email: 'Email',
      gender: 'Nem',
      committeeMember: 'Bizottsági tag',
      phoneNumber: 'Telefonszám',
      contactEmail: 'Kapcsolati email cím',
      phdYear: 'PHD megszerzés éve',
      addressing: 'Megszólítás',
      comment: 'Megjegyzés',
      positionSelector: 'Beosztás',
      instituteSelector: 'Intézet',
      filterName: 'Keresési név'
    },
    details: {
      title: 'Oktató szerkesztése',
      newTitle: 'Oktató létrehozása'
    },
    dialog: {
      delete: {
        title: 'Oktató törlése',
        text: 'Biztosan törli a Oktatót?'
      }
    },
    notification: {
      teacherSuccess: 'Oktató mentése sikeres!',
      teacherError: 'Hiba az Oktató mentése közben!',
      deleteSuccess: 'Oktató törlése sikeres!',
      deleteError: 'Hiba az Oktató törlése közben!'
    },
    validation: {
      neptunCodeAlreadyExists: 'A neptun kód már használatban van'
    }
  },
  topic: {
    summary: {
      label: {
        topicSampleUnits: 'Téma mintaegységei',
        sampleUnitSubjects: 'Mintaegység tantárgyai'
      }
    },
    tabs: {
      base: 'Alapadatok',
      speciality: 'Szakok',
      clinicalSkill: 'Klinikai készségek',
      educationalRequirement: 'Képzési és kimeneti követelmények',
      outcome: 'TEA',
      sampleUnit: 'Mintaegységek',
      entreaty: 'Kérvény'
    },
    label: {
      code: 'Kód',
      versionCode: 'Verzió',
      finalized: 'Véglegesítve',
      academicYearCode: 'Tanév',
      ownerNeptunCode: 'Felelős',
      medicalEligibility: 'Orvosi alkalmassági szükséges',
      lectureHours: 'Előadás',
      practiceHours: 'Gyakorlat',
      seminarHours: 'Szeminárium',
      sumHours: 'Összesen',
      name: 'Név',
      outcomeDescription: 'Kimeneti követelmény',
      parentTopics: 'Szülő témák',
      categories: 'PotePath',
      requestMessage: 'Kérvény indoklása',
      responseMessage: 'Válasz',
      base: 'Alapadatok',
      entreaty: 'Kérvény',
      opened: 'Nyitott',
      closed: 'Lezárt',
      curriculumStatus: 'Tantervi állapot',
      editors: 'Szerkesztők',
      status: 'Szerkesztési státusz',
      owner: 'Témafelelős',
      specialities: 'Szakok',
      title: 'Cím',
      academicYear: 'Akadémiai év',
      creationTime: 'Módosítás dátuma',
      createdBy: 'Módosító személye',
      auto: 'Módosulás típusa'
    },
    hint: {
      topicCategory: 'Csak egyszer menthető adat! Módosítás igény esetén keresse a Tanulmányi Hivatalt.'
    },
    dialog: {
      delete: {
        title: 'Téma törlése',
        text: 'Biztosan törli a Témát?'
      },
      statusChange: {
        title: 'Téma státusz módosítása',
        topicsSelected: '{count} téma kiválasztva'
      },
      finalize: {
        title: 'Téma véglegesítése',
        text: 'Biztosan véglegesíti a Témát? A véglegesítés után a téma és a témához kapcsolódó adatok nem szerkeszthető tovább.'
      },
      missingData: {
        title: 'Hiányzó téma adatok'
      }
    },
    notification: {
      topicSuccess: 'Téma mentése sikeres!',
      topicError: 'Hiba a Téma mentése közben!',
      topicEntreatySuccess: 'Téma kérvény mentése sikeres!',
      topicEntreatyError: 'Hiba a Téma kérvény mentése közben!',
      deleteSuccess: 'Téma törlése sikeres!',
      deleteError: 'Hiba a Téma törlése közben!',
      topicFinalizeSuccess: 'Téma véglegesítése sikeres!',
      topicFinalizeError: 'Hiba a Téma véglegesítése közben!',
      topicNotFinalizedSuccess: 'Téma nem véglegesítettre állítása sikeres!',
      topicNotFinalizedError: 'Hiba a Téma nem véglegesítettre állítása közben!',
      statusChangeSuccess: 'Téma státusz módosítása sikeres!',
      statusChangeError: 'Hiba a téma státusz módosítása közben!'
    },
    relation: {
      speciality: {
        topicName: 'Téma',
        specialityName: 'Szak',
        needClinicalSkills: 'Klinikai készségek rögzíthetők',
        details: {
          title: 'Szak kapcsolat szerkesztése'
        },
        dialog: {
          delete: {
            title: 'Szak kapcsolat törlése',
            text: 'Biztosan törli a Szak kapcsolatot? A kapcsolat törlésével törlődnek a téma-szak kapcsolathoz kapcsolt KKK és KKL elemek is.'
          }
        },
        notification: {
          topicHasSpecialitySuccess: 'Szak kapcsolat mentése sikeres!',
          topicHasSpecialityError: 'Hiba a Szak kapcsolat mentése közben!',
          deleteSuccess: 'Szak kapcsolat törlése sikeres!',
          deleteError: 'Hiba a Szak kapcsolat törlése közben!'
        }
      },
      clinicalSkill: {
        orderNumber: 'Sorszám',
        specialityGroupType: 'Szakcsoport típusa',
        topicName: 'Téma',
        specialityName: 'Szak',
        clinicalSkillName: 'Készség',
        details: {
          title: 'Készség kapcsolat szerkesztése'
        },
        dialog: {
          delete: {
            title: 'Készség kapcsolat törlése',
            text: 'Biztosan törli a készség kapcsolatot?'
          },
          deleteSelected: {
            text: 'Biztosan törli a kijelölt készségeket? <br> <strong><ul><li>{selected}</li></ul></strong>'
          }
        },
        notification: {
          topicHasClinicalSkillSuccess: 'Készség kapcsolat mentése sikeres!',
          topicHasClinicalSkillError: 'Hiba a Készség kapcsolat mentése közben!',
          deleteSuccess: 'Készség kapcsolat törlése sikeres!',
          deleteError: 'Hiba a Készség kapcsolat törlése közben!',
          deleteSelectedSuccess: 'Kiválasztott készségek törlése sikeres!',
          deleteSelectedError: 'Hiba a kiválasztott készségek törlése közben!'
        }
      },
      educationalRequirement: {
        orderNumber: 'Sorszám',
        topicName: 'Téma',
        specialityGroupType: 'Szakcsoport típusa',
        specialityName: 'Szak',
        educationalRequirementName: 'Követelmény',
        details: {
          title: 'Követelmény kapcsolat szerkesztése'
        },
        dialog: {
          delete: {
            title: 'Követelmény kapcsolat törlése',
            text: 'Biztosan törli a Követelmény kapcsolatot?'
          },
          deleteSelected: {
            title: 'Követelmény kapcsolat törlése',
            text: 'Biztosan törli a kijelölt Képzési és kimeneti követelményeket? <br> <strong><ul><li>{selected}</li></ul></strong>'
          }
        },
        notification: {
          topicHasEducationalRequirementSuccess: 'Követelmény kapcsolat mentése sikeres!',
          topicHasEducationalRequirementError: 'Hiba a Követelmény kapcsolat mentése közben!',
          deleteSuccess: 'Követelmény kapcsolat törlése sikeres!',
          deleteError: 'Hiba a Követelmény kapcsolat törlése közben!',
          deleteSelectedSuccess: 'Kiválasztott követelmény kapcsolatok törlése sikeres!',
          deleteSelectedError: 'Hiba a kiválasztott követelmény kapcsolatok törlése közben!'
        }
      },
      outcome: {
        notification: {
          saveSuccess: 'Kimeneti követelmény mentése sikeres!',
          saveError: 'Hiba a kimeneti követelmény mentése során!'
        }
      }
    }
  },
  sampleUnit: {
    label: {
      code: 'Kód',
      title: 'Cím',
      language: 'Nyelv',
      instituteName: 'Intézet',
      topicName: 'Téma',
      ownerNeptunCode: 'Tárgyfelelős Neptun-kódja',
      contactorNeptunCode: 'Kapcsolattartó',
      minStudents: 'Min. létsz.',
      maxStudents: 'Max. létsz.',
      absenceRatioName: 'Hiányzás',
      permission: 'Engedély',
      description: 'Általános leírás (Tematika)',
      scheduling: 'Ritmus',
      errors: 'Tárgyfelelős jegyzet',
      comment: 'TH feljegyzés',
      absenceRatioId: 'Megengedett hiányzás',
      acceptanceCondition: 'Félév-elfogadás további feltétele',
      absenceCompensation: 'Pótlás',
      midTermChecks: 'Félévközi ellenőrzések',
      examQuestions: 'Vizsgakérdések',
      ownCurriculum: 'Saját oktatási anyag',
      notes: 'Jegyzet',
      requiredReading: 'Kötelező irodalom',
      recommendedReading: 'Ajánlott irodalom',
      campusStudents: 'Campus-kredit létszám',
      feedbackSteps: 'Feedback alapján lépések',
      metodolicalSteps: 'További módszertani elemek',
      ownerName: 'Tárgyfelelős',
      contactPersons: 'Kapcsolattartók',
      topicCode: 'Téma',
      status: 'Szerkesztési státusz',
      curriculumStatus: 'Tantervi állapot',
      methodologicalElements: 'Módszertani elemek',
      base: 'Alapadatok',
      entreaty: 'Kérvény',
      campusFaculties: 'Campus karok',
      requestMessage: 'Kérvény indoklása',
      responseMessage: 'Válasz',
      opened: 'Nyitott',
      closed: 'Lezárt',
      owner: 'Tárgyfelelős',
      creationTime: 'Módosítás dátuma',
      createdBy: 'Módosító személye',
      auto: 'Módosulás típusa',
      institute: 'Intézet'
    },
    validation: {
      contactPersonsMaxLength: 'A Kapcsolattartók száma maximum {max} lehet',
      sampleUnitWithLangAlreadyExists: 'A megadott nyelvvel már létezik mintaegység a témában',
      sampleUnitOneOfRequired:
        'Saját oktatási anyag, Jegyzet, Kötelező irodalom és Ajánlott irodalom mezők közül legalább az egyik kitöltése kötelező',
      maxStudentsGreaterThan: 'A maximum létszám nem lehet kisebb mint a minimum',
      minStudentsSmallerThan: 'A minimum létszám nem lehet nagyobb mint a maximum',
      campusStudentsGreaterThan: 'A campus-kredit létszám nem lehet nagyobb mint a maximum'
    },
    details: {
      title: 'Mintaegység szerkesztése'
    },
    tabs: {
      base: 'Alapadatok',
      subject: 'Tantárgyak',
      lesson: 'Foglalkozások',
      examiner: 'Vizsgáztató',
      faculty: 'Campus karok',
      speciality: 'Szakok',
      entreaty: 'Alapadatok kérvény',
      subjectEntreaty: 'Tantárgy kérvény',
      courseTeachers: 'Szemináriumok, gyakorlatok oktatói'
    },
    dialog: {
      delete: {
        title: 'Mintaegység törlése',
        text: 'Biztosan törli a Mintaegységet?'
      },
      statusChange: {
        title: 'Mintaegység státusz módosítása',
        selected: '{count} mintaegység kiválasztva',
        incompatible: 'A kiválasztott mintaegységek tárgyainak státuszai nem kompatibilisek egymással!',
        hasMandatorySubject:
          'Kötelező tantárgy is szerepel a kijelöltek között, ezért csak "Véglegesített" és "Nem véglegesített" státusz választható.'
      },
      setStatus: {
        title: 'Mintaegység státusz módosítása',
        text: 'Biztosan módosítja a {sampleUnit} mintaegység státuszát {status}re? Ez a művelet a mintaegységhez kapcsolódó tantárgyak státuszát is módosítja. A művelet nem visszavonható!'
      },
      missingData: {
        title: 'Hiányzó mintaegység adatok'
      }
    },
    notification: {
      sampleUnitSuccess: 'Mintaegység mentése sikeres!',
      sampleUnitError: 'Hiba a Mintaegység mentése közben!',
      sampleUnitEntreatySuccess: 'Mintaegység kérvény mentése sikeres!',
      sampleUnitEntreatyError: 'Hiba a Mintaegység kérvény mentése közben!',
      subjectEntreatySuccess: 'Tantárgy kérvény mentése sikeres!',
      subjectEntreatyError: 'Hiba a Tantárgy kérvény mentése közben!',
      deleteSuccess: 'Mintaegység törlése sikeres!',
      deleteError: 'Hiba a Mintaegység törlése közben!',
      sampleUnitFinalizeSuccess: 'Mintaegység véglegesítése sikeres!',
      sampleUnitFinalizeError: 'Hiba a Mintaegység véglegesítése közben!',
      sampleUnitNotFinalizedSuccess: 'Mintaegység nem véglegesítettre állítása sikeres!',
      sampleUnitNotFinalizedError: 'Hiba a Mintaegység nem véglegesítettre állítása közben!',
      statusChangeSuccess: 'Mintaegység státusz módosítása sikeres!',
      statusChangeError: 'Hiba a mintaegység státusz módosítása közben!'
    },
    relation: {
      subject: {
        subjectName: 'Tárgy',
        sampleUnitName: 'Mintaegység',
        details: {
          title: 'Tárgy kapcsolat szerkesztése'
        },
        dialog: {
          delete: {
            title: 'Tárgy kapcsolat törlése',
            text: 'Biztosan törli a Tárgy kapcsolatot?'
          }
        },
        notification: {
          sampleUnitHasSubjectSuccess: 'Tárgy kapcsolat mentése sikeres!',
          sampleUnitHasSubjectError: 'Hiba a Tárgy kapcsolat mentése közben!',
          deleteSuccess: 'Tárgy kapcsolat törlése sikeres!',
          deleteError: 'Hiba a Tárgy kapcsolat törlése közben!'
        }
      },
      speciality: {
        specialityName: 'Szak',
        sampleUnitName: 'Mintaegység',
        details: {
          title: 'Szak kapcsolat szerkesztése'
        },
        dialog: {
          delete: {
            title: 'Szak kapcsolat törlése',
            text: 'Biztosan törli a Szak kapcsolatot?'
          }
        },
        notification: {
          sampleUnitHasSpecialitySuccess: 'Szak kapcsolat mentése sikeres!',
          sampleUnitHasSpecialityError: 'Hiba a Szak kapcsolat mentése közben!',
          deleteSuccess: 'Szak kapcsolat törlése sikeres!',
          deleteError: 'Hiba a Szak kapcsolat törlése közben!'
        }
      },
      faculty: {
        facultyName: 'Kar',
        sampleUnitName: 'Mintaegység',
        details: {
          title: 'Kar kapcsolat szerkesztése'
        },
        dialog: {
          delete: {
            title: 'Kar kapcsolat törlése',
            text: 'Biztosan törli a Kar kapcsolatot?'
          }
        },
        notification: {
          sampleUnitHasFacultySuccess: 'Kar kapcsolat mentése sikeres!',
          sampleUnitHasFacultyError: 'Hiba a Kar kapcsolat mentése közben!',
          deleteSuccess: 'Kar kapcsolat törlése sikeres!',
          deleteError: 'Hiba a Kar kapcsolat törlése közben!'
        }
      },
      courseTeacher: {
        filter: {
          type: 'Típus'
        },
        sampleUnitName: 'Mintaegység',
        teachers: 'Oktatók',
        courseTeacherName: 'Név',
        courseTeacherNeptunCode: 'Neptun kód',
        courseTeacherType: 'Típus',
        type: {
          ASSISTANT: 'Asszisztens',
          TEACHER: 'Oktató'
        },
        details: {
          title: 'Oktató kapcsolat szerkesztése'
        },
        new: {
          title: 'Oktató kapcsolat létrehozása'
        },
        dialog: {
          delete: {
            title: 'Oktató kapcsolat törlése',
            text: 'Biztosan törli az oktató kapcsolatot?'
          },
          deleteSelected: {
            title: 'Oktató kapcsolatok törlése',
            text: 'Biztosan törli a kiválasztott oktató kapcsolatokat?'
          }
        },
        notification: {
          sampleUnitHasExaminerError: 'Hiba az oktató kapcsolat mentése közben!',
          deleteSuccess: 'Oktató kapcsolat törlése sikeres!',
          deleteError: 'Hiba az oktató kapcsolat törlése közben!',
          sampleUnitHasCourseTeacherSuccess: 'Oktató kapcsolat mentése sikeres!',
          sampleUnitHasCourseTeacherError: 'Hiba az oktató kapcsolat mentése közben!'
        }
      },
      examiner: {
        examinerName: 'Vizsgáztató',
        sampleUnitName: 'Mintaegység',
        details: {
          title: 'Vizsgáztató kapcsolat szerkesztése'
        },
        dialog: {
          delete: {
            title: 'Vizsgáztató kapcsolat törlése',
            text: 'Biztosan törli a Vizsgáztató kapcsolatot?'
          },
          deleteSelected: {
            title: 'Vizsgáztató kapcsolatok törlése',
            text: 'Biztosan törli a kiválasztott Vizsgáztató kapcsolatokat?'
          }
        },
        notification: {
          sampleUnitHasExaminerSuccess: 'Vizsgáztató kapcsolat mentése sikeres!',
          sampleUnitHasExaminerError: 'Hiba a Vizsgáztató kapcsolat mentése közben!',
          deleteSuccess: 'Vizsgáztató kapcsolat törlése sikeres!',
          deleteError: 'Hiba a Vizsgáztató kapcsolat törlése közben!'
        }
      },
      lesson: {
        orderNumber: 'Sorszám',
        description: 'Leírás',
        noDescription: 'Nincs leírás',
        sampleUnitName: 'Mintaegység',
        type: 'Típus',
        lesson: 'Foglalkozás',
        teachers: 'Oktatók',
        details: {
          title: 'Foglalkozás kapcsolat szerkesztése'
        },
        dialog: {
          delete: {
            title: 'Foglalkozás kapcsolat törlése',
            text: 'Biztosan törli az Foglalkozás kapcsolatot?'
          },
          deleteSelected: {
            title: 'Foglalkozás kapcsolatok törlése',
            text: 'Biztosan törli a kiválasztott Foglalkozás kapcsolatokat?'
          },
          generate: {
            title: 'Foglalkozások generálása',
            text: 'Biztosan generálja a foglalkozásokat? A már létező foglalkozások törlődnek!'
          }
        },
        notification: {
          sampleUnitHasLessonSuccess: 'Foglalkozás kapcsolat mentése sikeres!',
          sampleUnitHasLessonError: 'Hiba az Foglalkozás kapcsolat mentése közben!',
          deleteSuccess: 'Foglalkozás kapcsolat törlése sikeres!',
          deleteError: 'Hiba az Foglalkozás kapcsolat törlése közben!',
          generateSuccess: 'Foglalkozások generálása sikeres!',
          generateError: 'Hiba a foglalkozások generálása közben!'
        },
        lessonType: {
          LECTURE: 'Előadás',
          EXERCISE: 'Gyakorlat',
          SEMINAR: 'Szeminárium'
        },
        importer: {
          title: 'Foglalkozások importálása'
        }
      }
    }
  },
  subject: {
    label: {
      code: 'Kód',
      title: 'Cím',
      institute: 'Intézet',
      subjectTitle: 'Tantárgy címe',
      subjectCode: 'Tantárgy kódja',
      academicYearCode: 'Év',
      moduleName: 'Modul',
      connectedModuleName: 'Kapcsolt modul',
      examTypeName: 'Jegykialakítás',
      earlyExam: 'Előrehozott vizsga',
      examCourse: 'Vizsgakurzus',
      examCourseJustification: 'Vizsgakurzusindítás mellőzésének szakmai indoka',
      credit: 'Kredit',
      scheduling: 'Ritmus',
      semester: 'Ajánlott félév',
      status: 'Szerkesztési státusz',
      curriculumStatus: 'Tantervi állapot',
      version: 'Verzió',
      specialityName: 'Szak',
      relatedLecture: 'Előadás kapcsolat',
      lectureName: 'Előadás',
      lectureNote: 'Előadás jegyzetek',
      relatedExercise: 'Gyakorlat kapcsolat',
      exerciseName: 'Gyakorlat',
      exerciseNote: 'Gyakorlat jegyzetek',
      relatedSeminar: 'Szeminárium kapcsolat',
      seminarName: 'Szeminárium',
      seminarNote: 'Szeminárium jegyzetek',
      sampleUnitName: 'Mintaegység',
      subjectType: 'Típus',
      baseSubject: 'Alapértelmezett tárgy',
      lectureData: 'eakurz',
      exerciseData: 'gyakkurz',
      seminarData: 'szemkurz',
      startYear: 'Futó évtől',
      endYear: 'Futó évig',
      examRequirement: 'Vizsgakövetelmény',
      base: 'Alapadatok',
      entreaty: 'Kérvény',
      opened: 'Nyitott',
      closed: 'Lezárt',
      requestMessage: 'Kérvény indoklása',
      responseMessage: 'Válasz',
      prerequisites: 'Előfeltételek',
      modules: 'Modul',
      entreatyPrerequisites: 'Kérvényben szereplő előfeltétek',
      sampleCurriculum: 'Mintatanterv',
      creationTime: 'Módosítás dátuma',
      createdBy: 'Módosító személye',
      auto: 'Módosulás típusa'
    },
    details: {
      title: 'Tárgy szerkesztése'
    },
    dialog: {
      delete: {
        title: 'Tárgy törlése',
        text: 'Biztosan törli a Tárgyat?'
      },
      statusChange: {
        title: 'Tantárgy státusz módosítás',
        subjectsSelected: '{count} tantárgy kiválasztva',
        hasMandatorySubject:
          'Kötelező tantárgy is szerepel a kijelöltek között, ezért csak "Véglegesített" és "Nem véglegesített" státusz választható.'
      },
      curriculumStatusChange: {
        title: 'Tantárgy tantervi státusz módosítás',
        subjectsSelected: '{count} tantárgy kiválasztva',
        subjectIsNotEditable: 'A kijelölt tantárgyak között van olyan, amely nem szerkeszthető.'
      },
      setStatus: {
        title: 'Tantárgy státusz módosítása',
        text: 'Biztosan módosítja a {subject} tárgy státuszát {status}re? A művelet nem visszavonható!'
      },
      missingData: {
        title: 'Hiányzó tantárgy adatok'
      }
    },
    notification: {
      subjectSuccess: 'Tárgy mentése sikeres!',
      subjectError: 'Hiba a Tárgy mentése közben!',
      deleteSuccess: 'Tárgy törlése sikeres!',
      deleteError: 'Hiba a Tárgy törlése közben!',
      subjectFinalizeSuccess: 'Tárgy véglegesítése sikeres!',
      subjectFinalizeError: 'Hiba a Tárgy véglegesítése közben!',
      subjectNotFinalizedSuccess: 'Tantárgy nem véglegesítettre állítása sikeres!',
      subjectNotFinalizedError: 'Hiba a Tantárgy nem véglegesítettre állítása közben!',
      statusChangeSuccess: 'Tantárgyak státusz módosítása sikeres!',
      statusChangeError: 'Hiba történt a tantárgyak státusz módosítása során!',
      curriculumStatusChangeSuccess: 'Tantárgyak tantervi státusz módosítása sikeres!',
      curriculumStatusChangeError: 'Hiba történt a tantárgyak tantervi státusz módosítása során!',
      subjectEntreatySuccess: 'Tantárgy kérvény mentése sikeres!',
      subjectEntreatyError: 'Hiba a Tantárgy kérvény mentése közben!',
      subjectEntreatyNotSavedYet: 'A tantárgy kérvény még nincs mentve!'
    },
    tabs: {
      base: 'Alapadatok',
      prerequisite: 'Előfeltételek',
      prerequisiteFor: 'Mihez előfeltétel',
      subjectHasSampleCurriculum: 'Mintatantervek'
    },
    relations: {
      prerequisite: {
        title: 'Tantárgy előfeltételek',
        subjectName: 'Tantárgy',
        prerequisiteName: 'Előfeltétel',
        prerequisiteSemester: 'Félév',
        prerequisiteCode: 'Előfeltétel kódja',
        listAlreadyFull: 'Maximum 3 előfeltétel adható meg!',
        type: 'Típus',
        types: {
          FINISHED: 'Teljesített',
          PARALLEL: 'Párhuzamos'
        },
        details: {
          title: 'Előfeltétel kapcsolat szerkesztése'
        },
        notification: {
          subjectPrerequisiteSuccess: 'Előfeltétel kapcsolat mentése sikeres!',
          subjectPrerequisiteError: 'Hiba az Előfeltétel kapcsolat mentése közben!',
          deleteSuccess: 'Előfeltétel kapcsolat törlése sikeres!',
          deleteError: 'Hiba az Előfeltétel kapcsolat törlése közben!'
        },
        dialog: {
          delete: {
            title: 'Előfeltétel törlése',
            text: 'Biztosan törli az Előfeltételt?'
          }
        }
      },
      subjectHasSampleCurriculum: {
        sampleCurriculumId: 'Mintatanterv',
        sampleCurriculumCode: 'Mintatanterv kódja',
        scheduledYear: 'Menetrendszerinti év',
        scheduling: 'Ritmus',
        semester: 'Ajánlott félév',
        noItems: 'Nincs mintatanterv a tárgyhoz csatolva',
        details: {
          title: 'Mintatanterv hozzáadása'
        },
        dialog: {
          delete: {
            title: 'Mintatanterv kapcsolat törlése',
            text: 'Biztos, hogy törli a mintatanterv kapcsolatot?'
          }
        },
        notification: {
          saveSuccess: 'Sikeres mintatanterv kapcsolat mentés',
          saveError: 'Hiba történt a mintatanterv kapcsolat hozzáadása során',
          deleteSuccess: 'Sikeres mintananterv kapcsolat törlés',
          deleteError: 'Hiba történt a mintatanterv kapcsolat törlése során'
        }
      }
    },
    subjectType: {
      MANDATORY: 'Kötelező',
      OPTIONAL: 'Választható'
    },
    examCourse: {
      N: 'nincs vizsgakurzus',
      OE: 'őszi vizsgakurzus előrehozott vizsgával',
      OD: 'őszi vizsgakurzus előrehozott vizsga NÉLKÜL',
      T: 'tavaszi vizsgakurzus előrehozott vizsga NÉLKÜL',
      ME: 'Mindkét félévben vizsgakurzus őszi előrehozott vizsgával',
      MD: 'Mindkét félévben vizsgakurzus előrehozott vizsga NÉLKÜL',
      null: '-'
    },
    validation: {
      subjectLectureOneOfRequired: 'Előadás kapcsolat vagy eakurz közül az egyik kitöltése kötelező',
      subjectExerciseOneOfRequired: 'Gyakorlat kapcsolat vagy gyakkurz közül az egyik kitöltése kötelező',
      subjectSeminarOneOfRequired: 'Szeminárium kapcsolat vagy szemkurz közül az egyik kitöltése kötelező',
      codeVersionAlreadyExists: 'A megadott kóddal és verzióval már létezik tantárgy a rendszerben'
    }
  },
  entreaty: {
    notification: {
      deleteSuccess: 'Kérvény törlése sikeres!',
      deleteError: 'Hiba a kérvény törlése közben!'
    },
    label: {
      type: 'Kérvény típusa',
      relationCode: 'Kapcsolódó kód',
      relationName: 'Kapcsolódó név',
      requestMessage: 'Kérvény indoklása',
      responseMessage: 'Válasz',
      closed: 'Lezárt',
      ownerNeptunCode: 'Felelős',
      status: 'Státusz'
    },
    dialog: {
      delete: {
        title: 'Kérvény törlése',
        text: 'Biztos szeretné törölni a kérvényt?'
      },
      deleteMultiple: {
        title: 'Kérvények törlése',
        text: 'Biztos szeretné törölni a kérvényeket?'
      },
      applyNew: {
        title: 'Új kurzus (téma) kérvényezése',
        text: `
        <p><strong>Kérem, hogy legyen kedves a következő kérdésekre adott válaszait megküldeni a
        <a href="mailto:targybejelento&#64;aok.pte.hu">targybejelento&#64;aok.pte.hu</a> címre:
        </strong></p>
    <ul>
        <li>volt-e már korábbi verziója / nyelvi változata (előzménye) a tervezett témának? Ha igen, akkor címet vagy
            mintaegység/tantárgy kódot legyen kedves megadni:</li>
        <li>meghirdetés nyelve: magyar / angol / német </li>
        <li>tantárgycím magyarul (akkor is, ha magyarul nem indul - igazolásokhoz kell): </li>
        <li>tantárgycím angolul (akkor is, ha angolul nem indul - igazolásokhoz kell): </li>
        <li>tantárgycím németül (akkor is, ha németül nem indul - igazolásokhoz kell): </li>

        <li>óraszám: hány előadás - hány gyakorlat - hány szeminárium: 0 ea + 0 gyak + szem</li>
        <ul>
            <li>ÁOK szakos meghirdetés: max. 12 az összóraszám, kérelemre 24</li>
        </ul>
        </li>
        <li>maximum létszám csoportonként (kurzusonként):</li>
        <li>hány csoportot szeretne indítani egy félévben (kurzus): 1</li>
        <ul>
            <li>ha egynél több, akkor szükség lesz majd egy indoklásra, amit szintén elbírálnak</li>
        </ul>
        <li>mely szak fogadja be: általános orvos, fogorvos, gyógyszerész, biotechnológus BSc, biotechnológus MSc</li>
        <li>mi legyen az előfeltétel (tantárgykód) szakonként (max. 3/szak):</li>
        <ul>
            <li>általános orvos:</li>
            <li>fogorvos:</li>
            <li>gyógyszerész:</li>
            <li>biotechnológus BSc:</li>
            <li>biotechnológus MSc:</li>
            <li>a tárgykódokat a TH weblapján kifüggesztett tantervekben a legegyszerűbb megnézni: <a
                    href="https://aok.pte.hu/hu/egyseg/570/szolgaltatasok/20">https://aok.pte.hu/hu/egyseg/570/szolgaltatasok/20
                </a> (biotech csak angolul van, azon tantervekhez nyelvet kell váltani jobb fent)</li>
        </ul>
        <li>melyik ajánlott félév (szakonként) az előfeltételt figyelembe véve (1..12):</li>
        <ul>
            <li>általános orvos:</li>
            <li>fogorvos:</li>
            <li>gyógyszerész:</li>
            <li>biotechnológus BSc:</li>
            <li>biotechnológus MSc:</li>
        </ul>

        <li>melyik félévben induljon kurzus: ősszel vagy tavasszal</li>
        <ul>
            <li>külön kérvényre mindkét félév is lehetséges</li>
        </ul>

        <li>jó-e a fakultatív vagy rögtön elektívben induljon: fakultatív / elektív</li>
        <ul>
            <li>külön kérvényre, nagyon jó indokra lehet azonnal elektívvel bepróbálkozni</li>
        </ul>
    </ul>
    <p>
        GYTK (gyógyszerész, biotechnológus BSC szakos) meghirdetésnél először Dr. Vida Róbert KUB elnökkel kell
        egyeztetni.
    </p>
        `
      },
      close: {
        title: 'Kérvény lezárása',
        message: 'A kérvény lezárásával módosítja a kérvény státuszát és szerkeszthetőségét.',
        acceptMessage: 'Amennyiben elfogadja a kérvényt, akkor a kérvényezett adatok felülírják a jelenlegieket.'
      }
    },
    messages: {
      containsEntreated: 'Módosítást tartalmaz',
      containsErrors: 'Hibát tartalmaz'
    }
  },
  information: {
    data: {
      server: 'Szerver információk',
      client: 'Kliens információk',
      version: 'Verzió',
      clientUrl: 'Kliens url',
      email: 'Alkalmazás email cím'
    }
  },
  instituteRevision: {
    label: {
      revisionId: 'Verziószám',
      revisionType: 'Típus'
    }
  },
  topicRevision: {
    label: {
      revisionId: 'Verziószám',
      revisionType: 'Típus'
    }
  },
  sampleUnitRevision: {
    label: {
      revisionId: 'Verziószám',
      revisionType: 'Típus'
    }
  },
  subjectRevision: {
    label: {
      revisionId: 'Verziószám',
      revisionType: 'Típus'
    }
  },
  entreatyType: {
    SUBJECT: 'Mintaegység tantárgyainak kérvénye',
    SAMPLE_UNIT: 'Mintaegység kérvénye',
    TOPIC: 'Téma kérvénye'
  },
  export: {
    course: 'Kurzus export',
    subject: 'Tantárgy export',
    lesson: 'Export foglalkozás táblához',
    entreaty: 'Kérvény export',
    entreatyList: 'Kérvény adminisztrációs export',
    checkData: 'Ellenőrző export',
    missingValidations: 'Hiányos adat validációk',
    educationalRequirement: 'Képzési és kimeneti követelmények export',
    clinicalSkill: 'Klinikai készségek export',
    sampleCurriculum: 'Mintatanterv export',
    programBook: 'Programfüzet',
    programBookModal: {
      title: 'Programfüzet export',
      matchSubjects: 'Egyező tárgyak: ',
      speciality: 'Szak',
      modules: 'Modulok',
      semesters: 'Ajánlott félév',
      headerText: 'Élőfej szövege',
      footerText: 'Élőláb szövege',
      titlePageText: 'Címlapi szöveg'
    },
    notification: {
      exportSuccess: 'Export letöltése sikeres',
      exportError: 'Hiba az export letörlése során'
    },
    label: {
      academicYear: 'Akadémiai év'
    },
    cmsIntegration: 'Cms integráció export',
    modal: {
      cmsIntegrationTitle: 'Cms integráció export'
    },
    validation: {
      specialityGroupTypeMatch: 'Nem egyezik a választott szakok szakcsoport típusa'
    }
  },
  entreatyStatus: {
    OPEN: 'Nyitott',
    PENDING_EVALUATION: 'Elbírálásra vár',
    ACCEPTED: 'Elfogadott',
    PARTIALY_ACCEPTED: 'Részlegesen elfogadott',
    REJECTED: 'Elutasított'
  },
  translation: {
    label: {
      translations: 'Címfordítások',
      hu: 'Magyar',
      en: 'Angol',
      de: 'Német',
      name: 'Név',
      HU: 'Magyar',
      EN: 'Angol',
      DE: 'Német'
    }
  },
  gender: {
    none: ' - ',
    male: 'Férfi',
    female: 'Nő'
  },
  label: {
    yes: 'Igen',
    no: 'Nem'
  },
  button: {
    create: 'Létrehozás',
    openSummary: 'Téma összegző megnyitása',
    createUser: 'Felhasználó létrehozása',
    edit: 'Szerkesztés',
    open: 'Megtekintés',
    copy: 'Másolás',
    close: 'Lezárás',
    download: 'Letöltés',
    delete: 'Törlés',
    save: 'Mentés',
    entreaty: 'Kérvény megnyitása',
    subjectEntreaty: 'Tantárgy kérvény megnyitása',
    accept: 'Elfogadás',
    reopen: 'Újranyitás',
    finalize: 'Véglegesítés',
    setNotFinalized: 'Nem véglegesítettre állítás',
    back: 'Vissza',
    cancel: 'Mégse',
    summary: 'Összegzés',
    add_new: 'Új létrehozása',
    addNew: 'Új hozzáadása',
    deleteSelected: 'Kijelöltek törlése',
    changeStatus: 'Státusz módosítás',
    changeCurriculumStatus: 'Tantervi állapot módosítás',
    activate: 'Aktiválás',
    inactivate: 'Inaktiválás',
    activation_email: 'Aktiváló email küldése',
    generate: 'Generálás',
    modify: 'Módosítás',
    closeModal: 'Bezárás',
    hierarchy: 'Hierarchia',
    makingCopy: 'Másolat készítése',
    downloadLessonExport: 'Foglalkozás export',
    exportTemplate: 'Export (sablon)',
    pause: 'Szüneteltetés',
    import: 'Importálás',
    downloadPdf: 'Mintatanterv letöltése',
    applyNew: 'Új kurzus (téma) kérvényezése',
    missingValidations: 'Hiányzó adatok',
    openSubject: 'Tantárgy megnyitása'
  },
  modal: {
    save: 'Mentés'
  },
  filter: {
    search: 'Keresés'
  },
  login: {
    signInSaml: 'PTE-s bejelentkezés',
    guestLogin: 'Vendég bejelentkezés'
  },
  yesOrNo: {
    true: 'Igen',
    false: 'Nem'
  },
  form: {
    validator: {
      modal: {
        formErrorTitle: 'Hiányzó vagy hibás alapadatok',
        formErrorText: 'Az űrlap nem menthető hibás, vagy hiányzó alapadatok miatt! Ezek pirossal ki vannak emelve az űrlapon.'
      },
      requiredOnFinalize: {
        invalidMessage: 'A mező kitöltése kötelező véglegesítés előtt!'
      }
    },
    label: {
      saved: 'Minden mentve',
      dirty: 'Mentetlen adatok',
      readonly: 'Csak olvasható'
    },
    routeLeave: {
      title: 'Mentetlen adatok',
      text: 'Az űrlapon végzett módosítások elvesznek, ha mentés nélkül hagyja el az oldalt. Biztos, hogy folytatja?'
    }
  },
  moduleType: {
    MANDATORY: 'Kötelező',
    OPTIONAL: 'Választható'
  },
  curriculumStatus: {
    CURRENT: 'Futó',
    DISCONTINUING: 'Kifutó',
    DISCONTINUED: 'Kifutott',
    NOTREADY: 'Még nem hirdethető'
  },
  scheduling: {
    AUTUMN: 'Őszi',
    SPRING: 'Tavaszi',
    BOTH: 'Minden'
  },
  editableStatus: {
    label: 'Szerkesztési státusz',
    NOT_FINISHED: 'Nem véglegesített',
    FINISHED: 'Véglegesített',
    INCOMPLETE: 'Hiányos',
    PAUSED: 'Szünetel',
    DELETED: 'Törölt'
  },
  active: {
    true: 'Aktív',
    false: 'Inaktív'
  },
  language: {
    HU: 'Magyar',
    EN: 'Angol',
    DE: 'Német'
  },
  userStat: {
    topics: 'Témák',
    sampleUnits: 'Mintaegységek',
    subjects: 'Tantárgyak',
    notFinishedSampleUnits: 'Nem véglegesített mintaegységek',
    editingPeriodEnd: 'Szerkesztési időszak vége',
    editingPeriodEndShort: 'Vége'
  },
  semester: 'félév',
  accessDenied: {
    title: 'Hozzáférés megtagadva'
  },
  specialityGroupType: {
    AOSZ: 'ÁOcs',
    FOSZ: 'FOcs',
    GYOSZ: 'GYcs',
    BIO: 'BIO',
    BIOB: 'BIOB',
    BIOM: 'BIOM'
  },
  revisionType: {
    0: 'Létrehozás',
    1: 'Módosítás',
    2: 'Törlés'
  },
  autocomplete: {
    giveNameOrNeptunCode: 'Adjon meg a rendszerben létező nevet vagy Neptun-kódot!',
    giveInstituteNeptunCode: 'Adja meg a rendszerben létező intézet Neptun-kódot!'
  },
  htmlEditor: {
    tagsAreInCounter: 'A karakterhosszba beleszámítanak a szövegben nem látható HTML tag-ek is.'
  },
  sidenav: {
    mandatorySubjects: 'Kötelező tárgyak',
    optionalSubjects: 'Választható tárgyak'
  },
  notification: {
    saveTheFormBeforeAction: 'Mentse el az űrlapot a művelet előtt!'
  },
  missingData: {
    plannedStatus: 'Tervezett státusz',
    notification: {
      loadError: 'Hiba történt az adatok betöltése során'
    },
    alert: {
      modalInfo: 'Az alábbi hiányzó adatok pótlását az adminisztrátorok tudják elvégezni.',
      noData: 'Nincs több hiányzó adat. Az adminisztrátorok tudják átállítani véglegesített státuszra.'
    }
  },
  uneditableYearDialog: {
    title: 'Hiba!',
    text: 'Feldolgozás alatt lévő akadémiai év nem szerkeszthető!'
  },
  uneditable_year: 'Hiba történt!',
  revision: {
    auto: {
      true: 'Automatikus',
      false: 'Manuális'
    }
  }
};
