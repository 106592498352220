import { ApiModule, type PaginatedResponseData } from '@common/index';
import type { InstituteRevisionItem, InstituteRevision } from '../models';

export class InstituteRevisionApi extends ApiModule {
  protected endpoint = '/institute-revision';

  public async pageInstituteRevisions(params: any) {
    return this.api.post<PaginatedResponseData<InstituteRevisionItem>, any>(
      `${this.endpoint}/page?page=${params.page}&size=${params.itemsPerPage}&sort=${params.sort ?? ''}`,
      {
        ...params
      }
    );
  }

  public loadInstituteRevisionDetails(id: number) {
    return this.api.get<InstituteRevision>(`${this.endpoint}?id=${id}`);
  }

  public saveInstituteRevision(params: any) {
    return this.api.post(`${this.endpoint}`, {
      ...params
    });
  }

  public deleteInstituteRevision(id: number) {
    return this.api.delete(`${this.endpoint}?id=${id}`);
  }
}
