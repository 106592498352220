import { Permission } from '@common/index';
import type { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
  {
    path: '/archive',
    name: 'archiveList',
    meta: {
      requiresAuth: true,
      permission: Permission.MENU_ADMINISTRATION
    },
    children: [
      {
        path: '',
        name: 'archiveTopicList',
        component: () => import('../pages/ArchiveTopicListPage.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: ':topicId',
        name: 'archiveTopicEditor',
        children: [
          {
            path: 'summary',
            name: 'archiveTopicSummary',
            children: [
              {
                path: ':academicYearCode',
                name: 'archiveTopicSummaryPage',
                component: () => import('../pages/ArchiveTopicSummaryPage.vue'),
                props: true
              }
            ]
          }
        ]
      }
    ]
  }
];

export default routes;
