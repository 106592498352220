import { ApiModule } from '../../api/services';

export class ValidatorApi extends ApiModule {
  protected endpoint = '/user';

  public getValidateEmail(email: string, userId: string | undefined) {
    return this.api.get<any>(`${this.endpoint}/validate-email`, {
      params: {
        email: email,
        userId: userId
      }
    });
  }

  public getValidatePhoneNumber(phoneNumber: string) {
    return this.api.get<any>('validate-phone-number', {
      params: {
        phoneNumber: phoneNumber
      }
    });
  }

  public validateNeptunCode(params: any) {
    return this.api.get<boolean>(`${this.endpoint}/validate-neptun-code`, { params });
  }
}
