<script lang="ts" setup>
import { computed, ref, toRefs } from 'vue';
import type { DialogModalProps } from '../';
import BaseModal from './BaseModal.vue';

const props = defineProps<DialogModalProps>();
const { config } = toRefs(props);
const isTranslate = computed(() => config.value.translate);
const baseModalRef = ref<any>(null);

const close = () => {
  baseModalRef.value?.close();
};

defineExpose({
  close
});
</script>

<template>
  <base-modal
    ref="baseModalRef"
    :config="config">
    <template #title="{ close }">
      <slot
        name="title"
        :title="config.title"
        :close="close">
        {{ isTranslate ? $t(config.title, config.translateParams) : config.title }}
      </slot>
    </template>
    <template #default="{ close }">
      <slot
        :text="config.text"
        :close="close">
        <p v-html="isTranslate ? $t(config.text, config.translateParams) : config.text"></p>
      </slot>
    </template>
    <template #actions="{ close }">
      <slot
        name="actions"
        :close="close">
        <v-btn @click="close">
          {{ $t('modal.close') }}
        </v-btn>
      </slot>
    </template>
  </base-modal>
</template>
