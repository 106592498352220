import { AdminApi } from './modules/admin/services/admin-api.service';
import { AbsenceRatioApi } from './modules/master-data/absence-ratio/services/absence-ratio-api.service';
import { AcademicYearApi } from './modules/master-data/academic-year/services/academic-year-api.service';
import { CampusFacultyApi } from './modules/master-data/campus-faculty/services/campus-faculty-api.service';
import { ClinicalSkillApi } from './modules/master-data/clinical-skill/services/clinical-skill-api.service';
import { EducationalRequirementApi } from './modules/master-data/educational-requirement/services/educational-requirement-api.service';
import { ExamTypeApi } from './modules/master-data/exam-type/services/exam-type-api.service';
import { FacultyApi } from './modules/master-data/faculty/services/faculty-api.service';
import { InstituteApi } from './modules/master-data/institute/services/institute-api.service';
import { MethodologicalElementApi } from './modules/master-data/methodological-element/services/methodological-element-api.service';
import { PositionApi } from './modules/master-data/position/services/position-api.service';
import { SpecialityApi } from './modules/master-data/speciality/services/speciality-api.service';
import { TopicCategoryApi } from './modules/master-data/topic-category/services/topic-category-api.service';
import { ModuleApi } from './modules/module/services/module-api.service';
import { SampleUnitHasExaminerApi } from './modules/sample-unit/relations/sample-unit-has-examiner/services/sample-unit-has-examiner-api.service';
import { SampleUnitHasLessonApi } from './modules/sample-unit/relations/sample-unit-has-lesson/services/sample-unit-has-lesson-api.service';
import { SampleUnitApi } from './modules/sample-unit/services/sample-unit-api.service';
import { SampleUnitEntreatyApi } from './modules/sample-unit/services/sample-unit-entreaty-api.service';
import { SubjectEntreatyApi } from './modules/sample-unit/services/subject-entreaty-api.service';
import { SubjectPrerequisiteApi } from './modules/subject/relations/subject-prerequisite/services/subject-prerequisite-api.service';
import { SubjectApi } from './modules/subject/services/subject-api.service';
import { TeacherApi } from './modules/teacher/services/teacher-api.service';
import { TopicHasSpecialityApi } from './modules/topic/relations/topic-has-speciality/services/topic-has-speciality-api.service';
import { TopicHasClinicalSkillApi } from './modules/topic/relations/topic-has-clinical-skill/services/topic-has-clinical-skill-api.service';
import { TopicHasEducationalRequirementApi } from './modules/topic/relations/topic-has-educational-requirement/services/topic-has-educational-requirement-api.service';
import { TopicApi } from './modules/topic/services/topic-api.service';
import { TopicEntreatyApi } from './modules/topic/services/topic-entreaty-api.service';
import { RouteMetaDataApi } from './services/route-metadata-api.service';
import { SampleUnitHasCourseTeacherApi } from './modules/sample-unit/relations/sample-unit-has-course-teacher/services/sample-unit-has-course-teacher-api.service';
import { SubjectPrerequisiteEntreatyApi } from './modules/sample-unit/relations/subject-prerequisite-entreaty/services/subject-prerequisite-entreaty-api.service';
import { EntreatyApi } from './modules/entreaty/services/entreaty-api.service';
import { ExportApi } from './modules/export/services/export-api.service';
import { DashboardApi } from './modules/dashboard/services/dashboard-api.service';
import { TopicRevisionApi } from './modules/revision/topic-revision/services/topic-revision-api.service';
import { SampleUnitRevisionApi } from './modules/revision/sample-unit-revision/services/sample-unit-revision-api.service';
import { SubjectRevisionApi } from './modules/revision/subject-revision/services/subject-revision-api.service';
import { InstituteRevisionApi } from './modules/revision/institute-revision/services/institute-revision-api.service';
import { InformationApi } from './modules/information/services/information-api.service';
import { MissingDataEditorApi } from './modules/missing-data-editor/services/missing-data-editor-service';
import { SampleCurriculumApi } from './modules/master-data/sample-curriculum/services/sample-curriculum-api.service';
import { SubjectHasSampleCurriculumApi } from './modules/subject/relations/subject-has-sample-curriculum/services';
import { ArchiveApi } from './modules/archive/services/topic-api.service';

const modules = {
  admin: new AdminApi(),
  academicYear: new AcademicYearApi(),
  examType: new ExamTypeApi(),
  topic: new TopicApi(),
  absenceRatio: new AbsenceRatioApi(),
  clinicalSkill: new ClinicalSkillApi(),
  educationalRequirement: new EducationalRequirementApi(),
  faculty: new FacultyApi(),
  institute: new InstituteApi(),
  position: new PositionApi(),
  speciality: new SpecialityApi(),
  teacher: new TeacherApi(),
  topicHasSpeciality: new TopicHasSpecialityApi(),
  topicHasClinicalSkill: new TopicHasClinicalSkillApi(),
  topicHasEducationalRequirement: new TopicHasEducationalRequirementApi(),
  topicCategory: new TopicCategoryApi(),
  module: new ModuleApi(),
  subject: new SubjectApi(),
  subjectPrerequisite: new SubjectPrerequisiteApi(),
  sampleUnit: new SampleUnitApi(),
  sampleUnitHasExaminer: new SampleUnitHasExaminerApi(),
  sampleUnitHasLesson: new SampleUnitHasLessonApi(),
  routeMetaData: new RouteMetaDataApi(),
  methodologicalElement: new MethodologicalElementApi(),
  topicEntreaty: new TopicEntreatyApi(),
  sampleUnitEntreaty: new SampleUnitEntreatyApi(),
  subjectEntreaty: new SubjectEntreatyApi(),
  campusFaculty: new CampusFacultyApi(),
  sampleUnitHasCourseTeacher: new SampleUnitHasCourseTeacherApi(),
  export: new ExportApi(),
  entreaty: new EntreatyApi(),
  subjectPrerequisiteEntreaty: new SubjectPrerequisiteEntreatyApi(),
  dashboard: new DashboardApi(),
  topicRevision: new TopicRevisionApi(),
  sampleUnitRevision: new SampleUnitRevisionApi(),
  subjectRevision: new SubjectRevisionApi(),
  instituteRevision: new InstituteRevisionApi(),
  missingDataEditor: new MissingDataEditorApi(),
  information: new InformationApi(),
  sampleCurriculum: new SampleCurriculumApi(),
  subjectHasSampleCurriculum: new SubjectHasSampleCurriculumApi(),
  archive: new ArchiveApi()
};

export default modules;
