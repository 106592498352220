import { Permission } from '@common/index';
import type { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
  {
    path: '/exam-types',
    name: 'examTypeList',
    component: () => import('../pages/ExamTypeListPage.vue'),
    meta: {
      requiresAuth: true,
      permission: Permission.MENU_ADMINISTRATION
    }
  }
];

export default routes;
