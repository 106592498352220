import { type Ref, onMounted } from 'vue';
import { useApi } from '../../../../../services';
import { createForm, setAsyncAutocompleteElementAdditionalParam, setSelectableFormElementChildItems, useIsLoading } from '@common/index';
import { useNotifications } from '@common/lib/modules/notification';
import { SUBJECT_PREREQUISITE_ENTREATY_DETAILS_FORM_CONFIG } from '../config';

export const useSubjectPrerequisiteEntreatyDetails = (
  subjectPrerequisiteEntreatyId: Ref<number | undefined>,
  subjectEntreatyId: number | undefined,
  subjectId: number | undefined,
  closeModal?: () => void
) => {
  const subjectPrerequisiteEntreatyApiService = useApi().subjectPrerequisiteEntreaty;
  const { isLoading, handlePromiseLoading } = useIsLoading();
  const notifications = useNotifications();

  const dataForm = createForm(SUBJECT_PREREQUISITE_ENTREATY_DETAILS_FORM_CONFIG);

  const loadSubjectPrerequisiteEntreatyDetails = async () => {
    if (subjectPrerequisiteEntreatyId.value) {
      const response = await subjectPrerequisiteEntreatyApiService.loadSubjectPrerequisiteEntreatyDetails(subjectPrerequisiteEntreatyId.value);
      if (response.data) {
        dataForm.value.value = response.data;
      }
    }
  };

  const onCancel = (close: () => void) => {
    closeModal?.call(this);
    dataForm.value.value = {};
    close();
  };

  const saveSubjectPrerequisiteEntreaty = async (close: () => void) => {
    const validationResult = await dataForm.validate();

    if (validationResult.valid) {
      await notifications.promise(handlePromiseLoading(subjectPrerequisiteEntreatyApiService.saveSubjectPrerequisiteEntreaty(dataForm.value.value)), {
        text: 'subject.relations.prerequisite.notification.subjectPrerequisiteSuccess',
        errorText: 'subject.relations.prerequisite.notification.subjectPrerequisiteError'
      });

      dataForm.value.value = {};
      closeModal?.call(this);
      close();
    }
  };

  const loadSelectDatas = async () => {
    dataForm.loading.value = true;
    if (subjectId && subjectEntreatyId) {
      const subjectResponse = await subjectPrerequisiteEntreatyApiService.getMetaData(
        subjectEntreatyId,
        subjectId,
        subjectPrerequisiteEntreatyId.value
      );

      setSelectableFormElementChildItems('subjectId', [{ label: subjectResponse.data.subjectName, value: subjectId }], dataForm.element);
      dataForm.value.value.subjectId = subjectId;
      dataForm.value.value.subjectEntreatyId = subjectEntreatyId;

      if (subjectResponse.data.subjectPrerequisiteId) {
        setSelectableFormElementChildItems(
          'prerequisiteId',
          [{ subjectLabelWithSemester: subjectResponse.data.subjectPrerequisiteLabelWithSemester, id: subjectResponse.data.subjectPrerequisiteId }],
          dataForm.element
        );
      }
    }
    dataForm.loading.value = false;
  };

  onMounted(async () => {
    setAsyncAutocompleteElementAdditionalParam('prerequisiteId', 'ignoreId', subjectId, dataForm.element);

    await handlePromiseLoading(loadSubjectPrerequisiteEntreatyDetails());
    await loadSelectDatas();
  });

  return {
    dataForm,
    isLoading,
    onCancel,
    saveSubjectPrerequisiteEntreaty
  };
};
