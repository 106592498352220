import { ApiModule, EditableStatus, type PaginatedResponseData } from '@common/index';
import type { SampleUnitItem, SampleUnit, SampleUnitMasterData, SampleUnitSubjectStat } from '../models';

export class SampleUnitApi extends ApiModule {
  protected endpoint = '/sample-unit';

  public async pageSampleUnits(params: any) {
    return this.api.post<PaginatedResponseData<SampleUnitItem>, any>(
      `${this.endpoint}/page?page=${params.page}&size=${params.itemsPerPage}&sort=${params.sort ?? ''}`,
      {
        ...params
      }
    );
  }

  public loadSampleUnitDetails(id: number) {
    return this.api.get<SampleUnit>(`${this.endpoint}?id=${id}`);
  }

  public saveSampleUnit(params: any, isCopy = false, oldId?: number) {
    const saveEndpoint = isCopy ? `${this.endpoint}/copy?oldSampleUnitId=${oldId}` : `${this.endpoint}`;
    return this.api.post<any, SampleUnit>(`${saveEndpoint}`, {
      ...params
    });
  }

  public finalizeSampleUnit(params: any) {
    return this.api.post<any, SampleUnit>(`${this.endpoint}/finalize`, {
      ...params
    });
  }

  public deleteSampleUnit(id: number) {
    return this.api.delete(`${this.endpoint}?id=${id}`);
  }

  public getMetaData(topicId: number | undefined, sampleUnitId: number | undefined) {
    return this.api.get<SampleUnitMasterData>(`${this.endpoint}/master-data`, { params: { sampleUnitId, topicId: topicId } });
  }

  public loadSampleUnitsForTopic(topicId: number) {
    return this.api.get<SampleUnit[]>(`${this.endpoint}/by-topic-id?topicId=${topicId}`);
  }

  public validateSampleUnitLanguage(params: any) {
    return this.api.get<boolean>(`${this.endpoint}/validate-language`, { params: { ...params } });
  }

  public validateSampleUnitCode(params: any) {
    return this.api.get<boolean>(`${this.endpoint}/validate-code`, { params: { ...params } });
  }

  public setSampleUnitStatus(sampleUnitId: number, status: EditableStatus) {
    return this.api.post<void, any>(`${this.endpoint}/set-status`, {}, { params: { sampleUnitId, status } });
  }

  public setMultipleStatus(sampleUnitIds: number[], selectedStatus: any): Promise<any> {
    return this.api.post<void, any>(`${this.endpoint}/set-multiple-status?status=${selectedStatus}`, { ids: sampleUnitIds });
  }

  public getSampleUnitStats(sampleUnitIds: number[]) {
    return this.api.get<SampleUnitSubjectStat[]>(`${this.endpoint}/multiple-subject-stats`, { params: { id: sampleUnitIds } });
  }

  public async pageOwnSampleUnits(params: any) {
    return this.api.post<PaginatedResponseData<SampleUnitItem>, any>(
      `${this.endpoint}/own/page?page=${params.page}&size=${params.itemsPerPage}&sort=${params.sort ?? ''}`,
      {
        ...params
      }
    );
  }

  public loadOwnSampleUnitDetails(id: number) {
    return this.api.get<SampleUnit>(`${this.endpoint}/own?id=${id}`);
  }

  public loadOwnSampleUnitsForTopic(topicId: number) {
    return this.api.get<SampleUnit[]>(`${this.endpoint}/own/by-topic-id?topicId=${topicId}`);
  }

  public saveOwnSampleUnit(params: any) {
    return this.api.post<any, SampleUnit>(`${this.endpoint}/own`, {
      ...params
    });
  }

  public setOwnStatus(sampleUnitId: number, status: EditableStatus) {
    return this.api.post<void, any>(`${this.endpoint}/own/set-status`, {}, { params: { id: sampleUnitId, status } });
  }
}
