import { ApiModule, type PaginatedResponseData } from '@common/index';
import type { SampleUnitHasCourseTeacherItem, SampleUnitHasCourseTeacher } from '../models';

export class SampleUnitHasCourseTeacherApi extends ApiModule {
  protected endpoint = '/sample-unit-has-course-teacher';

  public async pageSampleUnitHasCourseTeachers(params: any) {
    return this.api.post<PaginatedResponseData<SampleUnitHasCourseTeacherItem>, any>(
      `${this.endpoint}/page?page=${params.page}&size=${params.itemsPerPage}&sort=${params.sort ?? ''}`,
      {
        ...params
      }
    );
  }

  public loadSampleUnitHasCourseTeacherDetails(id: number) {
    return this.api.get<SampleUnitHasCourseTeacher>(`${this.endpoint}?id=${id}`);
  }

  public saveSampleUnitHasCourseTeacher(params: any) {
    return this.api.post(`${this.endpoint}`, {
      ...params
    });
  }

  public deleteSampleUnitHasCourseTeacher(id: number) {
    return this.api.delete(`${this.endpoint}?id=${id}`);
  }

  public addMultipleSampleUnitHasCourseTeacher(params: any) {
    return this.api.post(`${this.endpoint}/add-multiple`, {
      ...params
    });
  }

  public deleteMultiple(ids: number[], sampleUnitId: number) {
    return this.api.post<void, any>(`${this.endpoint}/delete-multiple?sampleUnitId=${sampleUnitId}`, { ids });
  }
}
