import { FormValidator, isEmpty, type FormValidatorConfig, type FormValidatorSettings } from '@common/index';

export type RequiredOnFinalizeValidatorSettings = FormValidatorSettings;

export type RequiredOnFinalizeValidatorConfig = FormValidatorConfig<RequiredOnFinalizeValidatorSettings>;

export class RequiredOnFinalizeValidator extends FormValidator<RequiredOnFinalizeValidatorSettings> {
  constructor() {
    super();
    this._name = 'requiredOnFinalize';
    this._settings = {
      invalidMessage: 'form.validator.requiredOnFinalize.invalidMessage',
      needTranslate: true
    };
  }

  public override async onValidate(value: any) {
    this._valid = !isEmpty(value);

    if (!this._valid) {
      this.addMessage(this._settings.invalidMessage);
    }
  }
}
