<script lang="ts" setup>
import type { DataTableButtonActionConfig } from '../../';
import { computed } from 'vue';

const props = defineProps<DataTableButtonActionConfig>();
const emit = defineEmits(['action']);
const hasIcon = computed(() => !!props.icon && !props.prependIcon && !props.appendIcon);
const isStacked = computed(() => !hasIcon.value && props.stacked);
const isDisabled = computed(() => props.disabled || false);
const isTranslated = computed(() => props.translateLabel || false);

const variant = computed(() => props.variant || 'elevated');

const onAction = () => {
  emit('action');
};
</script>

<template>
  <v-btn
    :variant="variant"
    :class="props.class"
    :color="color"
    :icon="hasIcon"
    :prepend-icon="prependIcon"
    :append-icon="appendIcon"
    :stacked="isStacked"
    :size="size"
    :disabled="isDisabled"
    @click.prevent.stop="onAction">
    <v-tooltip
      v-if="hasIcon"
      activator="parent"
      location="top">
      {{ isTranslated ? $t(label) : label }}
    </v-tooltip>
    <v-icon
      v-if="hasIcon"
      :size="iconSize"
      :icon="icon" />
    <template v-else>
      {{ isTranslated ? $t(label) : label }}
    </template>
  </v-btn>
</template>
