import { ApiModule, type PaginatedResponseData } from '@common/index';
import type { AcademicYearItem, AcademicYear, AcademicYearStatus } from '../models';

export class AcademicYearApi extends ApiModule {
  protected endpoint = '/academic-year';

  public async pageAcademicYears(params: any) {
    return this.api.post<PaginatedResponseData<AcademicYearItem>, any>(
      `${this.endpoint}/page?page=${params.page}&size=${params.itemsPerPage}&sort=${params.sort ?? ''}`,
      {
        ...params
      }
    );
  }

  public loadAcademicYearDetails(id: number) {
    return this.api.get<AcademicYear>(`${this.endpoint}`, { params: { id } });
  }

  public saveAcademicYear(params: any) {
    return this.api.post(`${this.endpoint}`, {
      ...params,
      editingPeriodEnd:
        !params.editingPeriodEnd || params.editingPeriodEnd.endsWith('T00:00:00') ? params.editingPeriodEnd : params.editingPeriodEnd + 'T00:00:00'
    });
  }

  public changeStatus(id: number, status: AcademicYearStatus) {
    return this.api.post(`${this.endpoint}/change-status?id=${id}&status=${status}`);
  }

  public loadAllAcademicYears() {
    return this.api.get<AcademicYear[]>(`${this.endpoint}/load-all-academic-years`);
  }

  public createUpcoming(id: number) {
    return this.api.post(`${this.endpoint}/create-upcoming?id=${id}`);
  }

  public copyActiveAcademicYear(id: number) {
    return this.api.post(`${this.endpoint}/copy-active-year?id=${id}`);
  }
}
