import type { AxiosError, AxiosInstance } from 'axios';
import type { ApiPlugin } from '../';
import * as Auth from '../../auth';

export class AccessDeniedResponsePlugin implements ApiPlugin {
  initialize(instance: AxiosInstance): void {
    instance.interceptors.response.use(
      (response) => {
        return response;
      },
      (error: AxiosError) => {
        if (error.response?.status === 403) {
          return this.handleAccessDenied(error);
        }
        return Promise.reject(error);
      }
    );
  }

  private handleAccessDenied = async (error) => {
    const authStore = Auth.useAuthStore();
    await authStore.navigateToAccessDenied();
    return Promise.reject(error);
  };
}
