import { ApiModule, type PaginatedResponseData } from '@common/index';
import type { ClinicalSkillItem, ClinicalSkill } from '../models';

export class ClinicalSkillApi extends ApiModule {
  protected endpoint = '/clinical-skill';

  public async pageClinicalSkills(params: any) {
    return this.api.post<PaginatedResponseData<ClinicalSkillItem>, any>(
      `${this.endpoint}/page?page=${params.page}&size=${params.itemsPerPage}&sort=${params.sort ?? ''}`,
      {
        ...params
      }
    );
  }

  public loadClinicalSkillDetails(id: number) {
    return this.api.get<ClinicalSkill>(`${this.endpoint}?id=${id}`);
  }

  public saveClinicalSkill(params: any) {
    return this.api.post(`${this.endpoint}`, {
      ...params
    });
  }

  public deleteClinicalSkill(id: number) {
    return this.api.delete(`${this.endpoint}?id=${id}`);
  }
}
