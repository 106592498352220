import { toRaw } from 'vue';
import { FormValidator, isEmpty, type FormValidatorConfig, type FormValidatorContext, type FormValidatorSettings } from '@common/index';

export type SampleUnitOneOfRequiredValidatorSettings = FormValidatorSettings;

export type SampleUnitOneOfRequiredValidatorConfig = FormValidatorConfig<SampleUnitOneOfRequiredValidatorSettings>;

export class SampleUnitOneOfRequiredValidator extends FormValidator<SampleUnitOneOfRequiredValidatorSettings> {
  constructor() {
    super();
    this._name = 'sampleUnitOneOfRequired';
    this._settings = {
      invalidMessage: 'sampleUnit.validation.sampleUnitOneOfRequired',
      needTranslate: true
    };
  }

  public override async onValidate(_: any, context: FormValidatorContext) {
    const formValues = toRaw(context.element.form?.value);
    const fields = ['ownCurriculum', 'notes', 'requiredReading', 'recommendedReading'];

    if (!formValues) {
      this.reset();
      return;
    }

    this.valid = fields.some((field) => !!formValues[field] && !isEmpty(formValues[field]));

    if (!this.valid) {
      this.addMessage(this.settings.invalidMessage);
    }
  }
}
