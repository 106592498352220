import { FormValidator, type FormValidatorContext, isEmpty, type FormValidatorConfig, type FormValidatorSettings } from '@common/index';
import { useApi } from '../../../../services';

export type CampusFacultyCodeValidatorSettings = FormValidatorSettings;
export type CampusFacultyCodeValidatorConfig = FormValidatorConfig<CampusFacultyCodeValidatorSettings>;

export class CampusFacultyCodeUniqueValidator extends FormValidator<CampusFacultyCodeValidatorSettings> {
  campusFacultyApi = useApi().campusFaculty;

  constructor() {
    super();
    this._name = 'campusFacultyCodeUniqueValidator';
    this._settings = {
      invalidMessage: 'campusFaculty.validation.codeAlreadyExists',
      needTranslate: true
    };
  }

  public override async onValidate(value: any, context: FormValidatorContext) {
    if (isEmpty(value)) {
      return;
    }

    const params = {
      code: context.element.parent?.value?.code || null,
      id: context.element.parent?.value?.id || 0
    };
    const response = await this.campusFacultyApi.validateCode(params);

    const notExist = response.data;
    if (notExist) {
      this._valid = true;
    } else {
      this.addMessage(this.settings.invalidMessage);
      this._valid = false;
    }
  }
}
