import { ApiModule, type PaginatedResponseData } from '@common/index';
import type { MethodologicalElementItem, MethodologicalElement } from '../models';

export class MethodologicalElementApi extends ApiModule {
  protected endpoint = '/methodological-element';

  public async pageMethodologicalElements(params: any) {
    return this.api.post<PaginatedResponseData<MethodologicalElementItem>, any>(
      `${this.endpoint}/page?page=${params.page}&size=${params.itemsPerPage}&sort=${params.sort ?? ''}`,
      {
        ...params
      }
    );
  }

  public getMethodologicalElements() {
    return this.api.get<any[]>(`${this.endpoint}/list`);
  }

  public loadMethodologicalElementDetails(id: number) {
    return this.api.get<MethodologicalElement>(`${this.endpoint}?id=${id}`);
  }

  public saveMethodologicalElement(params: any) {
    return this.api.post(`${this.endpoint}`, {
      ...params
    });
  }

  public deleteMethodologicalElement(id: number) {
    return this.api.delete(`${this.endpoint}?id=${id}`);
  }
}
