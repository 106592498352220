import { ApiModule } from '@common/index';
import type { MissingValidation } from '../models';

export class MissingDataEditorApi extends ApiModule {
  public loadTopicMissingValidation(topicId: number) {
    return this.api.get<MissingValidation[]>(`/topic/get-missing-validations?topicId=${topicId}`);
  }

  public loadSampleUnitMissingValidation(sampleUnitId: number) {
    return this.api.get<MissingValidation[]>(`/sample-unit/get-missing-validations?sampleUnitId=${sampleUnitId}`);
  }

  public loadSubjectMissingValidation(subjectId: number) {
    return this.api.get<MissingValidation[]>(`/subject/get-missing-validations?subjectId=${subjectId}`);
  }

  public loadOwnTopicMissingValidation(topicId: number) {
    return this.api.get<MissingValidation[]>(`/topic/own/get-missing-validations?topicId=${topicId}`);
  }

  public loadOwnSampleUnitMissingValidation(sampleUnitId: number) {
    return this.api.get<MissingValidation[]>(`/sample-unit/own/get-missing-validations?sampleUnitId=${sampleUnitId}`);
  }

  public loadOwnSubjectMissingValidation(subjectId: number) {
    return this.api.get<MissingValidation[]>(`/subject/own/get-missing-validations?subjectId=${subjectId}`);
  }
}
