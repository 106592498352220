import { FormValidator, type FormValidatorContext, isEmpty, type FormValidatorConfig, type FormValidatorSettings } from '@common/index';
import { useApi } from '../../../services';

export type SubjectCodeVersionUniqueValidatorSettings = FormValidatorSettings;
export type SubjectCodeVersionUniqueValidatorConfig = FormValidatorConfig<SubjectCodeVersionUniqueValidatorSettings>;

export class SubjectCodeVersionUniqueValidator extends FormValidator<SubjectCodeVersionUniqueValidatorSettings> {
  subjectApi = useApi().subject;

  constructor() {
    super();
    this._name = 'subjectCodeVersionUniqueValidator';
    this._settings = {
      invalidMessage: 'subject.validation.codeVersionAlreadyExists',
      needTranslate: true
    };
  }

  public override async onValidate(_: any, context: FormValidatorContext) {
    const code = context.element.parent?.value?.code || null;
    const version = context.element.parent?.value?.version || null;
    const id = context.element.parent?.value?.id || undefined;

    if (isEmpty(code) || isEmpty(version)) return;

    const params = {
      id,
      code,
      version
    };
    const response = await this.subjectApi.validateCodeVersion(params);

    const notExist = response.data;
    if (notExist) {
      this._valid = true;
    } else {
      this.addMessage(this.settings.invalidMessage);
      this._valid = false;
    }
  }
}
