import { ApiModule } from '@common/index';
import { EntreatyStatus, EntreatyType } from '../../entreaty/models';
import { type ExamTypeItem } from '../../master-data/exam-type/models';
import { type SubjectEntreatyData } from '../models/subject-entreaty.model';

export class SubjectEntreatyApi extends ApiModule {
  protected endpoint = '/entreaty';
  protected examTypeEndpoint = '/exam-type';
  protected subjectPrerequisiteEntreatyEndoint = '/subject-prerequisite-entreaty';
  protected type = EntreatyType.SUBJECT;

  public loadSubjectDetails(id: number) {
    return this.api.get<SubjectEntreatyData>(`${this.endpoint}/load-data`, {
      params: {
        relatedId: id,
        entreatyType: this.type
      }
    });
  }

  public createEntreaty(id: number) {
    return this.api.post<SubjectEntreatyData, any>(
      `${this.endpoint}/create`,
      {},
      {
        params: {
          relatedId: id,
          entreatyType: this.type
        }
      }
    );
  }

  public saveSubjectEntreaty(params: any) {
    return this.api.post<SubjectEntreatyData, any>(`${this.endpoint}/admin-save-data`, {
      ...params,
      entreatyMappedData: null,
      subjectData: null,
      entreatyType: this.type,
      subjectEntreatyData: this.mapEntreatyData(params.entreatyMappedData)
    });
  }

  public saveOwnSubjectEntreaty(params: any) {
    return this.api.post<SubjectEntreatyData, any>(`${this.endpoint}/teacher-save-data`, {
      ...params,
      entreatyMappedData: null,
      subjectData: null,
      entreatyType: this.type,
      subjectEntreatyData: this.mapEntreatyData(params.entreatyMappedData)
    });
  }

  public closeSubjectEntreaty(params: any, status: EntreatyStatus) {
    return this.api.post<SubjectEntreatyData, any>(`${this.endpoint}/close-entreaty-data?status=${status}`, {
      ...params,
      entreatyMappedData: null,
      subjectData: null,
      entreatyType: this.type,
      subjectEntreatyData: this.mapEntreatyData(params.entreatyMappedData)
    });
  }

  public reOpenSubjectEntreaty(id: any) {
    return this.api.post<SubjectEntreatyData>(`${this.endpoint}/reopen-entreaty-data?id=${id}`);
  }

  public getExamTypes() {
    return this.api.get<ExamTypeItem[]>(`${this.examTypeEndpoint}/get-list`, {});
  }

  public listSubjectPrerequisiteEntreaties(subjectEntreatyId: number) {
    return this.api.get(`${this.subjectPrerequisiteEntreatyEndoint}/list-by-subject-entreaty-id?subjectEntreatyId=${subjectEntreatyId}`);
  }

  public saveSubjectPrerequisiteEntreaty(params: any) {
    return this.api.post(`${this.subjectPrerequisiteEntreatyEndoint}`, {
      ...params
    });
  }

  public deleteSubjectPrerequisiteEntreaty(id: number) {
    return this.api.delete(`${this.subjectPrerequisiteEntreatyEndoint}?id=${id}`);
  }

  private mapEntreatyData(entreatyMappedData: any) {
    if (Array.isArray(entreatyMappedData)) {
      return entreatyMappedData.map((x) => x.subjectEntreatyData);
    } else if (typeof entreatyMappedData === 'object' && entreatyMappedData !== null) {
      return Object.keys(entreatyMappedData).map((key) => entreatyMappedData[key].subjectEntreatyData);
    }

    return [];
  }
}
