import type { AxiosProgressEvent } from 'axios';
import { ApiModule } from '../../api';

export class ImporterApiService extends ApiModule {
  public exportTemplate(baseUrl: string) {
    return this.api.get<Blob>(`${baseUrl}/export-template`, {
      headers: {
        Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      },
      responseType: 'blob'
    });
  }

  public async importFile(baseUrl: string, file: File, progressHandler: (event: AxiosProgressEvent) => void, additionalData?: any): Promise<any> {
    const formData = this.createFormData(file);
    return await this.api.post(`${baseUrl}/import`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      params: { ...additionalData },
      onUploadProgress: progressHandler
    });
  }

  private createFormData(file: Blob): FormData {
    const formData = new FormData();
    formData.append('file', file);

    return formData;
  }
}
