import { Permission } from '@common/index';
import type { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
  {
    path: '/applications',
    name: 'entreatyList',
    component: () => import('../pages/EntreatyListPage.vue'),
    meta: {
      requiresAuth: true,
      permission: Permission.MENU_ADMINISTRATION
    },
    children: []
  },
  {
    path: '/own-applications',
    name: 'ownEntreatyList',
    component: () => import('../pages/EntreatyListPage.vue'),
    meta: {
      requiresAuth: true
    },
    children: []
  }
];

export default routes;
