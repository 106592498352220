<script lang="ts" setup>
import { computed, onMounted, type PropType, ref, watch, toRaw } from 'vue';
import dateFormat from 'date-fns/format';
import { VDatePicker } from 'vuetify/labs/VDatePicker';
import type { VTextField } from 'vuetify/components';

const props = defineProps({
  modelValue: {
    type: [Object, String, Number, null] as PropType<Date | string | number | null>,
    default: undefined
  },
  format: {
    type: String,
    default: 'yyyy-MM-dd'
  },
  showAdjacentMonths: {
    type: Boolean,
    default: true
  },
  hideActions: {
    type: Boolean,
    default: true
  },
  appendInnerIcon: {
    type: String,
    default: 'mdi-calendar'
  },
  returnFormatted: {
    type: [Boolean, String] as PropType<boolean | string>,
    default: false
  },
  weekStartDay: {
    type: Number as PropType<0 | 1 | 2 | 3 | 4 | 5 | 6>,
    default: 1
  },
  returnType: {
    type: String,
    default: 'string'
  }
});

const emit = defineEmits(['update:modelValue', 'blur', 'input']);

const field = ref<VTextField | null>(null);
const open = ref(false);
const date = ref(props.modelValue ? [new Date(props.modelValue)] : []);
const dateText = ref('');
const renderKey = ref(0); // required for rerender after updating text value
const displayDate = computed(() => date.value[0] || new Date());
const emptyResult = ref(props.modelValue === null ? null : undefined);
const isString = ref(typeof props.modelValue === 'string' || props.returnType === 'string');
const isNumber = ref(typeof props.modelValue === 'number' || props.returnType === 'number');

onMounted(() => {
  setDate(date.value);
});

const computedValue = computed(() => props.modelValue);

watch(computedValue, (value) => {
  if (value) {
    date.value = [new Date(toRaw(value))];
    setDate(date.value);
  } else {
    date.value = [];
    dateText.value = '';
  }
});

const setDate = (value: Date[]) => {
  if (!value[0]) return;

  date.value = value;
  dateText.value = formatText(value[0]);
};

const update = (value: readonly Date[]) => {
  const typeSafeValue = Array.isArray(value) ? value : [value];
  setDate(typeSafeValue);

  const result = typeSafeValue[0] ? getReturnValue(typeSafeValue[0]) : emptyResult.value;
  emit('update:modelValue', result);
  onInput(value);
  open.value = false;

  if (field.value) {
    field.value.focus();
  }
};

const textUpdate = (value: string) => {
  dateText.value = value;

  const timestamp = Date.parse(value);
  if (isNaN(timestamp)) {
    date.value = [];
    emit('update:modelValue', emptyResult.value);
    return;
  }

  const result = new Date(timestamp);
  date.value = [result];
  renderKey.value += 1;
  emit('update:modelValue', getReturnValue(result));
};

const getReturnValue = (value: Date) => {
  if (props.returnFormatted) {
    return formatText(value, typeof props.returnFormatted === 'string' ? props.returnFormatted : props.format);
  }

  if (isString.value) {
    return value.toString();
  }

  if (isNumber.value) {
    return value.getTime();
  }

  return value;
};

const formatText = (value: Date, format: string = props.format) => {
  if (!value) return '';

  try {
    return dateFormat(value, format, { weekStartsOn: props.weekStartDay });
  } catch {
    return '';
  }
};

const onBlur = (e: any) => {
  dateText.value = formatText(date.value[0]);
  emit('blur', e);
};

const onInput = (e: any) => {
  emit('input', e);
};

const openPicker = () => {
  open.value = true;
};

const closePicker = () => {
  open.value = false;
};

defineExpose({ openPicker, closePicker });
</script>

<template>
  <v-text-field
    ref="field"
    :model-value="dateText"
    :append-inner-icon="appendInnerIcon"
    @update:model-value="textUpdate"
    @input="onInput"
    @change="onInput"
    @blur="onBlur">
    <v-menu
      v-model="open"
      activator="parent"
      :close-on-content-click="false"
      :disabled="$attrs.disabled as any || $attrs.readonly as any">
      <v-date-picker
        :key="renderKey"
        :model-value="date"
        :display-date="displayDate"
        :min="($attrs.min as any)"
        :title="$attrs.label as any"
        :color="$attrs.color as any"
        :show-adjacent-months="showAdjacentMonths"
        :hide-actions="hideActions"
        @update:model-value="update">
        <template #header></template>
      </v-date-picker>
    </v-menu>
    <template #message="{ message }">
      <slot
        name="message"
        :message="message"></slot>
    </template>
    <template
      v-if="$slots.append"
      #append>
      <slot name="append" />
    </template>
    <template
      v-if="$slots['append-inner']"
      #append-inner>
      <slot name="append-inner" />
    </template>
    <template
      #prepend
      v-if="$slots['prepend']">
      <slot name="prepend" />
    </template>
    <template
      v-if="$slots['prepend-inner']"
      #prepend-inner>
      <slot name="prepend-inner" />
    </template>
    <template #label="{ label, props }">
      <slot
        v-if="$slots['label']"
        name="label"
        :label="label"
        :props="props" />
      <span v-else>{{ label }}</span>
    </template>
  </v-text-field>
</template>
