<script lang="ts" setup>
import type { DataTableGridCardProps, DataTableItem } from '@common/lib/modules/datatable';

const props = defineProps<DataTableGridCardProps>();
const emit = defineEmits(['rowAction']);

const onRowAction = (action: string, item: DataTableItem) => {
  emit('rowAction', action, item);
};

const onRowClick = (item: DataTableItem) => {
  if (!props.clickableItems) return;
  onRowAction('click', item);
};
</script>

<template>
  <v-card
    class="data-table-card"
    :class="{ disabled: item.disabled }"
    :ripple="clickableItems"
    @click="onRowClick">
    <div
      v-for="(column, i) in columns"
      :key="i"
      class="data-table-card__row">
      <template v-if="column.name === '__data_table_row_actions__'">
        <v-card-actions>
          <template v-for="(action, j) in rowActions">
            <component
              v-if="!action.condition || action.condition(item)"
              :key="j"
              :is="action.actionComponent"
              :actionConfig="action.actionConfig"
              :disabledCondition="action.disabledCondition"
              :item="item"
              @action="onRowAction(action.action, item)" />
          </template>
        </v-card-actions>
      </template>
      <template v-else-if="column.name === '__data_table_index__'">
        <v-card-item>
          <div class="data-table-card__value">
            <component
              :is="column.cellComponent"
              :index="index" />
          </div>
        </v-card-item>
      </template>
      <template v-else>
        <v-card-item>
          <div
            v-if="column.name !== '__data_table_selected__' && column.needCardHeader"
            class="font-weight-bold data-table-card__column">
            {{ column.translateLabel ? $t(column.label) : column.label }}
          </div>
          <div class="data-table-card__value">
            <component
              :is="column.cellComponent"
              :column="column"
              :item="item"
              :cellConfig="column.cellConfig" />
          </div>
        </v-card-item>
      </template>
    </div>
  </v-card>
</template>
