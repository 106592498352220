import { ApiModule } from '@common/index';
import { EntreatyStatus, EntreatyType } from '../../entreaty/models';
import { type TopicEntreatyData } from '../models/topic-entreaty.model';

export class TopicEntreatyApi extends ApiModule {
  protected endpoint = '/entreaty';
  protected type = EntreatyType.TOPIC;

  public loadTopicDetails(id: number) {
    return this.api.get<TopicEntreatyData>(`${this.endpoint}/load-data`, {
      params: {
        relatedId: id,
        entreatyType: this.type
      }
    });
  }

  public createEntreaty(id: number) {
    return this.api.post<TopicEntreatyData, any>(
      `${this.endpoint}/create`,
      {},
      {
        params: {
          relatedId: id,
          entreatyType: this.type
        }
      }
    );
  }

  public saveTopicEntreaty(params: any) {
    return this.api.post<TopicEntreatyData, any>(`${this.endpoint}/admin-save-data`, {
      ...params,
      topicData: null,
      entreatyType: this.type
    });
  }

  public saveOwnTopicEntreaty(params: any) {
    return this.api.post<TopicEntreatyData, any>(`${this.endpoint}/teacher-save-data`, {
      ...params,
      topicData: null,
      entreatyType: this.type
    });
  }

  public closeTopicEntreaty(params: any, status: EntreatyStatus) {
    return this.api.post<TopicEntreatyData, any>(`${this.endpoint}/close-entreaty-data?status=${status}`, {
      ...params,
      topicData: null,
      entreatyType: this.type
    });
  }

  public reOpenTopicEntreaty(id: any) {
    return this.api.post<TopicEntreatyData>(`${this.endpoint}/reopen-entreaty-data?id=${id}`);
  }
}
