import { defineStore, storeToRefs } from 'pinia';
import { computed, ref, type Ref } from 'vue';
import type { AuthTokens, ChangePasswordData, Credentials, PasswordResetData, PersonalData } from '../models';
import { useLocalStorage, useSessionStorage } from '@vueuse/core';
import { useCommonApi } from '../../api';
import { useRouter } from 'vue-router';
import { useNotifications } from '../../notification';
import { Permission } from '../enums';
import { checkPermission } from '../utils';
import { useAcademicYearStore } from '../../../stores/academic-year';

const ACCESS_TOKEN_KEY = 'accessToken';
const REFRESH_TOKEN_KEY = 'refreshToken';
const REMEMBER_ME_KEY = 'rememberMe';
const IMPERSONATED_KEY = 'impersonated';

export const useAuthStore = defineStore('authStore', () => {
  const commonApi = useCommonApi();
  const router = useRouter();
  const notifications = useNotifications();

  const rememberMe: Ref<boolean> = useLocalStorage(REMEMBER_ME_KEY, false);
  const sessionAccessToken: Ref<string | null> = useSessionStorage(ACCESS_TOKEN_KEY, null);
  const sessionRefreshToken: Ref<string | null> = useSessionStorage(REFRESH_TOKEN_KEY, null);
  const persistentAccessToken: Ref<string | null> = useLocalStorage(ACCESS_TOKEN_KEY, null);
  const persistentRefreshToken: Ref<string | null> = useLocalStorage(REFRESH_TOKEN_KEY, null);
  const impersonated: Ref<boolean> = useSessionStorage(IMPERSONATED_KEY, false);

  const accessToken = computed(() => (rememberMe.value && !impersonated.value ? persistentAccessToken.value : sessionAccessToken.value));
  const refreshToken = computed(() => (rememberMe.value && !impersonated.value ? persistentRefreshToken.value : sessionRefreshToken.value));
  const isImpersonated = computed(() => impersonated.value);

  const personalData: Ref<PersonalData | null> = ref(null);
  const permissions: Ref<Permission[]> = ref([]);
  const loggedIn = computed(() => !!accessToken.value);

  const isAdmin = computed(() => personalData.value?.role === 'admin');

  const academicYearStore = useAcademicYearStore();
  const { academicYears, selectedAcademicYear } = storeToRefs(academicYearStore);

  const setAuthTokens = (tokens: AuthTokens) => {
    if (rememberMe.value) {
      persistentAccessToken.value = tokens.token;
      persistentRefreshToken.value = tokens.refreshToken;
    } else {
      sessionAccessToken.value = tokens.token;
      sessionRefreshToken.value = tokens.refreshToken;
    }
  };

  const login = async (credentials: Credentials) => {
    const response = await commonApi.auth.login(credentials);

    if (response.data) {
      rememberMe.value = credentials.rememberMe ?? false;
      setAuthTokens(response.data);

      await loadPersonalData();

      const redirect = router.currentRoute.value.query.redirect;
      const homeRouteName = isAdmin.value ? 'sampleUnitList' : 'ownSampleUnitList';

      await router.replace(redirect ? redirect.toString() : { name: homeRouteName });
    }
  };

  const refreshAccessToken = async () => {
    if (refreshToken.value) {
      const response = await commonApi.auth.refreshToken(refreshToken.value);

      const tokens = response.data;
      if (tokens) {
        setAuthTokens({ refreshToken: refreshToken.value, token: tokens.token });

        return true;
      }
    }

    return false;
  };

  const navigateToAccessDenied = async () => {
    await router.push({ name: 'accessDenied' });
  };

  const setPassword = async (passwordResetData: PasswordResetData) => {
    await commonApi.auth.setPassword(passwordResetData);

    await router.push({ name: 'login' });
  };

  const logout = async (useRedirect?: any, userLogout = false) => {
    if (refreshToken.value && userLogout) {
      const response = await commonApi.auth.logout(refreshToken.value);

      if (response.data.url) {
        document.location.href = response.data.url;
      }
    }

    sessionAccessToken.value = null;
    sessionRefreshToken.value = null;

    if (!impersonated.value) {
      persistentAccessToken.value = null;
      persistentRefreshToken.value = null;
    }

    impersonated.value = false;
    permissions.value = [];

    let redirect: any = { name: 'login' };
    if (useRedirect === true && router.currentRoute.value.name !== 'login') {
      redirect = { name: 'login', query: { redirect: router.currentRoute.value.fullPath } };
    }

    await router.push(redirect);
  };

  const forgotPassword = async (email: string) => {
    await commonApi.auth.forgotPassword(email);
  };

  const changePassword = async (changePasswordData: ChangePasswordData) => {
    await notifications.promise(commonApi.auth.changePassword(changePasswordData), {
      text: 'auth.notification.changePassword.success',
      errorText: 'auth.notification.changePassword.error'
    });
  };

  const savePersonalData = async (data: PersonalData) => {
    const response = await notifications.promise(commonApi.auth.savePersonalData(data), {
      text: 'auth.notification.savePersonalData.success',
      errorText: 'auth.notification.savePersonalData.error'
    });
    if (response.data) {
      personalData.value = response.data;
    }
  };

  const loadPersonalData = async () => {
    const response = await commonApi.auth.loadPersonalData();

    if (response.data) {
      personalData.value = response.data;
      permissions.value = personalData.value?.permissions;
      academicYears.value = response.data.academicYears;
      selectedAcademicYear.value = academicYears.value[0];
    }
  };

  const loadAcademicYears = async () => {
    const response = await commonApi.auth.loadPersonalData();

    if (response.data) {
      academicYears.value = response.data.academicYears;
      selectedAcademicYear.value = academicYears.value[0];
    }
  };

  const hasPermission = (permission: Permission | Permission[]) => {
    return checkPermission(permissions.value, permission);
  };

  const loginSaml = async (needToRememberMe: boolean) => {
    rememberMe.value = needToRememberMe;

    const response = await commonApi.auth.loginSaml();

    document.location.href = response.data.url;
  };

  const exchangeSamlToken = async (token: string) => {
    const response = await commonApi.auth.loginSamlExchangeToken(token);

    setAuthTokens(response.data);

    await loadPersonalData();

    const redirect = router.currentRoute.value.query.redirect;
    const homeRouteName = isAdmin.value ? 'sampleUnitList' : 'ownSampleUnitList';
    await router.replace(redirect ? redirect.toString() : { name: homeRouteName });
  };

  const initializingPromise: Ref<Promise<void> | null> = ref(null);

  const initStore = async () => {
    try {
      if (accessToken.value) {
        await loadPersonalData();
      }
    } finally {
      initializingPromise.value = null;
    }
  };

  initializingPromise.value = initStore();

  return {
    accessToken,
    refreshToken,
    personalData,
    loggedIn,
    isAdmin,
    login,
    logout,
    refreshAccessToken,
    forgotPassword,
    setPassword,
    changePassword,
    savePersonalData,
    isImpersonated,
    hasPermission,
    permissions,
    initializingPromise,
    loginSaml,
    exchangeSamlToken,
    navigateToAccessDenied,
    loadAcademicYears
  };
});
