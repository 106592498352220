export default {
  dataTable: {
    viewMode: {
      table: 'Táblázat nézet',
      grid: 'Rács nézet'
    },
    sort: {
      sort: 'Rendezés',
      none: 'Alapértelmezett'
    },
    selectAll: 'Összes elem kijelölése',
    loading: 'Betöltés...'
  },
  dataManager: {
    paginator: {
      itemsPerPage: 'Elem / oldal'
    }
  },
  modal: {
    close: 'Bezárás',
    cancel: 'Mégse',
    confirm: 'Mehet',
    ok: 'Ok'
  },
  welcomeModal: {
    dontShowAgain: 'Ne jelenjen meg többször ez az üzenet'
  },
  validation: {
    required: 'Mező kitöltése kötelező!',
    companyNumber: 'Mező formátuma nem megfelelő! (BS-CF-NNNNNN)',
    minChars: 'Minimum {min} karakter!',
    maxChars: 'Maximum {max} karakter!',
    email: 'Nem megfelelő e-mail cím formátum!',
    parcel: 'Nem megfelelő helyrajzi szám formátum!'
  },
  form: {
    input: {
      datePicker: {
        selectDate: 'Válasszon dátumot'
      },
      formArray: {
        confirmClearTitle: 'Összes elem törlése',
        confirmClearText: 'Biztos, hogy törlöd az összes elemet?',
        confirmRemoveTitle: 'Elem törlése',
        confirmRemoveText: 'Biztos, hogy törlöd az elemet?'
      },
      htmlEditor: {
        action: {
          subscript: 'Alsóindex',
          superscript: 'Felsőindex'
        }
      }
    },
    validator: {
      requiredTrue: 'A beleegyezés elfogadása kötelező!',
      required: {
        invalidMessage: 'A mező kitöltése kötelező'
      },
      length: {
        invalidMessage: 'A karakterek száma minimum {min} és maximum {max} lehet',
        invalidMessageMin: 'A karakterek száma minimum {min} lehet',
        invalidMessageMax: 'A karakterek száma maximum {max} lehet'
      },
      childrenValid: {
        invalidMessage: 'Kérjük ellenőrizze a blokkban megadott adatok helyességét'
      },
      email: {
        invalidMessage: 'Nem megfelelő e-mail cím formátum!'
      },
      companyNumber: {
        invalidMessage: 'Mező formátuma nem megfelelő! (BS-CF-NNNNNN)'
      },
      taxNumber: {
        invalidMessage: 'Mező formátuma nem megfelelő! (xxxxxxxx-y-zz)'
      },
      dateIsAfter: {
        invalidMessage: 'A dátum túl korai.'
      }
    }
  },
  login: {
    signIn: 'Bejelentkezés',
    signInSaml: 'SAML bejelentkezés',
    email: 'E-mail',
    password: 'Jelszó',
    rememberMe: 'Maradjak bejelentkezve',
    forgotPassword: 'Elfelejtett jelszó',
    backToLogin: 'Vissza a bejelentkezéshez',
    sendEmail: 'Email elküldése',
    username: 'Felhasználónév'
  },
  auth: {
    newPassword: 'Új jelszó',
    passwordConfirm: 'Jelszó megerősítése',
    setPassword: 'Jelszó beállítása',
    name: 'Név',
    email: 'Email',
    phoneNumber: 'Telefonszám',
    personalData: 'Személyes adatok',
    changePassword: 'Jelszó megváltoztatása',
    currentPassword: 'Jelenlegi jelszó',
    validation: {
      passwordMustMatch: 'A jelszavaknak egyeznie kell!',
      passwordMinLength: 'A jelszónak minimum {min} karakter hosszúnak kell lennie!',
      passwordFormat: 'A jelszónak tartalmaznia kell kisbetűt, nagybetűt, számot és speciális karaktert!'
    },
    hint: {
      passwordFormat:
        'A jelszónak minimum {min} karakter hosszúnak kell lennie, tartalmaznia kell kisbetűt, nagybetűt, számot és speciális karaktert!'
    },
    btn: {
      save: 'Mentés'
    },
    notification: {
      savePersonalData: {
        success: 'Sikeres módosítás!',
        error: 'Hiba történt a módosítás során!'
      },
      saveCustomerDetails: {
        success: 'Sikeres módosítás!',
        error: 'Hiba történt a módosítás során!'
      },
      createCustomer: {
        success: 'Sikeres létrehozás!',
        error: 'Hiba történt a létrehozás során!'
      },
      changePassword: {
        success: 'Sikeres jelszó módosítás!',
        error: 'Hiba történt a jelszó módosítása során!'
      },
      api: {
        noActiveAccount: 'Sikertelen bejelentkezés! Az email cím vagy a jelszó nem megfelelő!'
      },

      401: 'Sikertelen bejelentkezés!',
      404: 'Sikertelen bejelentkezés!',
      500: 'Sikertelen bejelentkezés!'
    }
  },
  notification: {
    default: {
      success: 'Sikeres művelet',
      error: 'Hiba történt a művelet végrehajtása során'
    }
  },

  forgotPassword: {
    notification: {
      text: 'Email sikeresen elküldve!',
      errorText: 'Ezzel az email címmel nincs aktív felhasználó!'
    }
  },
  importer: {
    label: {
      browse: 'Fájl tallózása'
    },
    btn: {
      upload: 'Importálás',
      downloadTemplate: 'Sablon letöltése'
    }
  }
};
