import { FormValidator, isEmpty, type FormValidatorConfig, type FormValidatorSettings, useCommonApi } from '@common/index';

export type EditingPeriodEndValidatorSettings = FormValidatorSettings;
export type EditingPeriodEndValidatorConfig = FormValidatorConfig<EditingPeriodEndValidatorSettings>;

export class EditingPeriodEndValidator extends FormValidator<EditingPeriodEndValidatorSettings> {
  validatorApi = useCommonApi().validator;

  constructor() {
    super();
    this._name = 'editingPeriodEndValidator';
    this._settings = {
      invalidMessage: 'academicYear.validation.editingPeriodIsWithinWeek',
      needTranslate: true
    };
  }

  public override async onValidate(value: any) {
    if (isEmpty(value)) {
      return;
    }
    const dateValue = new Date(value);
    const date = new Date();
    date.setDate(date.getDate() + 7);

    if (dateValue > date || dateValue < new Date()) {
      this._valid = true;
    } else {
      this.addMessage(this.settings.invalidMessage, 'warning');
      this._valid = true;
    }
  }
}
