import { Permission } from '@common/index';
import type { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
  {
    path: '/campus-faculties',
    name: 'campusFacultyList',
    component: () => import('../pages/CampusFacultyListPage.vue'),
    meta: {
      requiresAuth: true,
      permission: Permission.MENU_ADMINISTRATION
    }
  }
];

export default routes;
