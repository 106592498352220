import { ApiModule } from '@common/index';
import type { RouteMetaData } from '../models';

export class RouteMetaDataApi extends ApiModule {
  protected endpoint = '/base/route-metadata';

  public loadRouteMetaData(params: any) {
    return this.api.get<RouteMetaData>(`${this.endpoint}`, { params });
  }
}
