import {
  createApi,
  DefaultResponsePlugin,
  AuthHeaderPlugin,
  RefreshTokenPlugin,
  AcceptLanguagePlugin,
  AccessDeniedResponsePlugin,
  AcademicYearHeaderPlugin,
  UneditableYearPlugin
} from '@common/index';
import modules from '../api-modules';

const api = createApi({ modules });
api.registerPlugins([
  AccessDeniedResponsePlugin,
  DefaultResponsePlugin,
  AuthHeaderPlugin,
  AcademicYearHeaderPlugin,
  RefreshTokenPlugin,
  AcceptLanguagePlugin,
  UneditableYearPlugin
]);

export const useApi = () => api.getModules();
