import { ApiModule, type PaginatedResponseData } from '@common/index';
import type { InstituteItem, Institute } from '../models';

export class InstituteApi extends ApiModule {
  protected endpoint = '/institute';
  protected facultyEndpoint = '/faculty';

  public async pageInstitutes(params: any) {
    return this.api.post<PaginatedResponseData<InstituteItem>, any>(
      `${this.endpoint}/page?page=${params.page}&size=${params.itemsPerPage}&sort=${params.sort ?? ''}`,
      {
        ...params
      }
    );
  }

  public loadInstituteDetails(id: number) {
    return this.api.get<Institute>(`${this.endpoint}?id=${id}`);
  }

  public saveInstitute(params: any) {
    return this.api.post(`${this.endpoint}`, {
      ...params
    });
  }

  public deleteInstitute(id: number) {
    return this.api.delete(`${this.endpoint}?id=${id}`);
  }

  public getFaculties() {
    return this.api.get<any[]>(`${this.facultyEndpoint}/list`);
  }

  public listInstituteNeptunCodes() {
    return this.api.get<string[]>(`${this.endpoint}/list-institute-neptun-codes`);
  }
}
