<script lang="ts" setup>
import { inject, ref, watch } from 'vue';
import { useDataTable } from '../';

const emit = defineEmits(['change']);
const dataTableService = inject<ReturnType<useDataTable>>('dataTableService');
const currentMode = ref<string>(dataTableService.getCurrentViewMode.value.name);

watch(dataTableService.getCurrentViewMode, () => {
  currentMode.value = dataTableService.getCurrentViewMode.value.name;
});

const changeEvent = () => {
  const selected = dataTableService.changeViewMode(currentMode.value);
  emit('change', selected.name);
};
</script>

<template>
  <v-btn-toggle
    v-model="currentMode"
    mandatory
    color="primary"
    density="compact"
    variant="elevated"
    elevation="1"
    class="data-table-view-mode-change">
    <v-btn
      v-for="(viewMode, i) in dataTableService.getViewModes.value"
      :key="i"
      :value="viewMode.name"
      @click="changeEvent">
      <v-tooltip
        activator="parent"
        location="top">
        {{ viewMode.translateLabel ? $t(viewMode.label) : viewMode.label }}
      </v-tooltip>
      <v-icon>{{ viewMode.icon }}</v-icon>
    </v-btn>
  </v-btn-toggle>
</template>
