import { ApiModule, type PaginatedResponseData } from '@common/index';
import type { ExamTypeItem, ExamType } from '../models';

export class ExamTypeApi extends ApiModule {
  protected endpoint = '/exam-type';

  public async pageExamTypes(params: any) {
    return this.api.post<PaginatedResponseData<ExamTypeItem>, any>(
      `${this.endpoint}/page?page=${params.page}&size=${params.itemsPerPage}&sort=${params.sort ?? ''}`,
      {
        ...params
      }
    );
  }

  public loadExamTypeDetails(id: number) {
    return this.api.get<ExamType>(`${this.endpoint}?id=${id}`);
  }

  public saveExamType(params: any) {
    return this.api.post(`${this.endpoint}`, {
      ...params
    });
  }

  public deleteExamType(id: number) {
    return this.api.delete(`${this.endpoint}?id=${id}`);
  }
}
