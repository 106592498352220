<script lang="ts" setup>
import { computed, onMounted, onUnmounted, type PropType, ref, toRaw, watch } from 'vue';
import { Subscript } from '@tiptap/extension-subscript';
import { Superscript } from '@tiptap/extension-superscript';
import { CharacterCount } from '@tiptap/extension-character-count';
import { HardBreak } from '@tiptap/extension-hard-break';
import { Editor, EditorContent, type Extensions } from '@tiptap/vue-3';
import { type Content as EditorValue } from '@tiptap/core';
import {
  CtsHtmlEditorAction,
  DEFAULT_HTML_EDITOR_EXTENSIONS,
  DEFAULT_HTML_EDITOR_TOOLBAR_ACTIONS,
  type HtmlEditorToolbarAction
} from '@common/lib/modules/form';
import { convertParagraphsToHardBreaks, stripHtmlTagsAndSpecialChars } from '@common/lib/utils';

const props = defineProps({
  modelValue: {
    type: [String, Object] as PropType<EditorValue>,
    default: null
  },
  variant: {
    type: String as PropType<'filled' | 'outlined' | 'plain' | 'underlined' | 'solo' | 'solo-inverted' | 'solo-filled'>,
    required: false
  },
  focused: {
    type: Boolean,
    default: false
  },
  active: {
    type: Boolean,
    default: false
  },
  dirty: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  },
  readonly: {
    type: Boolean,
    default: false
  },
  label: {
    type: String,
    required: false
  },
  rows: {
    type: [Number, String],
    default: 5,
    validator: (v: any) => !isNaN(parseFloat(v))
  },
  output: {
    type: String as PropType<'html' | 'json' | 'text'>,
    default: 'html'
  },
  extensions: {
    type: Array as PropType<Extensions>,
    default: () => [...DEFAULT_HTML_EDITOR_EXTENSIONS, Subscript, Superscript]
  },
  toolbar: {
    type: [Boolean, String] as PropType<boolean | 'hover'>,
    default: 'hover'
  },
  toolbarActions: {
    type: Array as PropType<HtmlEditorToolbarAction[]>,
    default: DEFAULT_HTML_EDITOR_TOOLBAR_ACTIONS
  },
  maxHeight: {
    type: Number,
    required: false
  },
  loading: {
    type: Boolean,
    default: false
  },
  flat: {
    type: Boolean,
    default: false
  },
  rounded: {
    type: Boolean,
    default: false
  },
  clearable: {
    type: Boolean,
    default: false
  },
  persistentClear: {
    type: Boolean,
    default: false
  },
  singleLine: {
    type: Boolean,
    default: false
  },
  persistentCounter: {
    type: Boolean,
    default: false
  },
  color: {
    type: String,
    required: false
  },
  baseColor: {
    type: String,
    required: false
  },
  bgColor: {
    type: String,
    required: false
  },
  appendInnerIcon: {
    type: String,
    required: false
  },
  prependInnerIcon: {
    type: String,
    required: false
  },
  clearIcon: {
    type: String,
    required: false
  },
  counter: {
    type: [Boolean, Number, String] as PropType<boolean | number | string>,
    required: false
  },
  counterValue: {
    type: [Object, Number] as PropType<any>,
    required: false
  },
  counterMode: {
    type: String as PropType<'html' | 'text'>,
    default: 'text'
  },
  maxLength: {
    type: [String, Number] as PropType<string | number>,
    required: false
  },
  disabledCharacters: {
    type: Array as PropType<string[]>,
    default: () => ['<', '>']
  },
  useHardBreak: {
    type: Boolean,
    default: true
  }
});

const emit = defineEmits(['update:modelValue', 'update:focused', 'click:control', 'mousedown:control', 'input']);

const editor = ref<Editor>();
const editorRef = ref<any>(null);
const model = ref(
  props.useHardBreak && props.output === 'text' ? convertParagraphsToHardBreaks(props.modelValue as any as string) : props.modelValue
);

watch(
  () => props.modelValue,
  (value) => {
    if (props.output === 'json') {
      const jsonValue = JSON.stringify(toRaw(value));
      const jsonModelValue = JSON.stringify(toRaw(model.value));
      if (jsonValue === jsonModelValue) return;
    } else {
      if (model.value === value) return;
    }

    const val = (props.useHardBreak && props.output === 'text' ? convertParagraphsToHardBreaks(value as any as string) : value) as EditorValue;
    model.value = val;
    editor.value?.commands.setContent(val, true);
  }
);

watch(model, (value) => {
  emit('update:modelValue', value);
});

const isFocused = ref(props.focused);

watch(
  () => props.focused,
  (focused) => {
    if (isFocused.value === focused) return;
    isFocused.value = focused;
  }
);

const onFocus = () => {
  if (isFocused.value) return;
  isFocused.value = true;
  emit('update:focused', true);
};

const onBlur = () => {
  if (!isFocused.value) return;
  isFocused.value = false;
  emit('update:focused', false);
};

const isActive = computed(() => isFocused.value || props.active);

const isPlainOrUnderlined = computed(() => props.variant && ['plain', 'underlined'].includes(props.variant));

const max = computed(() => {
  if (props.maxLength) return props.maxLength as string | number;
  if (!props.counter || (typeof props.counter !== 'number' && typeof props.counter !== 'string')) return undefined;

  return props.counter;
});

const counterValue = computed(() => {
  return typeof props.counterValue === 'function'
    ? props.counterValue(model.value)
    : stripHtmlTagsAndSpecialChars(((props.counterMode === 'html' ? editor.value?.getHTML() : editor.value?.getText()) || '').toString()).length;
});

watch(
  () => props.disabled,
  (disabled) => {
    if (!editor.value) return;
    editor.value.setEditable(!disabled, false);
  }
);

watch(
  () => props.readonly,
  (readonly) => {
    if (!editor.value) return;
    editor.value.setEditable(!readonly, false);
  }
);

const extensions = computed(() => {
  const result = [...props.extensions];

  if (max.value) {
    const maxNumber = Number(`${max.value}`);
    result.push(
      CharacterCount.configure({
        mode: props.counterMode === 'html' ? 'nodeSize' : 'textSize',
        limit: maxNumber
      })
    );
  }

  if (props.useHardBreak) {
    result.push(
      HardBreak.extend({
        addKeyboardShortcuts: () =>
          ({
            Enter: () => editor.value?.commands.setHardBreak()
          } as any)
      })
    );
  }

  return result;
});

onMounted(() => {
  editor.value = new Editor({
    extensions: extensions.value,
    content: props.modelValue,
    injectCSS: true,
    editorProps: {
      editable: () => !props.disabled,
      attributes: () => ({}),
      handleKeyDown: (_: any, event: KeyboardEvent) => {
        if (props.disabledCharacters && props.disabledCharacters.includes(event.key)) {
          return true;
        }

        emit('input', new InputEvent('input'));
        return false;
      }
    },
    onFocus: () => onFocus(),
    onBlur: () => onBlur(),
    onUpdate: onEditorUpdate
  });
});

onUnmounted(() => {
  editor.value?.destroy();
});

const onEditorUpdate = () => {
  if (editor.value?.isEmpty || editor.value?.getText() === '') {
    model.value = props.output === 'json' ? null : '';
  }

  switch (props.output) {
    case 'text':
      model.value = (editor.value?.getText() as EditorValue) || '';
      break;
    case 'json':
      model.value = (editor.value?.getJSON() as EditorValue) || null;
      break;
    default:
      model.value = (editor.value?.getHTML() as EditorValue) || '';
  }
};

const onControlClick = (e: MouseEvent) => {
  onFocus();
  emit('click:control', e);
};

const onControlMousedown = (e: MouseEvent) => {
  emit('mousedown:control', e);
};

const onAction = () => {
  emit('input', new InputEvent('input'));
};

const height = computed(() => {
  if (!editorRef.value) return '0px';

  const style = getComputedStyle(editorRef.value.$el);
  const lineHeight = parseFloat(style.lineHeight);
  const padding =
    parseFloat(style.getPropertyValue('--v-field-padding-top')) +
    parseFloat(style.getPropertyValue('--v-input-padding-top')) +
    parseFloat(style.getPropertyValue('--v-field-padding-bottom'));

  return `${lineHeight * parseFloat(props.rows as string) + padding}px`;
});

const toolbarOpen = ref(props.toolbar === true);

const showToolbar = () => {
  if (props.toolbar !== 'hover') return;
  toolbarOpen.value = true;
};

const hideToolbar = () => {
  if (props.toolbar !== 'hover' || isFocused.value) return;
  toolbarOpen.value = false;
};

watch(isFocused, (focused) => {
  if (!focused) {
    hideToolbar();
  } else {
    showToolbar();
  }
});
</script>

<template>
  <v-input
    ref="inputRef"
    v-model="model"
    :class="[
      'position-relative',
      'v-textarea v-text-field',
      {
        'v-input--plain-underlined': isPlainOrUnderlined
      }
    ]"
    :focused="isFocused"
    :readonly="readonly"
    :disabled="disabled"
    @mouseenter="showToolbar"
    @mouseleave="hideToolbar">
    <template
      v-if="$slots.prepend"
      #prepend>
      <slot name="prepend" />
    </template>
    <template
      v-if="$slots.append"
      #append>
      <slot name="append" />
    </template>
    <template
      v-if="$slots.details || !!counter || !!counterValue"
      #details>
      <slot name="details">
        <template v-if="!!counter || !!counterValue">
          <span />
          <v-counter
            :active="persistentCounter || isFocused"
            :value="counterValue"
            :max="max" />
        </template>
      </slot>
    </template>
    <template
      v-if="$slots.message"
      #message>
      <slot name="message" />
    </template>
    <template #default="{ id, isDisabled, isDirty, isReadonly, isValid }">
      <transition
        name="slide-y-reverse-transition"
        mode="out-in">
        <div
          v-if="toolbar && !isReadonly.value && toolbarOpen"
          class="d-flex position-absolute gap-1 cts-html-editor__toolbar">
          <v-btn-group
            density="compact"
            class="bg-white"
            variant="outlined">
            <template
              v-for="(action, i) in toolbarActions"
              :key="i">
              <cts-html-editor-action
                :action="action"
                :editor="editor as Editor"
                @action="onAction" />
              <v-divider
                v-if="i < toolbarActions.length - 1"
                :vertical="true" />
            </template>
          </v-btn-group>
        </div>
      </transition>
      <v-field
        ref="fieldRef"
        :label="label"
        :loading="loading"
        :color="color"
        :flat="flat"
        :base-color="baseColor"
        :bg-color="bgColor"
        :append-inner-icon="appendInnerIcon"
        :clear-icon="clearIcon"
        :clearable="clearable"
        :prepend-inner-icon="prependInnerIcon"
        :rounded="rounded"
        :single-line="singleLine"
        :persistent-clear="persistentClear"
        :id="id.value"
        :active="isActive || isDirty.value"
        :dirty="isDirty.value || dirty"
        :disabled="isDisabled.value"
        :focused="isFocused"
        :error="isValid.value === false"
        :variant="variant"
        :centerAffix="rows === 1 && !isPlainOrUnderlined"
        @click="onControlClick"
        @mousedown="onControlMousedown">
        <template
          v-if="$slots.label"
          #label="{ label, props }">
          <slot
            name="label"
            :label="label"
            :props="props" />
        </template>
        <template
          v-if="$slots['prepend-inner']"
          #prepend-inner>
          <slot name="prepend-inner" />
        </template>
        <template
          v-if="$slots['append-inner']"
          #append-inner>
          <slot name="append-inner" />
        </template>
        <template
          v-if="$slots.clear"
          #clear>
          <slot name="clear" />
        </template>
        <template
          v-if="$slots.loader"
          #loader>
          <slot name="loader" />
        </template>
        <template #default>
          <editor-content
            ref="editorRef"
            class="v-field__input cts-html-editor__input"
            :class="{
              'cts-html-editor__input--readonly': isReadonly.value,
              'cts-html-editor__input--disabled': isDisabled.value,
              'cts-html-editor__input--resizable': isActive
            }"
            :style="{ '--cts-html-editor-row-height': height }"
            :editor="editor" />
        </template>
      </v-field>
    </template>
  </v-input>
</template>
