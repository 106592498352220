import { ApiModule, type PaginatedResponseData } from '@common/index';
import type { SampleUnitHasExaminerItem, SampleUnitHasExaminer } from '../models';

export class SampleUnitHasExaminerApi extends ApiModule {
  protected endpoint = '/sample-unit-has-examiner';
  protected sampleUnitEndpoint = '/sample-unit';

  public async pageSampleUnitHasExaminers(params: any) {
    return this.api.post<PaginatedResponseData<SampleUnitHasExaminerItem>, any>(
      `${this.endpoint}/page?page=${params.page}&size=${params.itemsPerPage}&sort=${params.sort ?? ''}`,
      {
        ...params
      }
    );
  }

  public loadSampleUnitHasExaminerDetails(id: number) {
    return this.api.get<SampleUnitHasExaminer>(`${this.endpoint}?id=${id}`);
  }

  public saveSampleUnitHasExaminer(params: any) {
    return this.api.post(`${this.endpoint}`, {
      ...params
    });
  }

  public addMultipleSampleUnitHasExaminer(params: any) {
    return this.api.post(`${this.endpoint}/add-multiple`, {
      ...params
    });
  }

  public deleteSampleUnitHasExaminer(id: number) {
    return this.api.delete(`${this.endpoint}?id=${id}`);
  }

  public deleteMultiple(ids: number[], sampleUnitId: number) {
    return this.api.post<void, any>(`${this.endpoint}/delete-multiple?sampleUnitId=${sampleUnitId}`, { ids });
  }
}
