import { ApiModule, type PaginatedResponseData } from '@common/index';
import type { SubjectRevisionItem, SubjectRevision } from '../models';

export class SubjectRevisionApi extends ApiModule {
  protected endpoint = '/subject-revision';

  public async pageSubjectRevisions(params: any) {
    return this.api.post<PaginatedResponseData<SubjectRevisionItem>, any>(
      `${this.endpoint}/page?page=${params.page}&size=${params.itemsPerPage}&sort=${params.sort ?? ''}`,
      {
        ...params
      }
    );
  }

  public loadSubjectRevisionDetails(id: number) {
    return this.api.get<SubjectRevision>(`${this.endpoint}?id=${id}`);
  }

  public saveSubjectRevision(params: any) {
    return this.api.post(`${this.endpoint}`, {
      ...params
    });
  }

  public deleteSubjectRevision(id: number) {
    return this.api.delete(`${this.endpoint}?id=${id}`);
  }
}
