import { ApiModule, type PaginatedResponseData } from '@common/index';
import type { FacultyItem, Faculty } from '../models';

export class FacultyApi extends ApiModule {
  protected endpoint = '/faculty';

  public async pageFacultys(params: any) {
    return this.api.post<PaginatedResponseData<FacultyItem>, any>(
      `${this.endpoint}/page?page=${params.page}&size=${params.itemsPerPage}&sort=${params.sort ?? ''}`,
      {
        ...params
      }
    );
  }

  public loadFacultyDetails(id: number) {
    return this.api.get<Faculty>(`${this.endpoint}?id=${id}`);
  }

  public saveFaculty(params: any) {
    return this.api.post(`${this.endpoint}`, {
      ...params
    });
  }

  public deleteFaculty(id: number) {
    return this.api.delete(`${this.endpoint}?id=${id}`);
  }

  public getFaculties() {
    return this.api.get<FacultyItem[]>(`${this.endpoint}/list`);
  }
}
