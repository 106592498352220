import { ApiModule } from '@common/index';

export class ExportApi extends ApiModule {
  protected endpoint = '/export-data';

  public loadSubjectExportDetails() {
    return this.api.get<Blob>(`${this.endpoint}/subject/xls`, {
      headers: {
        Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      },
      responseType: 'blob'
    });
  }

  public loadLessonExportDetails() {
    return this.api.get<Blob>(`${this.endpoint}/lesson/xls?&sampleUnitId=`, {
      headers: {
        Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      },
      responseType: 'blob'
    });
  }

  public loadEntreatyExportDetails() {
    return this.api.get<Blob>(`${this.endpoint}/entreaty/xls`, {
      headers: {
        Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      },
      responseType: 'blob'
    });
  }

  public loadEntreatyListExportDetails() {
    return this.api.get<Blob>(`${this.endpoint}/entreaty-list/xls`, {
      headers: {
        Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      },
      responseType: 'blob'
    });
  }

  public loadCheckDataExportDetails() {
    return this.api.get<Blob>(`${this.endpoint}/check-data/xls`, {
      headers: {
        Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      },
      responseType: 'blob'
    });
  }

  public loadMissingValidationExportDetails() {
    return this.api.get<Blob>(`${this.endpoint}/missing-validations/xls`, {
      headers: {
        Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      },
      responseType: 'blob'
    });
  }

  public educationalRequirementExportDetails() {
    return this.api.get<Blob>(`${this.endpoint}/educational-requirement/xls`, {
      headers: {
        Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      },
      responseType: 'blob'
    });
  }

  public clinicalSkillExportDetails() {
    return this.api.get<Blob>(`${this.endpoint}/clinical-skill/xls`, {
      headers: {
        Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      },
      responseType: 'blob'
    });
  }

  public sampleCurriculumExportDetails() {
    return this.api.get<Blob>(`${this.endpoint}/sample-curriculum/xls`, {
      headers: {
        Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      },
      responseType: 'blob'
    });
  }

  public courseExportDetails() {
    return this.api.get<Blob>(`${this.endpoint}/course/xls`, {
      headers: {
        Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      },
      responseType: 'blob'
    });
  }

  public programBookExportDetails(filterData: any) {
    return this.api.get<Blob>(
      `${this.endpoint}/program-book/pdf?modules=${filterData.modules}&semesters=${filterData.semesters}&specialityId=${filterData.speciality}&headerText=${filterData.headerText}&footerText=${filterData.footerText}&titlePageText=${filterData.titlePageText}`,
      {
        headers: {
          Accept: 'application/pdf'
        },
        responseType: 'blob'
      }
    );
  }

  public programBookCalculateCount(filterData: any) {
    return this.api.get<number>(
      `${this.endpoint}/program-book/calculate-count?modules=${filterData.modules}&semesters=${filterData.semesters}&specialityId=${filterData.speciality}`
    );
  }

  public cmsIntegrationExportDetails() {
    return this.api.get<Blob>(`${this.endpoint}/cms-integration/csv`, {
      headers: {
        Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      },
      responseType: 'blob'
    });
  }

  public sampleUnitCurriculumExportPdf(id: number) {
    return this.api.get<Blob>(`${this.endpoint}/sample-unit/pdf?id=${id}`, {
      headers: {
        Accept: 'application/pdf'
      },
      responseType: 'blob'
    });
  }
}
