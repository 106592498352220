import { ApiModule, type PaginatedResponseData } from '@common/index';
import type { TopicRevisionItem } from '../models';

export class TopicRevisionApi extends ApiModule {
  protected endpoint = '/topic-revision';

  public async pageTopicRevisions(params: any) {
    return this.api.post<PaginatedResponseData<TopicRevisionItem>, any>(
      `${this.endpoint}/page?page=${params.page}&size=${params.itemsPerPage}&sort=${params.sort ?? ''}`,
      {
        ...params
      }
    );
  }
}
