import type { RouteRecordRaw } from 'vue-router';

export const routes: RouteRecordRaw[] = [
  {
    name: 'login',
    path: '/login',
    component: () => import('../pages/LoginPage.vue')
  },
  {
    name: 'resetPassword',
    path: '/reset-password/:token',
    component: () => import('../pages/ResetPasswordPage.vue'),
    props: true
  }
];

export const mainRoutes: RouteRecordRaw[] = [];

export default { routes, mainRoutes };
