<script setup lang="ts">
import { computed } from 'vue';
import { CtsForm, DialogModal } from '@common/index';
import { type SubjectPrerequisiteEntreatyModalProps } from '../models';
import { useSubjectPrerequisiteEntreatyDetails } from '../composables';

const props = defineProps<SubjectPrerequisiteEntreatyModalProps>();
const subjectPrerequisiteEntreatyId = computed(() => props.config.subjectPrerequisiteEntreatyItem?.id);
const { dataForm, saveSubjectPrerequisiteEntreaty, onCancel, isLoading } = useSubjectPrerequisiteEntreatyDetails(
  subjectPrerequisiteEntreatyId,
  props.config.subjectEntreatyId,
  props.config.subjectId,
  props.config.onClose
);

const isPrereuisiteListFull = computed(() => props.config.alreadyAddedCount > 2);
</script>

<template>
  <dialog-modal :config="config">
    <template #default>
      <div class="mt-5">
        <cts-form :form="dataForm.element"></cts-form>
      </div>

      <v-alert
        v-if="isPrereuisiteListFull"
        color="warning"
        >{{ $t('subject.relations.prerequisite.listAlreadyFull') }}</v-alert
      >
    </template>
    <template #actions="{ close }">
      <v-btn
        color="primary"
        @click="saveSubjectPrerequisiteEntreaty(close)"
        :loading="isLoading"
        :disabled="isPrereuisiteListFull">
        {{ $t('modal.save') }}
      </v-btn>
      <v-btn @click="onCancel(close)">
        {{ $t('modal.cancel') }}
      </v-btn>
    </template>
  </dialog-modal>
</template>
