import { Permission } from '@common/index';
import type { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
  {
    path: 'subjects',
    name: 'subjectData',
    meta: {
      requiresAuth: true,
      permission: Permission.MENU_ADMINISTRATION
    },
    children: [
      {
        path: '',
        name: 'subjectList',
        component: () => import('../pages/SubjectListPage.vue')
      },
      {
        path: 'create',
        name: 'subjectCreateContainer',
        component: () => import('../pages/SubjectDetailsPage.vue'),
        props: true,
        children: [
          {
            path: '',
            name: 'subjectCreate',
            props: true,
            component: () => import('../containers/SubjectDetails.vue')
          }
        ]
      },
      {
        path: ':subjectId',
        name: 'subjectDetails',
        component: () => import('../pages/SubjectDetailsPage.vue'),
        props: true,
        children: [
          {
            path: '',
            name: 'subjectEdit',
            component: () => import('../containers/SubjectDetails.vue'),
            props: true
          },
          {
            path: 'copy',
            name: 'subjectCopy',
            component: () => import('../containers/SubjectDetails.vue'),
            props: (route) => ({ ...route.params, isCopy: true })
          },
          {
            path: 'prerequisite',
            name: 'subjectPrerequisite',
            component: () => import('../containers/SubjectPrerequisite.vue'),
            props: true
          },
          {
            path: 'prerequisite-for',
            name: 'subjectPrerequisiteFor',
            component: () => import('../containers/SubjectPrerequisiteFor.vue'),
            props: true
          },
          {
            path: 'sample-curriculum',
            name: 'subjectHasSampleCurriculum',
            component: () => import('../containers/SubjectHasSampleCurriculum.vue'),
            props: true
          }
        ]
      }
    ]
  }
];

export default routes;
