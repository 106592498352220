<script lang="ts" setup>
import { computed, inject, type PropType } from 'vue';
import { type DataTableColumn, type DataTableItem, useDataTable } from '../../../';

const dataTableService = inject<ReturnType<typeof useDataTable>>('dataTableService')!;

const props = defineProps({
  column: {
    type: Object as PropType<DataTableColumn>,
    required: true
  },
  item: {
    type: Object as PropType<DataTableItem>,
    required: true
  },
  index: {
    type: Number,
    required: true
  }
});

const emit = defineEmits(['rowAction']);

const rowActions = computed(() => dataTableService.getRowActions.value);

const onRowAction = (action: string, item: DataTableItem) => {
  emit('rowAction', action, item);
};

const cellClass = computed(() => {
  return {
    'data-table-cell': true,
    'data-table-cell--selection': props.column.name === '__data_table_selected__',
    'data-table-cell--actions': props.column.name === '__data_table_row_actions__'
  };
});
</script>

<template>
  <td :class="column.class">
    <template v-if="column.name === '__data_table_row_actions__'">
      <div :class="cellClass">
        <template v-for="(action, i) in rowActions">
          <component
            v-if="!action.condition || action.condition(item)"
            :key="i"
            :is="action.actionComponent"
            :actionConfig="action.actionConfig"
            :disabledCondition="action.disabledCondition"
            :item="item"
            @action="onRowAction(action.action, item)" />
        </template>
      </div>
    </template>
    <template v-else-if="column.name === '__data_table_index__'">
      <div :class="cellClass">
        <component
          :is="column.cellComponent"
          :index="index" />
      </div>
    </template>
    <template v-else>
      <div :class="cellClass">
        <component
          :is="column.cellComponent"
          :column="column"
          :item="item"
          :cellConfig="column.cellConfig" />
      </div>
    </template>
  </td>
</template>
