import { FormValidator, type FormValidatorContext, isEmpty, type FormValidatorConfig, type FormValidatorSettings } from '@common/index';
import { useApi } from '../../../services';

export type TeacherNeptunCodeValidatorSettings = FormValidatorSettings;
export type TeacherNeptunCodeValidatorConfig = FormValidatorConfig<TeacherNeptunCodeValidatorSettings>;

export class TeacherNeptunCodeUniqueValidator extends FormValidator<TeacherNeptunCodeValidatorSettings> {
  teacherApi = useApi().teacher;

  constructor() {
    super();
    this._name = 'teacherNeptunCodeUniqueValidator';
    this._settings = {
      invalidMessage: 'teacher.validation.neptunCodeAlreadyExists',
      needTranslate: true
    };
  }

  public override async onValidate(value: any, context: FormValidatorContext) {
    if (isEmpty(value)) {
      return;
    }

    const params = {
      neptunCode: context.element.parent?.value?.neptunCode || null,
      id: context.element.parent?.value?.id || 0
    };
    const response = await this.teacherApi.validateNeptunCode(params);

    const notExist = response.data;
    if (notExist) {
      this._valid = true;
    } else {
      this.addMessage(this.settings.invalidMessage);
      this._valid = false;
    }
  }
}
