import { ApiModule, type PaginatedResponseData } from '@common/index';
import type { SampleUnitHasLessonItem, SampleUnitHasLesson } from '../models';

export class SampleUnitHasLessonApi extends ApiModule {
  protected endpoint = '/lesson';
  protected sampleUnitEndpoint = '/sample-unit';
  protected exportEndpoint = '/export-data';

  public async pageSampleUnitHasLessons(params: any) {
    return this.api.post<PaginatedResponseData<SampleUnitHasLessonItem>, any>(
      `${this.endpoint}/page?page=${params.page}&size=${params.itemsPerPage}&sort=${params.sort ?? ''}`,
      {
        ...params
      }
    );
  }

  public loadSampleUnitHasLessonDetails(id: number) {
    return this.api.get<SampleUnitHasLesson>(`${this.endpoint}?id=${id}`);
  }

  public saveSampleUnitHasLesson(params: any) {
    return this.api.post(`${this.endpoint}`, {
      ...params
    });
  }

  public deleteSampleUnitHasLesson(id: number) {
    return this.api.delete(`${this.endpoint}?id=${id}`);
  }

  public generateLessons(sampleUnitId: number) {
    return this.api.post<void, any>(`${this.endpoint}/generate`, {}, { params: { sampleUnitId } });
  }

  public deleteMultiple(ids: number[]) {
    return this.api.post<void, any>(`${this.endpoint}/delete-multiple`, { ids });
  }

  public loadLessonExportDetails(sampleUnitId: number) {
    return this.api.get<Blob>(`${this.exportEndpoint}/lesson/xls?sampleUnitId=${sampleUnitId}`, {
      headers: {
        Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      },
      responseType: 'blob'
    });
  }
}
