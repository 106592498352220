import { ApiModule, type UserSuggestion } from '@common/index';
import { EntreatyStatus, EntreatyType } from '../../entreaty/models';
import { type SampleUnitEntreatyData } from '../models/sample-unit-entreaty.model';

export class SampleUnitEntreatyApi extends ApiModule {
  protected endpoint = '/entreaty';
  protected teacherSearchEndpoint = '/search-user/teachers';
  protected type = EntreatyType.SAMPLE_UNIT;

  public loadSampleUnitDetails(id: number) {
    return this.api.get<SampleUnitEntreatyData>(`${this.endpoint}/load-data`, {
      params: {
        relatedId: id,
        entreatyType: this.type
      }
    });
  }

  public createEntreaty(id: number) {
    return this.api.post<SampleUnitEntreatyData, any>(
      `${this.endpoint}/create`,
      {},
      {
        params: {
          relatedId: id,
          entreatyType: this.type
        }
      }
    );
  }

  public saveSampleUnitEntreaty(params: any) {
    return this.api.post<SampleUnitEntreatyData, any>(`${this.endpoint}/admin-save-data`, {
      ...params,
      sampleUnitData: null,
      entreatyType: this.type
    });
  }

  public closeSampleUnitEntreaty(params: any, status: EntreatyStatus) {
    return this.api.post<SampleUnitEntreatyData, any>(`${this.endpoint}/close-entreaty-data?status=${status}`, {
      ...params,
      sampleUnitData: null,
      entreatyType: this.type
    });
  }

  public reOpenSampleUnitEntreaty(id: any) {
    return this.api.post<SampleUnitEntreatyData>(`${this.endpoint}/reopen-entreaty-data?id=${id}`);
  }

  public searchTeacherByNeptunCode(code: string) {
    return this.api.get<UserSuggestion[]>(`${this.teacherSearchEndpoint}?searchText=${code}`);
  }

  public saveOwnSampleUnitEntreaty(params: any) {
    return this.api.post<SampleUnitEntreatyData, any>(`${this.endpoint}/teacher-save-data`, {
      ...params,
      sampleUnitData: null,
      entreatyType: this.type
    });
  }
}
