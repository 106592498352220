<script lang="ts" setup>
import type { PropType } from 'vue';
import { useFormGroupElement } from '../composables';
import { FormGroupElement } from '../models';
import { CtsField, CtsFieldMessage } from './';

const props = defineProps({
  element: {
    type: Object as PropType<FormGroupElement>,
    required: true
  },
  componentSettings: {
    type: Object as PropType<any>,
    required: false
  }
});

const { attributes, sheetAttributes, prependComponent, appendComponent, children, hasErrorMessages, hasWarningMessages, hasInfoMessages, density } =
  useFormGroupElement(props.element);
</script>

<template>
  <v-input
    :hideDetails="attributes.hideDetails"
    :class="{
      'v-input--error': hasErrorMessages,
      'v-input--warning': hasWarningMessages,
      'v-input--info': hasInfoMessages
    }">
    <template
      v-if="prependComponent"
      #prepend>
      <component
        :is="prependComponent"
        :element="element" />
    </template>
    <template #default>
      <v-sheet
        v-bind="sheetAttributes"
        width="100%">
        <div
          v-if="attributes.hideDetails !== true"
          class="v-input__details mb-5">
          <cts-field-message :element="element" />
        </div>
        <v-row :dense="density === 'compact'">
          <cts-field
            v-for="(child, i) in children"
            :key="i"
            :element="child" />
        </v-row>
      </v-sheet>
    </template>
    <template
      v-if="appendComponent"
      #append>
      <component
        :is="appendComponent"
        :element="element" />
    </template>
  </v-input>
</template>
