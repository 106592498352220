import { type Editor, type Extensions } from '@tiptap/vue-3';
import type { FormElementAttributes, FormElementConfig, FormElementSettings } from '../';
import { Node } from '@tiptap/core';
import { Document } from '@tiptap/extension-document';
import { Text } from '@tiptap/extension-text';
import { Paragraph } from '@tiptap/extension-paragraph';

export type HtmlEditorSettings = FormElementSettings;

export interface HtmlEditorAttributes extends FormElementAttributes {
  maxHeight?: string;
  toolbar?: boolean | 'hover';
  toolbarActions?: HtmlEditorToolbarAction[];
  extensions?: Extensions;
  output?: 'html' | 'json' | 'text';
  rows?: number;
}

export type HtmlEditorConfig = FormElementConfig<HtmlEditorSettings, HtmlEditorAttributes>;

export interface HtmlEditorToolbarAction {
  label?: string | ((editor: Editor) => string);
  needTranslate?: boolean;
  icon?: string | ((editor: Editor) => string);
  class?: string | ((editor: Editor) => string);
  attributes?: any | ((editor: Editor) => any);
  action: (editor: Editor) => void;
  disabled?: (editor: Editor) => boolean;
  active?: (editor: Editor) => boolean;
}

export const OneLineDocument = Node.create({
  name: 'doc',
  topNode: true,
  content: 'block'
});

export const DEFAULT_HTML_EDITOR_EXTENSIONS = [Document, Text, Paragraph];

export const SINGLE_LINE_HTML_EDITOR_EXTENSIONS = [OneLineDocument, Text, Paragraph];

export const DEFAULT_HTML_EDITOR_TOOLBAR_ACTIONS: HtmlEditorToolbarAction[] = [
  {
    label: 'form.input.htmlEditor.action.subscript',
    needTranslate: true,
    icon: 'mdi-format-subscript',
    action: (editor) => (editor.commands as any).toggleSubscript(),
    disabled: (editor) => !(editor.can() as any).toggleSubscript(),
    active: (editor) => editor.isActive('subscript') || false
  },
  {
    label: 'form.input.htmlEditor.action.superscript',
    needTranslate: true,
    icon: 'mdi-format-superscript',
    action: (editor) => (editor.commands as any).toggleSuperscript(),
    disabled: (editor) => !(editor.can() as any).toggleSuperscript(),
    active: (editor) => editor.isActive('superscript') || false
  }
];
