import { ApiModule, type PaginatedResponseData } from '@common/index';
import type { SubjectHasSampleCurriculum, SubjectHasSampleCurriculumItem } from '../models';

export class SubjectHasSampleCurriculumApi extends ApiModule {
  protected endpoint = '/subject-has-sample-curriculum';

  public async pageSubjectHasSampleCurriculum(params: any) {
    return this.api.post<PaginatedResponseData<SubjectHasSampleCurriculumItem>, any>(
      `${this.endpoint}/page?page=${params.page}&size=${params.itemsPerPage}&sort=${params.sort ?? ''}`,
      {
        ...params
      }
    );
  }

  public async listSubjectHasSampleCurriculum(params: any) {
    return this.api.get<SubjectHasSampleCurriculumItem[]>(`${this.endpoint}/list`, {
      params: {
        ...params
      }
    });
  }

  public loadSubjectHasSampleCurriculumDetails(id: number) {
    return this.api.get<SubjectHasSampleCurriculum>(`${this.endpoint}?id=${id}`);
  }

  public saveSubjectHasSampleCurriculum(params: any) {
    return this.api.post(`${this.endpoint}`, {
      ...params
    });
  }

  public deleteSubjectHasSampleCurriculum(id: number) {
    return this.api.delete(`${this.endpoint}?id=${id}`);
  }
}
