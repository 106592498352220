import { VAutocomplete, VCheckbox, VFileInput, VRadio, VRangeSlider, VSelect, VSlider, VSwitch, VTextarea, VTextField } from 'vuetify/components';
import {
  CheckboxElement,
  ExpansionFormArrayElement,
  FormArrayElement,
  FormElement,
  FormGroupElement,
  FormStepElement,
  SelectableFormElement,
  VuetifyFormElement,
  VuetifySelectableFormElement
} from '../models';
import {
  CtsAsyncAutoComplete,
  CtsCheckboxGroup,
  CtsDatePickerField,
  CtsDisplayField,
  CtsFormGroup,
  CtsFormArray,
  CtsRadioGroup,
  SelectableVuetifyField,
  VuetifyField,
  CtsCombobox,
  CtsExpansionFormGroup,
  CtsExpansionFormArray,
  CtsHtmlEditor
} from '../components';
import {
  ChildrenValidValidator,
  CompanyNumberValidator,
  DateIsAfterValidator,
  DateIsBeforeValidator,
  EmailValidator,
  GreaterThanValidator,
  LengthValidator,
  PasswordValidator,
  RequiredTrueValidator,
  RequiredValidator,
  SameAsValidator,
  SmallerThanValidator,
  ArrayLengthValidator,
  PhoneNumberValidator
} from '../validators';
import { ProgressPlugin, RevalidateFormOnInputPlugin, ValidateAfterInitPlugin } from '../plugins';
import { formService } from '../services';
import { FloatFilter, IntegerFilter, NumberFilter, StringTrimFilter, EmptyFilter } from '../filters';
import { TaxNumberValidator } from '../validators/tax-number.validator';

export const registerDefaultElements = () => {
  formService.registerFormElement('text', {
    class: VuetifyFormElement,
    componentClass: VuetifyField,
    componentSettings: { vuetifyComponent: VTextField }
  });
  formService.registerFormElement('textarea', {
    class: VuetifyFormElement,
    componentClass: VuetifyField,
    componentSettings: { vuetifyComponent: VTextarea }
  });
  formService.registerFormElement('htmlEditor', {
    class: VuetifyFormElement,
    componentClass: VuetifyField,
    componentSettings: { vuetifyComponent: CtsHtmlEditor }
  });
  formService.registerFormElement('checkbox', {
    class: CheckboxElement,
    componentClass: VuetifyField,
    componentSettings: { vuetifyComponent: VCheckbox }
  });
  formService.registerFormElement('radio', {
    class: VuetifyFormElement,
    componentClass: VuetifyField,
    componentSettings: { vuetifyComponent: VRadio }
  });
  formService.registerFormElement('select', {
    class: VuetifySelectableFormElement,
    componentClass: SelectableVuetifyField,
    componentSettings: { vuetifyComponent: VSelect }
  });
  formService.registerFormElement('file', {
    class: VuetifyFormElement,
    componentClass: VuetifyField,
    componentSettings: { vuetifyComponent: VFileInput }
  });
  formService.registerFormElement('range', {
    class: VuetifyFormElement,
    componentClass: VuetifyField,
    componentSettings: { vuetifyComponent: VRangeSlider }
  });
  formService.registerFormElement('slider', {
    class: VuetifyFormElement,
    componentClass: VuetifyField,
    componentSettings: { vuetifyComponent: VSlider }
  });
  formService.registerFormElement('switch', {
    class: VuetifyFormElement,
    componentClass: VuetifyField,
    componentSettings: { vuetifyComponent: VSwitch }
  });
  formService.registerFormElement('combobox', {
    class: SelectableFormElement,
    componentClass: CtsCombobox
  });
  formService.registerFormElement('formGroup', {
    class: FormGroupElement,
    componentClass: CtsFormGroup
  });
  formService.registerFormElement('expansionFormGroup', {
    class: FormGroupElement,
    componentClass: CtsExpansionFormGroup
  });
  formService.registerFormElement('formArray', {
    class: FormArrayElement,
    componentClass: CtsFormArray
  });
  formService.registerFormElement('expansionFormArray', {
    class: ExpansionFormArrayElement,
    componentClass: CtsExpansionFormArray
  });
  formService.registerFormElement('formStep', {
    class: FormStepElement,
    componentClass: CtsFormGroup
  });
  formService.registerFormElement('checkboxGroup', {
    class: SelectableFormElement,
    componentClass: CtsCheckboxGroup
  });
  formService.registerFormElement('radioGroup', {
    class: SelectableFormElement,
    componentClass: CtsRadioGroup
  });
  formService.registerFormElement('autocomplete', {
    class: VuetifySelectableFormElement,
    componentClass: SelectableVuetifyField,
    componentSettings: { vuetifyComponent: VAutocomplete }
  });
  formService.registerFormElement('asyncAutocomplete', {
    class: SelectableFormElement,
    componentClass: CtsAsyncAutoComplete
  });
  formService.registerFormElement('datepicker', {
    class: VuetifyFormElement,
    componentClass: VuetifyField,
    componentSettings: { vuetifyComponent: CtsDatePickerField }
  });
  formService.registerFormElement('display', {
    class: FormElement,
    componentClass: CtsDisplayField
  });
};

export const registerDefaultFilters = () => {
  formService.registerFilter('number', NumberFilter);
  formService.registerFilter('integer', IntegerFilter);
  formService.registerFilter('float', FloatFilter);
  formService.registerFilter('stringTrim', StringTrimFilter);
  formService.registerFilter('empty', EmptyFilter);
};

export const registerDefaultValidators = () => {
  formService.registerValidator('required', RequiredValidator);
  formService.registerValidator('length', LengthValidator);
  formService.registerValidator('arrayLength', ArrayLengthValidator);
  formService.registerValidator('childrenValid', ChildrenValidValidator);
  formService.registerValidator('smallerThan', SmallerThanValidator);
  formService.registerValidator('companyNumber', CompanyNumberValidator);
  formService.registerValidator('email', EmailValidator);
  formService.registerValidator('greaterThan', GreaterThanValidator);
  formService.registerValidator('password', PasswordValidator);
  formService.registerValidator('sameAs', SameAsValidator);
  formService.registerValidator('requiredTrue', RequiredTrueValidator);
  formService.registerValidator('taxNumber', TaxNumberValidator);
  formService.registerValidator('phoneNumber', PhoneNumberValidator);
  formService.registerValidator('dateIsAfter', DateIsAfterValidator);
  formService.registerValidator('dateIsBefore', DateIsBeforeValidator);
};

export const registerDefaultPlugins = () => {
  formService.registerPlugin('progress', ProgressPlugin);
  formService.registerPlugin('validateAfterInit', ValidateAfterInitPlugin);
  formService.registerPlugin('revalidateFormOnInput', RevalidateFormOnInputPlugin);
};

export const registerDefaults = () => {
  registerDefaultElements();
  registerDefaultFilters();
  registerDefaultValidators();
  registerDefaultPlugins();
};
