import { ApiModule, type PaginatedResponseData } from '@common/index';
import type { SampleUnitRevisionItem, SampleUnitRevision } from '../models';

export class SampleUnitRevisionApi extends ApiModule {
  protected endpoint = '/sample-unit-revision';

  public async pageSampleUnitRevisions(params: any) {
    return this.api.post<PaginatedResponseData<SampleUnitRevisionItem>, any>(
      `${this.endpoint}/page?page=${params.page}&size=${params.itemsPerPage}&sort=${params.sort ?? ''}`,
      {
        ...params
      }
    );
  }

  public loadSampleUnitRevisionDetails(id: number) {
    return this.api.get<SampleUnitRevision>(`${this.endpoint}?id=${id}`);
  }

  public saveSampleUnitRevision(params: any) {
    return this.api.post(`${this.endpoint}`, {
      ...params
    });
  }

  public deleteSampleUnitRevision(id: number) {
    return this.api.delete(`${this.endpoint}?id=${id}`);
  }
}
