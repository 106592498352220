export const REGEX_HTML_TAGS = /<[^>]*>/giu;
export const REGEX_HTML_SPECIAL_CHARS = /&[^\s]+?;/giu;
export const REGEX_NEW_LINE = /\r\n|\r|\n/giu;
export const REGEX_P_TO_BR_OPEN = /<p>/giu;
export const REGEX_P_TO_BR_CLOSE = /<\/p>/giu;

export const stripHtmlTags = (html?: string | null) => {
  if (!html) return html;
  return html.replaceAll(REGEX_HTML_TAGS, '');
};

export const stripHtmlSpecialChars = (text?: string | null) => {
  if (!text) return text;
  return text.replaceAll(REGEX_HTML_SPECIAL_CHARS, '');
};

export const stripHtmlTagsAndSpecialChars = (text?: string | null) => {
  if (!text) return text;
  return stripHtmlTags(stripHtmlSpecialChars(unescapeHtml(text)));
};

export const stripNewLines = (text?: string | null) => {
  if (!text) return text;
  return text.replace(REGEX_NEW_LINE, '');
};

export const unescapeHtml = (text?: string | null) => {
  if (!text) return text;
  return decodeURIComponent(encodeURIComponent(text));
};

export const convertParagraphsToHardBreaks = (html?: string | null) => {
  if (!html) return html;
  const converted = html.replace(REGEX_P_TO_BR_OPEN, '').replace(REGEX_P_TO_BR_CLOSE, '<br>');
  return `<p>${converted}</p>`;
};
