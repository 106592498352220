import { FormValidator, type FormValidatorContext, isEmpty, type FormValidatorConfig, type FormValidatorSettings, useCommonApi } from '@common/index';

export type UserNeptunCodeValidatorSettings = FormValidatorSettings;
export type UserNeptunCodeValidatorConfig = FormValidatorConfig<UserNeptunCodeValidatorSettings>;

export class UserNeptunCodeUniqueValidator extends FormValidator<UserNeptunCodeValidatorSettings> {
  validatorApi = useCommonApi().validator;

  constructor() {
    super();
    this._name = 'userNeptunCodeUniqueValidator';
    this._settings = {
      invalidMessage: 'user.validation.neptunCodeAlreadyExists',
      needTranslate: true
    };
  }

  public override async onValidate(value: any, context: FormValidatorContext) {
    if (isEmpty(value)) {
      return;
    }

    const params = {
      neptunCode: value,
      id: context.element.parent?.value?.id
    };
    const response = await this.validatorApi.validateNeptunCode(params);

    const notExist = response.data;
    if (notExist) {
      this._valid = true;
    } else {
      this.addMessage(this.settings.invalidMessage);
      this._valid = false;
    }
  }
}
