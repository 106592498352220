import { computed, inject } from 'vue';
import { cloneDeep } from 'lodash';
import { type FormElement, useFormElement } from '@common/lib/modules/form';
import { SUBJECT_PREREQUISITE_ENTREATY_TABLE_CONFIG } from '../config';
import type { SubjectPrerequisiteEntreatyItem, SubjectPrerequisiteEntreatyModalConfig } from '../models';
import { useApi } from '../../../../../services';
import { useModals } from '@common/lib/modules/modal';
import { useIsLoading } from '@common/lib/composables';
import { useNotifications } from '@common/lib/modules/notification';
import { SubjectPrerequisiteEntreatyDetailsModal } from '../components';

export const useSubjectPrerequisiteEntreatyDataTable = (element: FormElement) => {
  const subjectPrerequisiteEntreatyApiService = useApi().subjectPrerequisiteEntreaty;
  const notifications = useNotifications();
  const modals = useModals();
  const { handlePromiseLoading } = useIsLoading();

  const tableConfig = cloneDeep(SUBJECT_PREREQUISITE_ENTREATY_TABLE_CONFIG);
  if (element.readonly) {
    tableConfig.actions = { global: [], row: [] };
  }

  const { value: data } = useFormElement(element);
  const subjectId = computed(() => element.parent?.value.subjectId);
  const entreatyId = computed(() => element.parent?.value.id);

  const subjectEntreatyDetails = inject<any>('subjectEntreatyDetails');

  const openSubjectPrerequisiteEntreaty = (subjectPrerequisiteEntreatyItem: SubjectPrerequisiteEntreatyItem) => {
    modals.open<SubjectPrerequisiteEntreatyModalConfig>(SubjectPrerequisiteEntreatyDetailsModal, {
      title: 'subject.relations.prerequisite.details.title',
      width: '800px',
      subjectEntreatyId: entreatyId.value,
      subjectId: subjectId.value,
      subjectPrerequisiteEntreatyItem: subjectPrerequisiteEntreatyItem,
      translate: true,
      onClose: async () => {
        subjectEntreatyDetails?.reload();
      }
    });
  };

  const newSubjectPrerequisiteEntreaty = () => {
    modals.open<SubjectPrerequisiteEntreatyModalConfig>(SubjectPrerequisiteEntreatyDetailsModal, {
      title: 'subject.relations.prerequisite.details.title',
      width: '800px',
      subjectEntreatyId: entreatyId.value,
      subjectId: subjectId.value,
      alreadyAddedCount: data.value.length,
      translate: true,
      onClose: async () => {
        subjectEntreatyDetails?.reload();
      }
    });
  };

  const deleteSubjectPrerequisiteEntreaty = (subjectPrerequisiteEntreatyItem: SubjectPrerequisiteEntreatyItem) => {
    modals.openConfirm({
      title: 'subject.relations.prerequisite.dialog.delete.title',
      text: 'subject.relations.prerequisite.dialog.delete.text',
      translateParams: subjectPrerequisiteEntreatyItem,
      translate: true,
      onConfirm: async () => {
        await notifications.promise(
          handlePromiseLoading(subjectPrerequisiteEntreatyApiService.deleteSubjectPrerequisiteEntreaty(subjectPrerequisiteEntreatyItem.id)),
          {
            text: 'subject.relations.prerequisite.notification.deleteSuccess',
            errorText: 'subject.relations.prerequisite.notification.deleteError'
          }
        );
        subjectEntreatyDetails?.reload();
      }
    });
  };

  const onRowAction = (action: string, item: SubjectPrerequisiteEntreatyItem) => {
    switch (action) {
      case 'open':
        openSubjectPrerequisiteEntreaty(item);
        break;
      case 'delete':
        deleteSubjectPrerequisiteEntreaty(item);
        break;
      default:
        window.alert(`${action}: ${item.id}`);
    }
  };

  const onGlobalAction = (action: string, items: SubjectPrerequisiteEntreatyItem[]) => {
    switch (action) {
      case 'add_new':
        newSubjectPrerequisiteEntreaty();
        break;
      default:
        window.alert(`${action}, ${items}`);
    }
  };

  return {
    tableConfig,
    data,
    onRowAction,
    onGlobalAction
  };
};
