import { ApiModule, type PaginatedResponseData } from '@common/index';
import type { SubjectPrerequisiteItem, SubjectPrerequisite, SubjectPrerequisiteFor } from '../models';

export class SubjectPrerequisiteApi extends ApiModule {
  protected endpoint = '/subject-prerequisite';

  public async pageSubjectPrerequisites(params: any) {
    return this.api.post<PaginatedResponseData<SubjectPrerequisiteItem>, any>(
      `${this.endpoint}/page?page=${params.page}&size=${params.itemsPerPage}&sort=${params.sort ?? ''}`,
      {
        ...params
      }
    );
  }

  public async pageSubjectsPrerequisitesFor(params: any) {
    return this.api.post<PaginatedResponseData<SubjectPrerequisiteFor>, any>(
      `${this.endpoint}/for/page?page=${params.page}&size=${params.itemsPerPage}&sort=${params.sort ?? ''}`,
      {
        ...params
      }
    );
  }

  public loadSubjectPrerequisiteDetails(id: number) {
    return this.api.get<SubjectPrerequisite>(`${this.endpoint}?id=${id}`);
  }

  public saveSubjectPrerequisite(params: any) {
    return this.api.post(`${this.endpoint}`, {
      ...params
    });
  }

  public deleteSubjectPrerequisite(id: number) {
    return this.api.delete(`${this.endpoint}?id=${id}`);
  }

  public getMetaData(subjectId: number, subjectPrerequisiteId: number | undefined) {
    return this.api.get<any>(`${this.endpoint}/master-data?subjectId=${subjectId}&subjectPrerequisiteId=${subjectPrerequisiteId ?? ''}`);
  }
}
