<script lang="ts" setup>
import { computed, inject, type PropType } from 'vue';
import { type DataTableColumn, type DataTableSort, SortDirection, useDataTable } from '../../../';

const dataTableService = inject<ReturnType<typeof useDataTable>>('dataTableService')!;

const props = defineProps({
  column: {
    type: Object as PropType<DataTableColumn>,
    required: true
  },
  sorted: {
    type: Object as PropType<DataTableSort>,
    required: true
  }
});

const emit = defineEmits(['sort']);

const isAllSelected = computed({
  get: (): boolean => dataTableService.isAllSelected.value,
  set: (): void => dataTableService.selectAll()
});

const isSomeSelected = computed(() => dataTableService.isSomeSelected.value);

const isSortable = computed(() => props.column.sortable);
const isSorted = computed(() => dataTableService.sort.value?.column === props.column.name);
const isAscSorted = computed(() => isSorted.value && dataTableService.sort.value?.direction === SortDirection.ASC);
const isDescSorted = computed(() => isSorted.value && dataTableService.sort.value?.direction === SortDirection.DESC);

const sort = (column: string) => {
  dataTableService.sortBy(column);
  emit('sort', dataTableService.sort.value);
};

const columnClass = computed(() => {
  const classes = {
    'data-table-column': true,
    'data-table-column--selection': props.column.name === '__data_table_selected__',
    'data-table-column--actions': props.column.name === '__data_table_row_actions__'
  };

  if (props.column.class) {
    classes[props.column.class] = true;
  }

  return classes;
});
</script>

<template>
  <th :class="columnClass">
    <template v-if="column.name === '__data_table_selected__'">
      <v-checkbox
        hide-details
        color="primary"
        v-model="isAllSelected"
        :indeterminate="isSomeSelected">
        <v-tooltip
          activator="parent"
          location="top">
          {{ $t('dataTable.selectAll') }}
        </v-tooltip>
      </v-checkbox>
    </template>
    <template v-else>
      <span>
        {{ column.translateLabel ? $t(column.label) : column.label }}
      </span>
      <span
        v-if="isSortable"
        class="data-table-sort"
        :class="{ 'data-table-sort--active': isSorted }"
        @click="sort(column.name)">
        <v-icon
          v-if="isAscSorted"
          size="small"
          color="primary">
          mdi-sort-ascending
        </v-icon>
        <v-icon
          v-else-if="isDescSorted"
          size="small"
          color="primary">
          mdi-sort-descending
        </v-icon>
        <v-icon
          v-else
          size="small">
          mdi-sort
        </v-icon>
      </span>
    </template>
  </th>
</template>
