import type { UserItem } from '../models/user.model';
import { ApiModule, type PaginatedResponseData } from '@common/index';

export class AdminApi extends ApiModule {
  protected endpoint = '/admin';

  public async pageUsers(params: any) {
    return this.api.post<PaginatedResponseData<UserItem>, any>(
      `/user/page?page=${params.page}&size=${params.itemsPerPage}&sort=${params.sort ?? ''}`,
      {
        ...params
      }
    );
  }

  public saveUser(params: any) {
    return this.api.post(`/user`, {
      ...params
    });
  }

  public deleteUser(userId?: number) {
    return this.api.delete(`/user?id=${userId}`);
  }

  public async activateUsers(params: any) {
    return this.api.post<void, any>(`/user/activate-users`, {
      ...params
    });
  }

  public async inactivateUsers(params: any) {
    return this.api.post<void, any>(`/user/inactivate-users`, {
      ...params
    });
  }

  public async activationEmailUsers(params: any) {
    return this.api.post<void, any>(`/user/send-activation-emails`, {
      ...params
    });
  }

  public async pageCountrys(params: any) {
    return this.api.post<PaginatedResponseData<UserItem>, any>(
      `/country/page?page=${params.page}&size=${params.itemsPerPage}&sort=${params.sort ?? ''}`,
      {
        ...params
      }
    );
  }
  public saveCountry(params: any) {
    return this.api.post(`/country`, {
      ...params
    });
  }
  public deleteCountry(countyId?: number) {
    return this.api.delete(`/country?id=${countyId}`);
  }
  public async pageCenters(params: any) {
    return this.api.post<PaginatedResponseData<UserItem>, any>(
      `/center/page?page=${params.page}&size=${params.itemsPerPage}&sort=${params.sort ?? ''}`,
      {
        ...params
      }
    );
  }

  public saveCenter(params: any) {
    return this.api.post(`/center`, {
      ...params
    });
  }

  public deleteCenter(centerId?: number) {
    return this.api.delete(`/center?id=${centerId}`);
  }

  public async pageContexts(params: any) {
    return this.api.post<PaginatedResponseData<UserItem>, any>(
      `/context-relation/page?page=${params.page}&size=${params.itemsPerPage}&sort=${params.sort ?? ''}`,
      {
        ...params
      }
    );
  }

  public saveContext(params: any) {
    return this.api.post(`/context-relation`, {
      ...params
    });
  }

  public deleteContextRelation(contextId: number) {
    return this.api.delete(`/context-relation?id=${contextId}`);
  }

  public async expotTemplate() {
    const response = await this.api.get<Blob>(`/user/export-template`, { params: { responseType: 'blob' } });

    const blobUrl = URL.createObjectURL(new Blob([response.data], { type: 'text/csv' }));
    window.open(blobUrl, '_blank');
  }
}
