import type { FormElement } from '@common/lib/modules/form';
import { FormElementPlugin } from '@common/lib/modules/form';

export class SampleCurriculumCodeGenPlugin extends FormElementPlugin {
  public constructor() {
    super();
    this._name = 'sampleCurriculumCodeGen';
  }

  public override onFocus(element: FormElement<any>) {
    if (element.settings.custom.sampleCurriculumCodeGen) {
      element.settings.custom.sampleCurriculumCodeGen();
    }
  }
}
