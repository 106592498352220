import { ApiModule } from '../../api/services';
import type { Credentials, AuthTokens, PasswordResetData, PersonalData, ChangePasswordData } from '../';

export class AuthApi extends ApiModule {
  protected authEndpoint = '/auth';
  protected userEndpoint = '/user';
  protected publicEndpoint = '/public';

  public login(credentials: Credentials) {
    return this.api.post<AuthTokens, typeof credentials>(`${this.authEndpoint}/login`, credentials);
  }

  public refreshToken(refreshToken: string) {
    return this.api.post<AuthTokens, { refreshToken: string }>(`${this.authEndpoint}/refresh`, { refreshToken: refreshToken });
  }

  public forgotPassword(email: string) {
    return this.api.get(`${this.publicEndpoint}/reset-password`, { params: { email } });
  }

  public setPassword(passwordResetData: PasswordResetData) {
    return this.api.post<void, PasswordResetData>(`${this.publicEndpoint}/change-password`, passwordResetData);
  }

  public loadPersonalData() {
    return this.api.get<PersonalData>(`/me`);
  }

  public savePersonalData(personalData: PersonalData) {
    return this.api.put<PersonalData, PersonalData>(`${this.userEndpoint}/own`, personalData);
  }

  public changePassword(changePasswordData: ChangePasswordData) {
    return this.api.post<void, ChangePasswordData>(`${this.userEndpoint}/change-password`, changePasswordData);
  }

  public loginSaml() {
    return this.api.post<{ url: string }, any>(`${this.authEndpoint}/init-auth`, {}, { headers: { 'X-Client-Type': 'WEBAPP' } });
  }

  public loginSamlExchangeToken(token: string) {
    return this.api.post<AuthTokens, { token: string }>(`${this.authEndpoint}/exchange-token`, { token }, { headers: { 'X-Client-Type': 'WEBAPP' } });
  }

  public logout(refreshToken: string) {
    return this.api.post<{ url?: string }, { refreshToken: string }>(`/base/logout`, { refreshToken }, { headers: { 'X-Client-Type': 'WEBAPP' } });
  }
}
