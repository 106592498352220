import type { AxiosInstance } from 'axios';
import type { ApiPlugin } from '..';
import { useAcademicYearStore } from '../../../stores/academic-year';
import { useAuthStore } from '../../auth';
import { storeToRefs } from 'pinia';

export class AcademicYearHeaderPlugin implements ApiPlugin {
  initialize(instance: AxiosInstance): void {
    instance.interceptors.request.use((config) => {
      const { loggedIn } = useAuthStore();

      if (loggedIn) {
        const academicYearStore = useAcademicYearStore();
        const { selectedAcademicYear } = storeToRefs(academicYearStore);
        if (selectedAcademicYear.value) {
          config.headers['x-academic-year'] = selectedAcademicYear.value.code;
        }
      }

      return config;
    });
  }
}
