import {
  FormValidator,
  type FormValidatorContext,
  isEmpty,
  type FormValidatorConfig,
  type FormValidatorSettings,
  useCommonApi,
  SelectableFormElement
} from '@common/index';

export type SpecialityGroupTypeMatchValidatorSettings = FormValidatorSettings;
export type SpecialityGroupTypeMatchValidatorConfig = FormValidatorConfig<SpecialityGroupTypeMatchValidatorSettings>;

export class SpecialityGroupTypeMatchValidator extends FormValidator<SpecialityGroupTypeMatchValidatorSettings> {
  validatorApi = useCommonApi().validator;

  constructor() {
    super();
    this._name = 'specialityGroupTypeMatch';
    this._settings = {
      invalidMessage: 'export.validation.specialityGroupTypeMatch',
      needTranslate: true
    };
  }

  public override async onValidate(value: any, context: FormValidatorContext) {
    if (isEmpty(value)) {
      return;
    }
    const selectableElement: SelectableFormElement = context.element as SelectableFormElement;
    const groupTypes = selectableElement.items.filter((type) => value.includes(type.id)).map((type) => type.specialityGroupType);
    const unique = [...new Set(groupTypes)];

    const notMatch = unique.length > 1;
    if (notMatch) {
      this.addMessage(this.settings.invalidMessage);
      this._valid = false;
    } else {
      this._valid = true;
    }
  }
}
