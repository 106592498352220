import { ApiModule, type PaginatedResponseData } from '@common/index';
import type { TopicItem, TopicSummaryItem } from '../../topic/models';
import type { ArchiveAcademicYearCodes } from '../models';

export class ArchiveApi extends ApiModule {
  protected endpoint = '/archive';
  protected topicEndpoint = '/topic-archive';

  public async pageArchiveTopics(params: any) {
    return this.api.post<PaginatedResponseData<TopicItem>, any>(
      `${this.topicEndpoint}/page?page=${params.page}&size=${params.itemsPerPage}&sort=${params.sort ?? ''}`,
      {
        ...params
      }
    );
  }

  public loadArchiveTopicSummary(id: number, academicYearCode: string) {
    return this.api.get<TopicSummaryItem>(`${this.topicEndpoint}/get-summary?topicId=${id}&academicYearCode=${academicYearCode}`);
  }

  public loadArchiveAcademicYearCodes() {
    return this.api.get<ArchiveAcademicYearCodes>(`${this.endpoint}/get-archive-academic-year-codes`);
  }
}
