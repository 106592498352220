import type { RouteRecordRaw } from 'vue-router';
import core from './modules/core/routes/routes';
import authRoutes from '@common/lib/modules/auth/routes/routes';
import adminRoutes from './modules/admin/routes/routes';
import academicYearRoutes from './modules/master-data/academic-year/routes/routes';
import absenceRatioRoutes from './modules/master-data/absence-ratio/routes/routes';
import clinicalSkillRoutes from './modules/master-data/clinical-skill/routes/routes';
import examTypeRoutes from './modules/master-data/exam-type/routes/routes';
import topicCategoryRoutes from './modules/master-data/topic-category/routes/routes';
import facultyRoutes from './modules/master-data/faculty/routes/routes';
import instituteRoutes from './modules/master-data/institute/routes/routes';
import positionRoutes from './modules/master-data/position/routes/routes';
import specialityRoutes from './modules/master-data/speciality/routes/routes';
import teacherRoutes from './modules/teacher/routes/routes';
import topicRoutes from './modules/topic/routes/routes';
import moduleRoutes from './modules/module/routes/routes';
import subjectRoutes from './modules/subject/routes/routes';
import sampleUnitRoutes from './modules/sample-unit/routes/routes';
import methodologicalElementRoutes from './modules/master-data/methodological-element/routes/routes';
import campusFactoryRoutes from './modules/master-data/campus-faculty/routes/routes';
import entreatyRoutes from './modules/entreaty/routes/routes';
import exportRoutes from './modules/export/routes/routes';
import accessDeniedRoutes from './modules/core/routes/routes';
import topicRevisionRoutes from './modules/revision/topic-revision/routes/routes';
import sampleUnitRevisionRoutes from './modules/revision/sample-unit-revision/routes/routes';
import subjectRevisionRoutes from './modules/revision/subject-revision/routes/routes';
import instituteRevisionRoutes from './modules/revision/institute-revision/routes/routes';
import informationRoutes from './modules/information/routes/routes';
import sampleCurriculumRoutes from './modules/master-data/sample-curriculum/routes/routes';
import archiveRoutes from './modules/archive/routes/routes';

import educationalRequirementRoutes from './modules/master-data/educational-requirement/routes/routes';

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    children: [
      {
        path: '',
        component: () => import('./components/layouts/MainLayout.vue'),
        children: [
          ...core,
          ...adminRoutes,
          ...academicYearRoutes,
          ...examTypeRoutes,
          ...topicCategoryRoutes,
          ...absenceRatioRoutes,
          ...clinicalSkillRoutes,
          ...facultyRoutes,
          ...educationalRequirementRoutes,
          ...methodologicalElementRoutes,
          ...instituteRoutes,
          ...specialityRoutes,
          ...positionRoutes,
          ...teacherRoutes,
          ...topicRoutes,
          ...moduleRoutes,
          ...subjectRoutes,
          ...sampleUnitRoutes,
          ...campusFactoryRoutes,
          ...entreatyRoutes,
          ...exportRoutes,
          ...topicRevisionRoutes,
          ...sampleUnitRevisionRoutes,
          ...subjectRevisionRoutes,
          ...instituteRevisionRoutes,
          ...accessDeniedRoutes,
          ...informationRoutes,
          ...sampleCurriculumRoutes,
          ...archiveRoutes,
          ...authRoutes.mainRoutes
        ]
      },
      {
        path: '',
        component: () => import('./components/layouts/LoginLayout.vue'),
        children: [
          ...authRoutes.routes,
          { path: 'privacy-policy', name: 'privacyPolicy', component: () => import('./modules/core/pages/PrivacyPage.vue') }
        ]
      }
    ]
  },
  {
    path: '/:pathMatch(.*)*',
    component: () => import('./modules/core/pages/ErrorPage.vue')
  }
];

export default routes;
